import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Collapse,
    createStyles,
    Drawer,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    MenuList,
    Paper,
    Popper,
    Theme,
} from '@material-ui/core';
import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { mainTheme } from '../../Theme';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LockIcon from '@material-ui/icons/Lock';
import HelpIcon from '@material-ui/icons/Help';
import StorageIcon from '@material-ui/icons/Storage';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Skeleton } from '@material-ui/lab';
import { UserDefinedDialog } from '../../helpConfiguration/dialog/UserDefinedDialog';
import { ScrubEnquiryDataDialog } from '../../enquirySummary/dialogs/ScrubEnquiryDataDialog';
import { ChangePassword } from '../ChangePassword';
import CloseIcon from '@material-ui/icons/Close';
import { DocumentListFile } from '../../common/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
        },
        avatar: {
            backgroundColor: '#fff',
            border: `1px solid ${mainTheme.TemplateColor.Primary}`,
            height: '50px',
            width: '50px',
        },
        userIcon: {
            fontSize: '2rem',
            color: mainTheme.TemplateColor.Primary,
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        button: {
            textTransform: 'none',
            fontSize: '16px',
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        listItem: {
            color: mainTheme.TemplateColor.Primary,
        },
        listItemIcon: {
            minWidth: '30px',
        },
        icon: {
            color: mainTheme.TemplateColor.Primary,
        },
        changePasswordDrawer: {
            minWidth: '450px',
        },
        drawerHeader: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

enum ResourceEnum {
    Acuity = 'acuityResourceCenter',
    CRM = 'crmResourceCenter',
    Tasks = 'taskResourceCenter',
    Matters = 'mattersResourceCenter',
    Timesheet = 'timesheetResourceCenter',
}

type UserCardV2Props = {
    tenantId?: string;
    isForceToChangePass?: boolean;
    showResetLeapAccess?: boolean;
};

type UserCardV2State = {
    open: boolean;
    isHelpOpen: boolean;
    isScrubEnquiryOpen: boolean;
    isChangePasswordOpen: boolean;
};

export const UserCardV2 = (props: UserCardV2Props) => {
    const classes = useStyles();

    const currentUser = useCurrentUser('cache-first');

    const [state, setState] = React.useState<UserCardV2State>({
        open: false,
        isHelpOpen: false,
        isScrubEnquiryOpen: false,
        isChangePasswordOpen: false,
    });

    const [open, setOpen] = React.useState(false);

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const [resourceOpen, setResourceOpen] = React.useState(false);

    const handleResourceOpen = () => {
        setResourceOpen(!resourceOpen);
    };

    const handleToggle = () => {
        setOpen((currentOpen) => !currentOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = open;
        // tslint:disable-next-line: align
    }, [open]);

    const handleLogout = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        window.location.href = `/${props.tenantId}/logout`;

        setOpen(false);
    };

    const handleChangePassword = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setState((prevState) => {
            return {
                ...prevState,
                isChangePasswordOpen: true,
            };
        });

        setOpen(false);
    };

    const handleServiceLevels = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        window.open(DocumentListFile.ServiceLevel, '_blank');

        setOpen(false);
    };

    const handleResetLeapAccess = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        window.location.href = '/LeapAccessResetSignIn';

        setOpen(false);
    };

    const handleReportCatalog = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        window.location.href = '/reportsCatalog';

        setOpen(false);
    };

    const handleHelp = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setState((prevState) => {
            return {
                ...prevState,
                isHelpOpen: true,
            };
        });

        setOpen(false);
    };

    const handleScrubData = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setState((prevState) => {
            return {
                ...prevState,
                isScrubEnquiryOpen: true,
            };
        });

        setOpen(false);
    };

    const onCloseChangePassword = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isChangePasswordOpen: false,
            };
        });
    };

    const onCloseHelp = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isHelpOpen: false,
            };
        });
    };

    const onCloseScrub = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isScrubEnquiryOpen: false,
            };
        });
    };

    const handleResource = (resource: ResourceEnum) => (event: React.MouseEvent<EventTarget>) => {
        window.location.href = `/${resource}`;
    };

    if (currentUser.loading) {
        return (
            <Box className={classes.root}>
                <Skeleton variant="circle" width={50} height={50} />
                <Skeleton variant="rect" width={131} height={40} />
            </Box>
        );
    }

    return (
        <>
            {state.isHelpOpen && <UserDefinedDialog open={state.isHelpOpen} onClose={onCloseHelp} />}
            {state.isScrubEnquiryOpen && <ScrubEnquiryDataDialog open={state.isScrubEnquiryOpen} onClose={onCloseScrub} />}
            {state.isChangePasswordOpen && (
                <Drawer
                    classes={{
                        paper: classes.changePasswordDrawer,
                    }}
                    anchor="right"
                    open={state.isChangePasswordOpen}
                    onClose={onCloseChangePassword}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton aria-label="close" onClick={onCloseChangePassword}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <ChangePassword />
                </Drawer>
            )}
            <Box className={classes.root}>
                <Avatar className={classes.avatar}>
                    <PersonIcon className={classes.userIcon} />
                </Avatar>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    endIcon={<ArrowDropDownIcon />}
                    className={classes.button}
                >
                    {currentUser.data?.appUser?.name}
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    // role={undefined}
                    transition={true}
                    disablePortal={true}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <ListItem onClick={handleClose} disabled={true} className={classes.listItem}>
                                            {`Firm ID ${props.tenantId || ''}`}
                                        </ListItem>
                                        <ListItem button={true} onClick={handleResourceOpen} className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <InfoIcon className={classes.icon} />
                                            </ListItemIcon>
                                            <ListItemText primary="Resources" />
                                            {resourceOpen ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={resourceOpen} timeout="auto" unmountOnExit={true}>
                                            <List component="div" disablePadding={true}>
                                                <ListItem button={true} onClick={handleResource(ResourceEnum.Acuity)} className={clsx(classes.listItem, classes.nested)}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <ImportContactsIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Acuity" />
                                                </ListItem>
                                                <ListItem button={true} onClick={handleResource(ResourceEnum.CRM)} className={clsx(classes.listItem, classes.nested)}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <ImportContactsIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="CRM" />
                                                </ListItem>
                                                <ListItem button={true} onClick={handleResource(ResourceEnum.Tasks)} className={clsx(classes.listItem, classes.nested)}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <ImportContactsIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Tasks" />
                                                </ListItem>
                                                <ListItem button={true} onClick={handleResource(ResourceEnum.Matters)} className={clsx(classes.listItem, classes.nested)}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <ImportContactsIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Matters" />
                                                </ListItem>
                                                <ListItem button={true} onClick={handleResource(ResourceEnum.Timesheet)} className={clsx(classes.listItem, classes.nested)}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <ImportContactsIcon className={classes.icon} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Timesheet" />
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                        <ListItem onClick={handleServiceLevels} button={true} className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <TrendingUpIcon className={classes.icon} />
                                            </ListItemIcon>
                                            <ListItemText primary="Service Levels" />
                                        </ListItem>
                                        {UserFeature.HasAccess(UserFeatureEnum.hasReportCatalog) && (
                                            <ListItem onClick={handleReportCatalog} button={true} className={classes.listItem}>
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    <FormatListBulletedIcon className={classes.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary="Reports Catalog" />
                                            </ListItem>
                                        )}
                                        <ListItem onClick={handleChangePassword} button={true} className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <LockIcon className={classes.icon} />
                                            </ListItemIcon>
                                            <ListItemText primary="Change Password" />
                                        </ListItem>
                                        {UserFeature.HasAccess(UserFeatureEnum.hasUserDefinedHelp) && (
                                            <ListItem onClick={handleHelp} button={true} className={classes.listItem}>
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    <HelpIcon className={classes.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary="Help" />
                                            </ListItem>
                                        )}
                                        {currentUser.data?.appUser && currentUser.data.appUser.isAdministrator && UserFeature.HasAccess(UserFeatureEnum.hasScrubData) && (
                                            <ListItem onClick={handleScrubData} button={true} className={classes.listItem}>
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    <StorageIcon className={classes.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary="Scrub Enquiry Data" />
                                            </ListItem>
                                        )}
                                        {currentUser.data?.appUser && currentUser.data.appUser.isAdministrator && props.showResetLeapAccess && (
                                            <ListItem onClick={handleResetLeapAccess} button={true} className={classes.listItem}>
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    <SettingsIcon className={classes.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary="Reset LEAP Access" />
                                            </ListItem>
                                        )}
                                        <ListItem onClick={handleLogout} button={true} className={classes.listItem}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <ExitToAppIcon className={classes.icon} />
                                            </ListItemIcon>
                                            <ListItemText primary="Logout" />
                                        </ListItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </>
    );
};
