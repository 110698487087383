import * as React from 'react';
import '../App.css';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

export interface TooltipProps {
    hint?: string;
}

export class RvTooltip extends React.Component<TooltipProps, {}> {

    render() {

        return (
            <div className="tooltip">
                {this.props.children}
                <span className="tooltiptext">
                    {this.props.hint}
                </span>
            </div>);
    }
}

export interface TooltipFabricProps {
    hint?: string;
    key?: string;
}

// tslint:disable-next-line:no-any
export class RvTooltipFabric extends React.Component<TooltipFabricProps, {}> {
    public render(): JSX.Element {
        const { 
            hint,
            key
        } = this.props;
        return (
            <div>
            <TooltipHost content={hint} id={key} calloutProps={{ gapSpace: 0 }}>
                {this.props.children}
            </TooltipHost>
            </div>
        );
    }
  }
