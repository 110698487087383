import * as React from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

interface IconProps {
    iconKey?: SemanticICONS; 
    title?: string;
    size?: IconSizeProp;
}

export class RvIcon extends React.Component<IconProps, {}> {
    render() {
        return (
            <Icon 
                name={this.props.iconKey} 
                title={this.props.title}
                size={this.props.size}
            />
        );
    }
}
