export enum LeapFirmProvisionStatusType {
    Pending = 0,
    SyncInitiated = 1,
    SyncComplete = 2,
    MappingInitiated = 3,
    MappingComplete = 4,
    Complete = 5,
    DoNotProvision = 6,
    Failed = 7
}

export function GetLeapFirmProvisionStatusType(leapProvisioningStatus: string): LeapFirmProvisionStatusType {

    if (!leapProvisioningStatus || leapProvisioningStatus.length === 0) {
        return LeapFirmProvisionStatusType.DoNotProvision;
    }

    switch (leapProvisioningStatus.toLocaleLowerCase()) {
        case 'pending':
            return LeapFirmProvisionStatusType.Pending;
        case 'complete':
            return LeapFirmProvisionStatusType.Complete;
        case 'syncinitiated':
            return LeapFirmProvisionStatusType.SyncInitiated;
        case 'synccomplete':
            return LeapFirmProvisionStatusType.SyncComplete;
        case 'mappinginitiated':
            return LeapFirmProvisionStatusType.MappingInitiated;
        case 'mappingcomplete':
            return LeapFirmProvisionStatusType.MappingComplete;
        case 'failed':
            return LeapFirmProvisionStatusType.Failed;
        default:
            return LeapFirmProvisionStatusType.DoNotProvision;
    }
}