import { CircularProgress, Dialog, makeStyles, Theme, Typography, createStyles, DialogActions, Button, DialogContent } from '@material-ui/core';
import React from 'react';
// import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            textAlign: 'center'
        },
        title: {
            paddingTop: '15px',
            fontSize: '18px',
            textAlign: 'center',
        },
        secondaryTitle: {
            fontSize: '14px',
            textAlign: 'center',
            color: 'gray'
        },
        progressWrapper: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        progress: {
            // position: 'absolute',
        },
        avatar: {
            backgroundColor: '#5173FF'
        }
    })
);

interface LoadingDialogProps {
    open: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    title: string;
    secondaryTitle?: string;
    onClose?: () => void;
    displayCloseButton?: boolean;
}

export const LoadingDialog: React.FC<LoadingDialogProps> = (props) => {

    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            classes={{
                paper: classes.root
            }}
            maxWidth={props.maxWidth}
            fullWidth={true}
        >
            <DialogContent>
                {/* <div className={classes.progressWrapper}> */}
                <CircularProgress size={45} className={classes.progress} />
                    {/* <Avatar className={classes.avatar}>
                        <NotificationsActiveOutlinedIcon />
                    </Avatar> */}
                {/* </div> */}
                {props.title && (           
                    <Typography className={classes.title}>{props.title}</Typography>
                )}
                {props.secondaryTitle && (           
                    <Typography className={classes.secondaryTitle}>{props.secondaryTitle}</Typography>
                )}
            </DialogContent>
            {props.displayCloseButton 
                ? 
                (
                    <DialogActions>
                        <Button onClick={props.onClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                ) 
                : null
            }
            
        </Dialog>
    );
};

export default LoadingDialog;