import * as React from 'react';
import { FormEvent } from 'react';
import { RvInput } from './Input';
import glamorous, { ThemeProvider } from 'glamorous';
import { mainTheme } from '../Theme';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { IconButton, Theme, Tooltip, withStyles } from '@material-ui/core';

// tslint:disable-next-line:no-any
const SearchBoxWrapper = glamorous.div<{theme?: any}> ((props) => ({
    textAlign: 'left',
    position: 'relative',
    [`& .${`input`}`]: {
        // marginLeft: '30px',
        maxWidth: 'none !important',
        '> input': {
            border: 'none !important',
            color: props.theme.BrandColors.TextSecondary,
            fontFamily: props.theme.Font.Main,
            fontSize: props.theme.FontSize.PX.size3,
            fontWeight: props.theme.FontWeight.SixHundred,	
            lineHeight: props.theme.LineHeight.Regular1,
        },
        '> i': {
            color: props.theme.BrandColors.TemplatePrimary,
            fontSize: props.theme.FontSize.PX.size6,
            marginLeft: '-10px !important'
        }
    }
}));

const IconButtonWrapper = glamorous(IconButton)({
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
});

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    arrow: {
        color: theme.palette.common.white,
        '&::before': {
            // backgroundColor: 'blue',
            boxShadow: theme.shadows[1],
        }
    },
}))(Tooltip);

export interface SearchProps {
    hint: string;
    search: string;
    disabled?: boolean;
    searchAction: (filter: string) => void;
    iconPosition?: 'left';
    showClear?: boolean;
    tooltip?: NonNullable<React.ReactNode>;
}

export interface SearchState {
    search: string;
}

export class RvSearch extends React.Component<SearchProps, SearchState> {
    // tslint:disable-next-line:no-any
    // private timeOut: any;
    // private delay = 100; // ms delay

    static filterString = (value: string, filter: string) => {
        return value && value.toLowerCase().includes(filter);
    }

    static filterDate = (value: Date | string, filter: string) => {
        if (!value) {
            return false;
        }
        let dateValue: Date;
        if (typeof value === 'string') {
            dateValue = new Date(Date.parse(value as string));
        } else {
            dateValue = value as Date;
        }
        var textValue = dateValue.toDateString() + ' ' + dateValue.toLocaleTimeString();
        return textValue.toLowerCase().includes(filter);
    }

    constructor(props: SearchProps, state: SearchState) {
        super(props, state);

        this.state = { search: this.props.search };
        this.handleChange = this.handleChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: SearchProps) {
        if (this.props.search !== nextProps.search) {
            this.setState({
                search: nextProps.search
            });   
        }
    }
 
    public render() {

        return (
            <ThemeProvider theme={mainTheme}>
                <LightTooltip 
                    arrow={true} 
                    title={this.props.tooltip ? this.props.tooltip : ''}
                    placement="bottom-end"
                >
                    <SearchBoxWrapper>
                        <RvInput
                            hint={this.props.hint}
                            onChange={this.handleChange}
                            iconKey="search"
                            value={this.state.search}
                            disabled={this.props.disabled}
                            iconPosition="left"
                            onKeyDown={this.handleKeyDown}
                        />
                        {!!(this.props.showClear && this.state.search.length > 0) && (
                            <IconButtonWrapper 
                                size="small" 
                                style={{ position: 'absolute' }}
                                onClick={this.onClear}
                            >
                                <ClearOutlinedIcon fontSize="inherit" />
                            </IconButtonWrapper>
                        )}
                    </SearchBoxWrapper>
                </LightTooltip>
            </ThemeProvider>
        );
    }

    private handleChange = (event: FormEvent<HTMLTextAreaElement> | FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        var newValue = target.value;

        this.setState({
            search: newValue
        });

        this.props.searchAction(newValue);

        // if (this.state.search !== newValue) {
        //     this.setState({ search: newValue });
        //     if (this.timeOut) {
        //         clearTimeout(this.timeOut);
        //     }
        //     this.timeOut = setTimeout(() => this.props.searchAction(newValue), this.delay)  ;
        // }
    }

    private handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === 13) {
            this.props.searchAction(this.state.search);
        }
    }

    private onClear = () => {
        this.setState({
            search: ''
        });
        
        this.props.searchAction('');
    }
}