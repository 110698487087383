import * as React from 'react';
import { Button, Icon, SemanticICONS } from 'semantic-ui-react';
import glamorous from 'glamorous';
import { PrimaryButton, IButtonProps, IconButton } from 'office-ui-fabric-react/lib/Button';
import { IContextualMenuProps } from 'office-ui-fabric-react/lib/components/ContextualMenu/ContextualMenu.types';

export interface ButtonProps {
    iconKey?: SemanticICONS;
    label?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
    disabled?: boolean;
    loading?: boolean;
    error?: string;
    className?: string;
    linkTo?: string;
}

const ButtonWrapDiv = glamorous.div ({
    [`& .${`icon`}`]: {
        margin: '0 !important',
        // display: 'flex',
        alignItems: 'center',
        width: 'auto'
    }
});

export class RvButton extends React.Component<ButtonProps, {}> {

    private button: Button | null;

    public focus() {
        if (this.button) {
            this.button.focus();
        }
    }

    public render() {

        return (
            <ButtonWrapDiv>
                <Button
                    disabled={this.props.disabled}
                    loading={this.props.loading}
                    onClick={this.props.onClick}
                    className={this.props.className}
                    ref={(item) => this.button = item}
                >
                    {this.props.iconKey ? <Icon name={this.props.iconKey} /> : null}{this.props.label}
                </Button>
            </ButtonWrapDiv>
            
        );
    }
}

/*
* Fabric component
*/

const ButtonsFabricWrapper = glamorous.div({
    [`& .${`ms-Button-icon`}`]: {
        height: 'auto',
    }    
});

const IconButtonWrapper = glamorous(IconButton)({
    '&:hover': {
        backgroundColor: 'transparent'
    }
});

export enum RvButtonType {
    default = 0,
    icon = 1,
    split = 2,
}

export interface RvButtonFabricProps extends IButtonProps {
    rvClassName?: string;
    title?: string;
    primary?: boolean; 
    rvKey?: string; 
    href?: string;
    target?: string;
    ariaDescription?: string;
    onClick?: () => void;
    rvbuttonType?: RvButtonType;
    rvIcon?: string;
    split?: boolean;
    text?: string;
    // style?: string[];
    rvMenuProps?: IContextualMenuProps;

}

export class RvButtonFabric extends React.Component<RvButtonFabricProps, {}> {

    public render(): JSX.Element {
        const { 
            disabled, 
            checked, 
            rvKey, 
            primary, 
            title,
            href,
            target,
            ariaDescription,
            onClick,
            rvbuttonType,
            rvIcon,
            rvClassName,
            split,
            text,
            style,
            rvMenuProps
        } = this.props;
        switch (rvbuttonType) {
            case 1: 
                return (
                    <ButtonsFabricWrapper className={rvClassName}>
                        <IconButtonWrapper
                            disabled={disabled}
                            checked={checked}
                            iconProps={{ iconName: rvIcon }}
                            title={title}
                            ariaLabel={title}
                            onClick={onClick}
                        />
                    </ButtonsFabricWrapper>
                );
            case 2: 
                return (
                    <ButtonsFabricWrapper className={rvClassName}>
                        <PrimaryButton
                            className={rvIcon}
                            primary={primary}
                            data-automation-id={rvKey}
                            disabled={disabled}
                            checked={checked}
                            text={text}
                            onClick={onClick}
                            iconProps={{ iconName: rvIcon }}
                            split={split}
                            style={style}
                            menuProps={rvMenuProps}
                        />
                    </ButtonsFabricWrapper>
                );
            default: 
                return (
                    <ButtonsFabricWrapper className={rvClassName}>
                        <PrimaryButton
                            data-automation-id={rvKey}
                            primary={primary ? true : false}
                            disabled={disabled}
                            checked={checked}
                            href={href}
                            target={target}
                            title={title}
                            ariaDescription={ariaDescription}
                            onClick={onClick}
                            iconProps={{ iconName: rvIcon }}
                        >
                        {text}
                        </PrimaryButton>
                    </ButtonsFabricWrapper>
                );
        }
      }
}