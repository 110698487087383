import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import '../../App.css';
import { graphql } from 'react-apollo';
import glamorous from 'glamorous';
import { ApolloError } from 'apollo-client';
import { RvContainer } from '../Container';
// import { UserCard } from './UserCard';
import { RvButton } from '../Button';
import { RvPanel } from '../Panel';
// import { ChangePassword } from '../ChangePassword';
// import { MatterTerminology } from '../../MatterTerminology';
import { SupportedBrowsers } from '../SupportedBrowsers';
import gql from 'graphql-tag';
import { AppLogo } from './AppLogo';
// import { UserCardDropDown } from './UserCardDropDown';
import { cache } from '../..';
import { LeapFirmProvisionStatusType, GetLeapFirmProvisionStatusType } from '../Leap/LeapFirmProvisionStatusType';
import TimerIcon from '@material-ui/icons/Timer';
import { Badge, Button, IconButton } from '@material-ui/core';
import { TimerContext } from '../../contexts/TimerContext';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import { RvTooltipFabric } from '../Tooltip';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { retrieveTimerSummaryCountData, TimerSummaryData, TimerSummaryParams } from '../../timesheetTimer/TimesheetTimerRepository';
import { showNotification } from '../../App';
import AppMenu from './AppMenu';
import { UserCardV2 } from './UserCardV2';

// tslint:disable-next-line:no-any
const StyledDiv = glamorous.div<{ theme?: any }>((props) => ({
    [`& .${`grid`}`]: {
        padding: '0 34px',
        '@media (max-width: 1024px)': {
            padding: 0,
        },
    },
    [`& .${`right-header`}`]: {
        [`& .${`button`}`]: {
            backgroundColor: 'transparent',
        },
        [`& .${`icon`}`]: {
            color: props.theme.BrandColors.TemplatePrimary,
            fontSize: props.theme.FontSize.REM.size2,
        },
    },
}));

const StyledGridColumn = glamorous(Grid.Column)({
    display: 'flex !important',
    [`> ${`div`}`]: {
        padding: '18px 0',
        display: 'flex',
        alignItems: 'center',
    },
    [`.${`right-header`}`]: {
        justifyContent: 'flex-end',
    },
    [` .${`tenantTitleWrapper`}`]: {
        '@media (max-width: 768px)': {
            display: 'none',
        },
    },
    '& .logoWrapper': {
        '@media (max-width: 480px)': {
            display: 'none',
        },
    },
});

// tslint:disable-next-line:no-any
const Title = glamorous.h1<{ theme?: any }>((props) => ({
    color: props.theme.BrandColors.TextSecondary,
    fontSize: props.theme.FontSize.PX.size6,
    lineHeight: props.theme.LineHeight.Medium,
    fontWeight: props.theme.FontWeight.ThreeHundred,
    margin: 0,
}));

// tslint:disable-next-line:no-any
const Divider = glamorous.div<{ theme?: any }>((props) => ({
    // content: ' ',
    width: '1px',
    height: '50px',
    borderLeft: '1px solid ' + props.theme.NeutralColors.LightBlue1,
    margin: '0 26px',
}));

// tslint:disable-next-line:no-any
const AppMenuDiv = glamorous.div<{ theme?: any }>((props) => ({
    [`& .${`button`}`]: {
        backgroundColor: 'transparent',
        color: props.theme.BrandColors.TemplatePrimary,
        ':hover': {
            backgroundColor: 'transparent !important',
        },
        ':focus': {
            backgroundColor: 'transparent !important',
        },
        ':visited': {
            backgroundColor: 'transparent !important',
        },
        ':active': {
            // backgroundColor: 'transparent !important',
        },
    },
    [`& .${`button.active`}`]: {
        backgroundColor: props.theme.NeutralColors.LightBlue2,
    },
}));

const AppTitleDiv = glamorous.div({
    display: 'flex',
    alignItems: 'center',
});

// tslint:disable-next-line: no-any
const TimerButtonContainer = glamorous.div<{ theme?: any }>((props) => ({
    paddingRight: '10px !important',
    '& .MuiIconButton-root': {
        color: 'rgb(81, 115, 255)',
    },
}));

// tslint:disable-next-line: no-any
const BadgeWrapper = glamorous(Badge)<{ theme?: any }>((props) => ({
    '& .MuiBadge-badge': {
        top: '6px',
        right: '10px',
    }
}));
interface AppHeaderProps {
    tenantId: string;
    isAuthenticated: boolean;
    onToggleMenu?: (showMenu: boolean) => void;
    isForceToChangePass?: boolean;
    onUploadDocuments?: () => void;
    onRequestSupport?: () => void;
    onClickInstructMatter?: () => void;
    onToggleDrawer?: () => void;
}

interface Tenant {
    companyName: string;
    title: string;
    logo: string;
    companyUrl?: string;
    sourceSystem: string;
    leapProvisioningStatus?: string;
    showResetLeapAccessCard?: boolean;
}

// TODO: make this a generic type to extend
interface QueryResult {
    loading?: boolean;
    networkStatus?: number;
    error?: ApolloError;
    tenant?: Tenant;
}

interface QAppHeaderProps extends AppHeaderProps {
    id?: string;
    data?: QueryResult;
}

// var tenantId = sessionStorage.getItem('tenantId');

// TODO: put in its own component or in the user card
/* const UserDropdown = (tenantId: string) => (
  <Dropdown>
    <StyledDropdownMenu>
      <StyledDropdownItem href={'/' + tenantId + '/login/logout'}>
        <RvButton iconKey="sign out" label={MatterTerminology.Logout}/>
      </StyledDropdownItem>
      <StyledDropdownItem>
        <RvPanel
            // isOpen={this.state.showChangePassword}
            // onOpen={this.toggleVisibilityChangePass}
            iconKey="unlock alternate"
            label={MatterTerminology.ChangePassword}
            size="custom"
        >
          <ChangePassword />
        </RvPanel>
      </StyledDropdownItem>
      <StyledDropdownItem>
        <RvPanel
          // isOpen={this.state.showSupportedBrowser}
          // onOpen={this.toggleVisibilitySupportedBrowser}
          iconKey="browser"
          label={MatterTerminology.SupportedBrowsers}
          size="small"
        >
          <SupportedBrowsers />
        </RvPanel>
      </StyledDropdownItem>
    </StyledDropdownMenu>
  </Dropdown>
); */

const SupportedBrowserDrawer = () => (
    <RvPanel
        // isOpen={this.state.showSupportedBrowser}
        // onOpen={this.toggleVisibilitySupportedBrowser}
        iconKey="info circle"
        size="small"
    >
        <SupportedBrowsers />
    </RvPanel>
);

// const initialState = {
//   showMenu: false
// };
// type AppBarState = Readonly<typeof initialState>;

interface AppBarState {
    showMenu?: boolean;
}

class AppBar extends React.Component<QAppHeaderProps, AppBarState> {
    // readonly state: AppBarState = initialState;

    static contextType = TimerContext;

    constructor(props: QAppHeaderProps, state: AppBarState) {
        super(props, state);
        this.toggleMenuVisibility = this.toggleMenuVisibility.bind(this);
        this.handleSidebarHide = this.handleSidebarHide.bind(this);
        this.onClickUploadDocument = this.onClickUploadDocument.bind(this);
        this.onClickSupport = this.onClickSupport.bind(this);
        this.onClickInstructMatter = this.onClickInstructMatter.bind(this);
        this.fetchTimerSummary = this.fetchTimerSummary.bind(this);
        this.onRetrieveTimerSummary = this.onRetrieveTimerSummary.bind(this);
        this.onToggleDrawer = this.onToggleDrawer.bind(this);
        this.state = {
            showMenu: false,
        };

        if (this.props.isAuthenticated) {
            this.fetchTimerSummary();
        }
    }

    render() {
        // let loading = (!this.props.data || this.props.data.loading);
        // if (loading) { return null; }

        /* if (!loading && this.props.data!.networkStatus === 8) {
      return <div>Error</div>;
    } */

        const { totalTimer } = this.context;

        let title = this.props.data && this.props.data.tenant ? this.props.data.tenant.title : undefined;
        let tenantTitle = (
            <AppTitleDiv>
                <Divider />
                <Title>{title}</Title>
            </AppTitleDiv>
        );

        let leapProvisioningStatus = LeapFirmProvisionStatusType.DoNotProvision;
        let sourceSystem = '';
        if (this.props.data && this.props.data.tenant) {
            sourceSystem = this.props.data.tenant.sourceSystem && this.props.data.tenant.sourceSystem.toLocaleLowerCase();

            if (sourceSystem === 'leap' && this.props.data.tenant.leapProvisioningStatus) {
                // tslint:disable-next-line:max-line-length
                leapProvisioningStatus = GetLeapFirmProvisionStatusType(this.props.data.tenant.leapProvisioningStatus);
            }
        }

        cache.writeData({
            data: {
                leapProvisioningStatus: leapProvisioningStatus,
                sourceSystem: sourceSystem,
            },
        });

        let appMenu =
            !this.props.isAuthenticated ||
            (leapProvisioningStatus !== LeapFirmProvisionStatusType.DoNotProvision && leapProvisioningStatus !== LeapFirmProvisionStatusType.Complete) ? null : (
                <AppMenuDiv>
                    <RvButton iconKey={this.state.showMenu ? 'x' : 'content'} onClick={this.toggleMenuVisibility} className={this.state.showMenu ? 'active' : ''} />
                    <AppMenu show={this.state.showMenu} handleSidebarHide={this.handleSidebarHide} />
                </AppMenuDiv>
            );

        // let userCard = !this.props.isAuthenticated ? null : <UserCard />;

        let timerButton = this.props.isAuthenticated && UserFeature.HasAccess(UserFeatureEnum.hasTimesheetTimer) && (
            <RvTooltipFabric hint="Timesheet Timers">
                <TimerButtonContainer>
                    <BadgeWrapper badgeContent={totalTimer} color="error">
                        <IconButton aria-label="timer" onClick={this.onToggleDrawer}>
                            <TimerIcon fontSize="large" />
                        </IconButton>
                    </BadgeWrapper>
                </TimerButtonContainer>
            </RvTooltipFabric>
        );

        let documentButton = this.props.isAuthenticated && UserFeature.HasAccess(UserFeatureEnum.hasUploadMatterDocument) && (
            <RvTooltipFabric hint="Upload Document">
                <TimerButtonContainer>
                    <IconButton aria-label="timer" onClick={this.onClickUploadDocument}>
                        <PostAddIcon fontSize="large" />
                    </IconButton>
                </TimerButtonContainer>
            </RvTooltipFabric>
        );

        let instructMatterButton = this.props.isAuthenticated && UserFeature.HasAccess(UserFeatureEnum.hasDebtCollection) && (
            <RvTooltipFabric hint="New Matter Instructions">
                <TimerButtonContainer>
                    <IconButton aria-label="timer" onClick={this.onClickInstructMatter}>
                        <DescriptionOutlinedIcon fontSize="large" />
                    </IconButton>
                </TimerButtonContainer>
            </RvTooltipFabric>
        );

        let supportButton = (
            <RvTooltipFabric hint="Contact Support">
                <TimerButtonContainer>
                    <Button 
                        // color="primary"
                        style={{
                            color: 'rgb(81, 115, 255)',
                        }}
                        // variant="contained"
                        aria-label="timer" 
                        onClick={this.onClickSupport}
                        startIcon={<ContactSupportOutlinedIcon fontSize="large" />}
                    >
                        Get Support
                    </Button>
                </TimerButtonContainer>
            </RvTooltipFabric>
        );

        let showResetLeapAccessCard: boolean = false;

        if (sourceSystem === 'leap' && this.props.data && this.props.data.tenant && this.props.data.tenant.showResetLeapAccessCard) {
            showResetLeapAccessCard = this.props.data.tenant.showResetLeapAccessCard;
        }

        // let userCardDropdown = !this.props.isAuthenticated ? (
        //     <SupportedBrowserDrawer />
        // ) : (
        //     // tslint:disable-next-line:max-line-length
        //     <UserCardDropDown tenantId={this.props.tenantId} isForceToChangePass={this.props.isForceToChangePass} showResetLeapAccess={showResetLeapAccessCard} />
        // );

        const userCardv2 = !this.props.isAuthenticated ? (
            <SupportedBrowserDrawer />
        ) : (
            <UserCardV2 
                isForceToChangePass={this.props.isForceToChangePass}
                showResetLeapAccess={showResetLeapAccessCard}
                tenantId={this.props.tenantId}
            />
        );

        const logoStorageKey: string = this.props.tenantId + '_brandlogo';
        // tslint:disable-next-line:no-any
        let logo: any = this.props.data && this.props.data.tenant ? this.props.data.tenant.logo : undefined;
        if (!this.props.isAuthenticated) {
            // tslint:disable-next-line:no-any
            let brandLogo: any = localStorage.getItem(logoStorageKey);
            if (brandLogo !== null && brandLogo.length > 0) {
                logo = brandLogo;
            }
        } else {
            if (logo) {
                localStorage.setItem(logoStorageKey, logo);
            }
        }

        let logoUrl = this.props.data && this.props.data.tenant && this.props.data.tenant.companyUrl ? this.props.data.tenant.companyUrl : 'http://www.RedRainCorp.com';

        return (
            <StyledDiv>
                <RvContainer rvClassName="header-container">
                    <Grid>
                        <StyledGridColumn width={11}>
                            <div>{appMenu}</div>
                            <div>
                                <div className="logoWrapper">
                                    <AppLogo logoUrl={logoUrl} logoImg={logo} />
                                </div>
                                <div className="tenantTitleWrapper">{tenantTitle}</div>
                            </div>
                        </StyledGridColumn>
                        <StyledGridColumn width={5} className="right-header">
                            {instructMatterButton}
                            {documentButton}
                            {timerButton}
                            {supportButton}
                            {userCardv2}
                            {/* <div>{userCard}</div>
                            <div>{userCardDropdown}</div> */}
                        </StyledGridColumn>
                    </Grid>
                </RvContainer>
            </StyledDiv>
        );
    }

    private toggleMenuVisibility() {
        var show = !this.state.showMenu;
        if (this.props.onToggleMenu) {
            this.props.onToggleMenu(show);
        }
        this.setState({ showMenu: show });
    }

    private handleSidebarHide(value: boolean) {
        this.setState({ showMenu: value });
    }

    private onClickUploadDocument() {
        if (this.props.onUploadDocuments) {
            this.props.onUploadDocuments();
        }
    }

    private onToggleDrawer() {
        if (this.props.onToggleDrawer) {
            this.props.onToggleDrawer();
        }
    }

    private onClickSupport() {
        if (this.props.onRequestSupport) {
            this.props.onRequestSupport();
        }
    }

    private onClickInstructMatter() {
        if (this.props.onClickInstructMatter) {
            this.props.onClickInstructMatter();
        }
    }

    private fetchTimerSummary() {
        const timerParams: TimerSummaryParams = {
            filter: '',
            first: 1000,
            offset: 0,
        };

        retrieveTimerSummaryCountData(
            timerParams,
            true,
            (data: TimerSummaryData) => this.onRetrieveTimerSummary(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    }

    private onRetrieveTimerSummary(data: TimerSummaryData) {
        const { setInitialTimerTotal } = this.context;
        setInitialTimerTotal(data.timerSummary.recordCount);
    }
}

const TenantData = gql`
    query Tenant($tenantId: String) {
        tenant(tenantId: $tenantId) {
            tenantId
            title
            logo
            companyUrl
            sourceSystem
            leapProvisioningStatus
            showResetLeapAccessCard
        }
    }
`;

// tslint:disable-next-line:no-any
export const AppHeader = graphql<any, AppHeaderProps, any>(TenantData, {
    options: ({ tenantId }) => ({ 
        variables: { tenantId: tenantId },
        fetchPolicy: 'cache-first'
    }),
})(AppBar);
