import * as React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { RvLabel, LabelStyle } from './Label/Label';
import { RvInput } from './Input';
import glamorous from 'glamorous';
import { AppStateUpdate, showNotification, RouterHistoryProps } from '../App';
import { FormEvent } from 'react';
import { RvButton } from './Button';
import gql from 'graphql-tag';
import { client } from '..';
import { RvCheckbox, CheckboxState } from './Checkbox';
import { Error } from './Error';
// import { graphql } from 'react-apollo';
import { RvLoader } from './Loader';
import { MatterTerminology } from '../MatterTerminology';
import { ForgottenPassword } from './ForgottenPassword';
import { PreloadComponents } from './LoadComponentAsync';
import { ChangePassword } from './ChangePassword';
import { setTimeout } from 'timers';
import { LeapFirmProvisionStatusType, GetLeapFirmProvisionStatusType } from './Leap/LeapFirmProvisionStatusType';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
// import { mainTheme } from '../Theme';
import { FetchPolicy } from 'apollo-client';

// tslint:disable-next-line:no-any
const LoginSegment = glamorous(Segment)<{ theme?: any }>((props) => ({
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '480px',
    minHeight: '370px',
    boxShadow: '0 1px 3px 0 ' + props.theme.NeutralColors.BackgroundGrey4,
    borderRadius: '8px',
    border: '1px solid ' + props.theme.NeutralColors.BackgroundGrey4,
    margin: '0 auto !important',
    [`& .${`checkbox `}`]: {
        fontWeight: props.theme.FontWeight.SixHundred,
        marginLeft: '-2rem'
    },    
    [`& .${`button `}`]: {
        fontWeight: props.theme.FontWeight.SixHundred + ' !important',
    }
}));

const LoginInputs = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 3rem',
    width: '100%',
});

// tslint:disable-next-line:no-any
const LoginLabel = glamorous.div<{ theme?: any }>((props) => ({
    // margin: '0 2rem',
    color: props.theme.NeutralColors.Dark,
    fontWeight: props.theme.FontWeight.SevenHundred,
    marginBottom: '60px',
    display: 'block',
    '@media (max-width: 768px)': {
        marginBottom: '20px',
    },
    [`& .${`rvlabel`}`]: {
        fontSize: props.theme.FontSize.REM.size3 + ' !important',
        '@media (max-width: 768px)': {
            fontSize: props.theme.FontSize.REM.size2 + ' !important',
        },
    }    
}));

const LoginNavGrid = glamorous(Grid)({
    width: '100%',
    padding: '0rem 3rem 0 3rem !important',
    margin: '0 !important',
    [`& .${`column`}`]: {
        marginLeft: '-8px !important',
    },  
    [`& .${`row`}`]: {
        margin: '0 1rem !important'
    }    
});

const button = `button`;
// tslint:disable-next-line:no-any
const LoginButton = glamorous.div<{ theme?: any }>((props) => ({
    // display: 'flex',
    // justifyContent: 'flex-end',
    // width: '384px',
    // paddingRight: '10px',
    display: 'inline-block',
    width: '100%',
    borderTop: '1px solid ' + props.theme.NeutralColors.LightBlue1,
    paddingTop: '20px',
    marginTop: '20px',
    [`& .${button}`]: {
        color: props.theme.NeutralColors.BackgroundWhite,
        backgroundColor: props.theme.BrandColors.TemplatePrimary,
        padding: '17px 80px'
    }
}));

// tslint:disable-next-line:no-any
const ForgotPassword = glamorous(Grid.Column)<{ theme?: any }>((props) => ({
    '&&': {
        textAlign: 'right',
        color: props.theme.BrandColors.TemplatePrimary,
        paddingRight: '0px !important',
        marginLeft: '0px !important',
    },
    [`& .${`button`}`]: {
        backgroundColor: 'transparent !important',
        color: props.theme.BrandColors.TemplatePrimary,
        padding: 0,
        fontWeight: props.theme.FontWeight.Normal,
    }
}));

const content = `content`;
const DisplayErrors = glamorous.div({
    display: 'block',
    height: '80px',
    justifyContent: 'center',
    // width: '350px',
    width: '100%',
    // margin: '1rem',
    [`& .${content}`]: {
        textAlign: 'left'
    }
});

const ErrorContainer = glamorous.div({
    display: 'inline-block',
    width: '100%',
    padding: '10px 4.5rem 0 4.5rem',
});

const LoginColumn = glamorous.div({
    display: 'inline-block',
    width: '100%',
    padding: '40px 0',
});

const LoginGrid = glamorous(Grid)({
    '&&': {
        // height: window.innerHeight - 150,
        alignItems: 'center',
        margin: 0
    }
});

const LoginGridRow = glamorous(Grid.Row)({
    display: 'block !important',
    padding: '0 10px !important',
});

const LoginDiv = glamorous.div ({
    padding: '100px 0',
    [`& .${`sidebar`}`]: {
        top: '12px !important'
    },
    [`& .${`segment`}`]: {
        top: '0px !important'
    },
    '@media (max-width: 768px)': {
        padding: '30px 0',
    }
});

const DialogDiv = glamorous(Dialog)({
    // width: '100%',
    // minWidth: '400px',
    // minHeight: '400px'
});

// const DialogTitleDiv = glamorous(DialogTitle)({
//     color: mainTheme.TemplateColor.Primary,
// });

interface LoginProps extends RouterHistoryProps {
    match: {
        params: {
            tenantId: string
            command: string
        }
    };
    onLogin?: (isAuthenticated: boolean) => void;
    onTenant?: (tenantId: string) => void;
    onForceToChangePass?: (isChange: boolean) => void;
}

interface LoginState {
    userId: string;
    password: string;
    tenantId: string;
    rememberMe: boolean;
    autoLogin: boolean;
    mfaCodeRequired: boolean;
    isForceChangePassword: boolean;
    mfaCode: string;

    splashScreenTemplate?: string;
    isOpenSplashScreen: boolean;
    showPassword: boolean;
}

export interface TokenResponse {
    accessToken: string;
    refreshToken: string;
    error: string;
    errorDescription: string;
    expiresIn: number;
    isError: boolean;
    statusCode: number;
    authenticationMethod: string;
    forceChangePassword: boolean;
}

interface OneTimePassword  {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    statusCode: number;
    isError: boolean;    
    errorDescription: string;
    error: string;
    forceChangePassword: boolean;
}

export default class Login extends React.Component<LoginProps, LoginState> {
    private isLoggingIn: boolean;
    private isLoggedInMfa: boolean;
    private errorLogin: boolean;
    private errorMfa: boolean;
    private errorSummary: string;
    private errorDescription: string;
    private refreshToken: string;
    private userIdToken: string;
    private userName: RvInput | null;
    private password: RvInput | null;
    private loginButton: RvButton | null;
    private rememberMeCheckBox: RvCheckbox | null;
    private mfaCode: RvInput | null;
    // tslint:disable-next-line: no-any
    private appUser: any;

    // tslint:disable-next-line:no-any
    constructor(props: LoginProps, context?: any) {
        super(props, context);
        
        var tenantId = (this.props.match && this.props.match.params.tenantId) 
                        ? this.props.match.params.tenantId 
                        : '';
        this.refreshToken = 'refreshToken_' + tenantId;
        this.userIdToken = 'userId_' + tenantId;
        var rememberMe = (this.getRefreshToken() != null);
        var userId = this.getUserId();
        this.isLoggingIn = false;
        this.isLoggedInMfa = false;

        // Clear token if refreshing login page
        // sessionStorage.removeItem('tenantId');
        // sessionStorage.setItem('accessToken', '');
        AppStateUpdate.loggedOn = false;
      
        this.state = {
            userId: userId != null ? userId : '',
            password: '',
            tenantId: tenantId,
            rememberMe: rememberMe,
            autoLogin: rememberMe,
            mfaCodeRequired: false,
            mfaCode: '',
            isForceChangePassword: false,
            isOpenSplashScreen: false,
            showPassword: false
        };

        this.handleRemberMe = this.handleRemberMe.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleMfaClick = this.handleMfaClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handlePasswordResetSuccess = this.handlePasswordResetSuccess.bind(this);
        this.handlePasswordResetFailure = this.handlePasswordResetFailure.bind(this);
        this.navigateToPage = this.navigateToPage.bind(this);
        // this.fetchUserSplashScreen = this.fetchUserSplashScreen.bind(this);
        this.onCloseSplashScreen = this.onCloseSplashScreen.bind(this);
        this.onToggleShowPassword = this.onToggleShowPassword.bind(this);
    }

    componentDidMount() {
         /*
        var appInitState = AppStateUpdate.getAppState();
        if (appInitState.tenant !== this.state.tenantId) {
            appInitState.tenant = this.state.tenantId;
            AppStateUpdate.updateAppState(appInitState);
        } */
        if (this.props.onTenant) { this.props.onTenant(this.state.tenantId); }
        if (this.state.autoLogin) {
            this.loginRemember();
        }

        // if user is already logged in and hit the login url again
        if (sessionStorage.getItem('tenantId') === this.state.tenantId &&
            sessionStorage.getItem('accessToken') !== null &&
            sessionStorage.getItem('accessToken') !== '' && 
            sessionStorage.getItem('isLoggedIn') !== null) {
            this.isLoggingIn = true;
            this.isLoggedInMfa = true;

            if (this.props.onLogin) { 
                this.props.onLogin(true); 
            }                 
            this.checkForLeapTenantAutoProvisioning();
        } else {
            // if user tries different tenant without logging out previous one, wipe out local and session storage data
            sessionStorage.removeItem('accessToken');            
            UserFeature.Clear();
            sessionStorage.removeItem('tenantId');

            let length = localStorage.length;
            
            while (length--) {
                let key = localStorage.key(length) || '';
                if (/refreshToken_/.test(key)) {
                    localStorage.removeItem(key);
                }  
            }
        }
        
        setTimeout(() => {
            if (this.userName && !this.validateString(this.state.userId)) {
                this.userName.focus();
            } else if (this.password && this.validateString(this.state.userId)) {
                this.password.focus();
                if (this.rememberMeCheckBox) {
                    this.rememberMeCheckBox.Check();
                }
            } else if (this.loginButton) {
                this.loginButton.focus();
            } else if (this.mfaCode) {
                this.mfaCode.focus();
            }            
            // tslint:disable-next-line:align
        }, 500);

        setTimeout(() => {
            PreloadComponents();            
            // tslint:disable-next-line:align
        }, 1000);
    }

    handleResendCode = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        this.resendVerificationCode();
      }

    public render() {
        /*  
        if (this.props.data && !this.props.data.loading &&
            this.props.data.tenant && !this.props.data.tenant.tenantId) {
            window.location.replace('http://www.redview.com.au/');
            return <div>Redirecting to RedView....</div>;
        } */   

        if (this.state.autoLogin) {
            return (<RvLoader size="large" />);
        }

        let errorMessage;
        if (this.errorLogin && (this.errorSummary || this.errorDescription)) {
            errorMessage = (
                <ErrorContainer>
                    <DisplayErrors>
                        <Error
                            headerMessage={this.errorSummary}
                            message={this.errorDescription}
                        />
                    </DisplayErrors>
                </ErrorContainer>
            );
        } else {
            errorMessage = '';
        }

        if (this.errorMfa && (this.errorSummary || this.errorDescription)) {
            errorMessage = (
                <ErrorContainer>
                    <DisplayErrors>
                        <Error
                            headerMessage={this.errorSummary}
                            message={this.errorDescription}
                        />
                    </DisplayErrors>
                </ErrorContainer>
            );
        }
        
        // tslint:disable-next-line:no-any
        let inputs: any;

        if (this.state.mfaCodeRequired) {
            // MFA
            inputs = (
                <React.Fragment>
                    {MatterTerminology.MultifactorAuthenticationMessage}
                    <RvInput
                        iconKey="key"
                        hint={MatterTerminology.AuthenticationCode}
                        value={this.state.mfaCode}
                        onChange={this.changeMfaCode}
                        disabled={this.isLoggedInMfa}
                        error={this.errorMfa}
                        onKeyDown={this.handleKeyDownMfa}
                        focus={true}
                        ref={(item) => this.mfaCode = item}
                        iconPosition="left"
                        size="big"
                    />
                </React.Fragment>
            );            
            
        } else if (this.state.isForceChangePassword) {
            // Force change password component
            inputs = (
                <React.Fragment>
                    <ChangePassword
                        handlePasswordResetSuccess={this.handlePasswordResetSuccess}
                        handlePasswordResetFailure={this.handlePasswordResetFailure}
                        isForcedPasswordChange={true}
                        userName={this.state.userId}
                        tenantId={this.state.tenantId}
                    />
                </React.Fragment>
            );
        } else {
            // Usual login process inputs
            inputs = (
                <React.Fragment>
                    <RvInput
                        iconKey="user"
                        hint={MatterTerminology.Username}
                        value={this.state.userId}
                        onChange={this.changeUserId}
                        disabled={this.isLoggingIn}
                        error={this.errorLogin}
                        onKeyDown={this.handleKeyDown}
                        focus={true}
                        ref={(item) => this.userName = item}
                        iconPosition="left"
                        size="big"
                    />
                    <RvInput
                        iconKey="lock"
                        hint={MatterTerminology.Password}
                        secure={!this.state.showPassword}
                        value={this.state.password}
                        onChange={this.changePassword}
                        disabled={this.isLoggingIn}
                        error={this.errorLogin}
                        onKeyDown={this.handleKeyDown}
                        ref={(item) => this.password = item}
                        iconPosition="left"
                        size="big"
                        action={{ 
                            icon: this.state.showPassword ? 'hide' : 'unhide',
                            onClick: this.onToggleShowPassword
                        }}
                    />
                </React.Fragment>
            );
        }

        // tslint:disable-next-line:no-any
        let buttons: any;
        if (this.state.mfaCodeRequired) {
            // MFA Button
            buttons = (  
                <LoginButton>        
                    <RvButton
                        label={MatterTerminology.Verify}
                        onClick={this.handleMfaClick}
                        disabled={this.isLoggedInMfa}
                        loading={this.isLoggedInMfa}
                        ref={(item) => this.loginButton = item}
                    />
                </LoginButton>     
            );
        } else if (this.state.isForceChangePassword) {
            // No button is required for Force Change password.
            buttons = null;
        } else {
            // Usual Login button
            buttons = (
                <LoginButton>
                    <RvButton
                        label={MatterTerminology.Login}
                        onClick={this.handleClick}
                        disabled={this.isLoggingIn}
                        loading={this.isLoggingIn}
                        ref={(item) => this.loginButton = item}
                    />
                </LoginButton>
            );
        }
        
        // tslint:disable-next-line:no-any
        let controls: any;

        if (this.state.mfaCodeRequired) {
            // <FA
            controls =  (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" onClick={this.handleResendCode} >
                    {MatterTerminology.ResendVerificationCode}
                </a>
            );
        } else if (this.state.isForceChangePassword) {
            controls = null;
        } else {
            // Usual Login process
            controls = (
                <LoginNavGrid columns={2}>                                            
                    <Grid.Row>                                                
                        <Grid.Column>
                            <RvCheckbox
                                label={MatterTerminology.RememberMe}
                                isChecked={this.state.rememberMe}
                                onChange={this.handleRemberMe}
                                ref={(item) => this.rememberMeCheckBox = item}
                            />
                        </Grid.Column>
                        <ForgotPassword>
                            <ForgottenPassword
                                userID={this.state.userId}
                                tenantId={this.state.tenantId}
                            />
                        </ForgotPassword>                                               
                    </Grid.Row>                                            
                </LoginNavGrid>
            );
           
        }

        return (
                <LoginDiv className="login-container">
                    <LoginGrid centered={true}>
                        <LoginGridRow centered={true}>
                            <LoginLabel>
                                <RvLabel label={MatterTerminology.MemberLogin} style={LabelStyle.Heading2} />
                            </LoginLabel>
                            <LoginSegment
                                padded="very"
                            >
                                <LoginColumn className="test">
                                    <LoginInputs>
                                        {inputs}
                                    </LoginInputs>
                                    {controls}
                                    {errorMessage}                                       
                                    {buttons}                                      
                                </LoginColumn>
                            </LoginSegment>
                        </LoginGridRow>
                    </LoginGrid>
                    {this.getSplashScreen()}
                </LoginDiv>
        );
    }

    private handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.login();
    }

    private handleMfaClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.multifactorAuthentication();
    }

    private validateString = (value: string, name?: string): boolean => {
        if (value == null || value.trim().length === 0) {
            if (name) {
                showNotification(null, `"${name}" can not be blank`, 'warning');
            }
            return false;
        }
        return true;
    }

    private login = () => {
        this.clearRefreshToken();

        if (!this.validateString(this.state.tenantId, 'tenant') ||
            !this.validateString(this.state.userId, 'user name') ||
            !this.validateString(this.state.password, 'password')) {
            return;
        }

        this.isLoggingIn = true;     

        client.mutate({
            mutation: LoginMutation,
            variables: {
                tenantId: this.state.tenantId,
                userName: this.state.userId,
                password: this.state.password
            }
        }).then((results: {
            data: {
                login: TokenResponse
            }
        }) => {
            var response = results.data.login;
            if (!this.loginSuccess(response, this.state.rememberMe, this.state.userId)) {
                if (response.statusCode === 404) {
                    this.isLoggingIn = false;
                    this.errorLogin = true;
                    this.errorSummary = 'Invalid Tenant';
                    this.forceUpdate();
                } else {
                    this.isLoggingIn = false;
                    this.errorLogin = true;
                    this.errorSummary = 'Failed to Login';
                    this.errorDescription = response.errorDescription;
                    this.forceUpdate();
                }
            }           
        
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            this.isLoggingIn = false;
            this.errorLogin = true;
            this.errorSummary = 'System Error';
            this.errorDescription = reason;
            this.forceUpdate();
        }); 
    }

    /**
     * Used to Verify the Authentication code in Multifactor Authentication
     */
    private multifactorAuthentication = () => {
        if (!this.validateString(this.state.tenantId, 'tenant') ||
            !this.validateString(this.state.mfaCode, 'authentication code')) {
            return;
        }

        this.isLoggedInMfa = true;
        // this.forceUpdate();

        client.mutate({
            mutation: MultifactorAuthenticationMutation,
            variables: {
                tenantId: this.state.tenantId,
                otp: this.state.mfaCode,
                token: sessionStorage.getItem('accessToken')
            }
        }).then((results: {
            data: {
                otp: OneTimePassword
            }
        }) => {
            var response = results.data.otp;
            if (!this.loginMfaSuccess(response, this.state.rememberMe, this.state.userId)) {
                    if (response.statusCode === 404) {
                        this.isLoggedInMfa = false;
                        this.errorMfa = true;
                        this.errorSummary = 'Invalid Tenant';
                        this.forceUpdate();
                    } else {
                        this.isLoggedInMfa = false;
                        this.errorMfa = true;
                        this.errorSummary = 'Failed to verify';
                        this.errorDescription = MatterTerminology.VerificationFailed;
                        this.forceUpdate();
                    }
                }
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            this.isLoggedInMfa = false;
            this.errorMfa = true;
            this.errorSummary = 'System Error';
            this.errorDescription = reason;
            this.forceUpdate();
        });
    }

    /**
     * Used to resend verification code
     */
    private resendVerificationCode = () => {

        if (!this.validateString(this.state.tenantId, 'tenant')) {
            return;
        }

        this.isLoggedInMfa = true;

        client.mutate({
            mutation: SendVerificationCodeMutation,
            variables: {
                tenantId: this.state.tenantId,
                token: sessionStorage.getItem('accessToken')
            }
        }).then((results: {
            data: {
                resendOtp: OneTimePassword
            }
        }) => {
            var response = results.data.resendOtp;

            if (response.statusCode === 200) {
                this.isLoggedInMfa = false; 
                sessionStorage.setItem('accessToken', response.accessToken);
                UserFeature.Init();
                showNotification(null, MatterTerminology.VerificationCodeResendSuccessMessage, 'success');  
                
            } else {
                if (response.statusCode === 404) {
                    this.isLoggedInMfa = false;
                    this.errorMfa = true;
                    this.errorSummary = 'Invalid Tenant';
                    this.forceUpdate();
                } else {
                    this.isLoggedInMfa = false;
                    this.errorMfa = true;
                    this.errorSummary = 'Failed to resend verification code';
                    this.errorDescription = response.errorDescription;
                    this.forceUpdate();
                }
            }

            return true;
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            this.isLoggedInMfa = false;
            this.errorMfa = true;
            this.errorSummary = 'System Error';
            this.errorDescription = reason;
            this.forceUpdate();
        });
    }

    private handleRemberMe = (event: FormEvent<HTMLInputElement>, data: CheckboxState) => {
        this.setState({ rememberMe: data.checked });
        if (!data.checked) {
            this.clearRefreshToken();
        }
    }

    private changeUserId = (event: FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ userId: target.value });
    }

    private changePassword = (event: FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ password: target.value });
    }

    private changeMfaCode = (event: FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ mfaCode: target.value });
    }
   
    private handleKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === 13) {
            this.login();
        }
    }

    private handleKeyDownMfa = (event: KeyboardEvent) => {
        if (event.keyCode === 13) {
            this.multifactorAuthentication();
        }
    }

    private loginSuccess = (response: TokenResponse, storeToken: boolean, userId: string): boolean => {
        var vm = this;
        if (response.statusCode === 200) {
            this.errorLogin = false;

            if (response.forceChangePassword === true) {
                this.setLoginValuesForChangePassword(response, userId);
                this.setState({
                    isForceChangePassword : true
                });
                return true;
            } else {
                sessionStorage.setItem('accessToken', response.accessToken);
                UserFeature.Init();
                if (storeToken) {
                    this.setRefreshToken(response.refreshToken);
                    this.setUserId(userId);   
                } else {
                    this.setUserId('');
                }

                // Set Multifactor authentication required
                if (response.authenticationMethod !== null &&
                    response.authenticationMethod !== undefined && 
                    response.authenticationMethod.length !== 0) {
                    sessionStorage.setItem('mfaCodeRequired', '1');
                    vm.setState({ mfaCodeRequired : true});
                    return true;
                } else {
                    sessionStorage.setItem('mfaCodeRequired', '0');
                    vm.setState({ mfaCodeRequired : false});
                }    

                if (response.authenticationMethod === null) {
                    // AppStateUpdate.loggedOn = true;
                    return this.setLoginValues();
                }
            }
        }
        return false;
    }

    private loginMfaSuccess = (response: OneTimePassword, storeToken: boolean, userId: string): boolean => {
        if (response.statusCode === 200) {
            if (response.forceChangePassword) {
                this.setLoginValuesForChangePassword(response, userId);
                this.setState({
                    isForceChangePassword : true,
                    mfaCodeRequired: false
                });
                return true;
            } else {
                sessionStorage.setItem('accessToken', response.accessToken);     
                UserFeature.Init();           
                if (storeToken) {
                    this.setRefreshToken(response.refreshToken);
                    this.setUserId(userId);             
                } else {
                    this.setUserId('');
                }
    
                return this.setLoginValues();   
            }            
        }
        return false;
    }

    private setLoginValues() {
        sessionStorage.setItem('tenantId', this.state.tenantId);
        sessionStorage.setItem('isLoggedIn', 'true');

        this.checkForLeapTenantAutoProvisioning();             
        return true;
    }

    private redirect() {
        if (this.props.onLogin) {
            this.props.onLogin(true);
        }
    }

    private checkForLeapTenantAutoProvisioning() {
        client.query({
            query: TenantData
            // tslint:disable-next-line:no-any
        }).then((results: {
            // tslint:disable-next-line:no-any
            data: any;
            }) => {                    
                if (results.data && results.data.tenant && 
                    results.data.tenant.sourceSystem === 'Leap' &&
                    results.data.tenant.leapProvisioningStatus !== null && 
                    // tslint:disable-next-line:max-line-length
                    GetLeapFirmProvisionStatusType(results.data.tenant.leapProvisioningStatus) !== LeapFirmProvisionStatusType.DoNotProvision &&
                    // tslint:disable-next-line:max-line-length
                    GetLeapFirmProvisionStatusType(results.data.tenant.leapProvisioningStatus) !== LeapFirmProvisionStatusType.Complete) {
                    this.props.history.push('/LeapSignIn');
                    this.redirect();
                } else {
                    // For all other source systems
                    this.setUserData();
                }                    
            // tslint:disable-next-line:no-any
            }).catch((reason: any) => {
                showNotification(null, reason, 'error');
        });
    }
    
    private setUserData() {
        let vm = this;

        client.query({
            query: UserData,
            fetchPolicy: 'network-only'
            // tslint:disable-next-line:no-any
        }).then((results: {
            // tslint:disable-next-line:no-any
            data: any;
            }) => {
                // Display Splash Screen
                if (this.state.autoLogin) {
                    vm.manageReDirect(results.data.appUser);
                } else {
                    vm.appUser = results.data.appUser;
                    
                    if (this.state.userId !== undefined && this.state.userId.length > 0) {
                        vm.fetchUserSplashScreen(results.data.appUser);
                    } else {
                        vm.manageReDirect(results.data.appUser);
                    }                    
                }

                // // check matter access count
                // if (results.data.appUser.matterAccessCount === 1) {
                //     this.navigateToMatterDetails(results.data.appUser.defaultMatterId);
                // } else if (results.data.appUser.isAdministrator) {
                //     // this.props.history.push('/admin');
                //     this.navigateToPage(true);
                // } else {
                //     this.navigateToPage(false);
                //     // this.props.history.push('/matterSummary');
                // }
                // this.redirect();
            // tslint:disable-next-line:no-any
            }).catch((reason: any) => {
                showNotification(null, reason, 'error');
        });
    }

    // tslint:disable-next-line: no-any
    private fetchUserSplashScreen(appUser: any) {
        let vm = this;
        var fetchPolicy: FetchPolicy = 'network-only';

        client.query({
            query: SplashScreenData,
            fetchPolicy: fetchPolicy
            // tslint:disable-next-line:no-any
        }).then((results: {
            // tslint:disable-next-line:no-any
            data: any;
            }) => {                    
                if (results.data && results.data.appUser && 
                    results.data.appUser.splashScreen &&
                    results.data.appUser.splashScreen.template !== null ) {
                        vm.setState({
                        isOpenSplashScreen: true, 
                        splashScreenTemplate: results.data.appUser.splashScreen.template
                    });
                } else {
                    vm.manageReDirect(appUser);
                }        
            // tslint:disable-next-line:no-any
            }).catch((reason: any) => {
                showNotification(null, reason, 'error');
        });
    }

    // tslint:disable-next-line: no-any
    private manageReDirect(appUser: any) {
        // check matter access count
        if (appUser.matterAccessCount === 1) {
            this.navigateToMatterDetails(appUser.defaultMatterId);
        } else if (appUser.isAdministrator) {
            // this.props.history.push('/admin');
            this.navigateToPage(true, appUser.isInternalAdmin, appUser.isInternal);
        } else {
            this.navigateToPage(false, appUser.isInternalAdmin, appUser.isInternal);
            // this.props.history.push('/matterSummary');
        }
        this.redirect();
    }

    private getSplashScreen() {
        return (
            <DialogDiv
                open={this.state.isOpenSplashScreen}
                onClose={this.onCloseSplashScreen}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}
                // className={classes.root}
            >
                {/* <DialogTitleDiv
                    id="form-dialog-title"
                    // className={classes.dialogTitle}
                >
                    Splash Screen
                </DialogTitleDiv> */}
                <DialogContent dividers={true}>
                    <React.Fragment>
                        {
                            this.state.splashScreenTemplate 
                            ? (<div dangerouslySetInnerHTML={{__html: this.state.splashScreenTemplate}} /> )
                            : null
                        }
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCloseSplashScreen} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </DialogDiv>
        );
    }

    private onCloseSplashScreen() {
        if (this.appUser) {
            this.manageReDirect(this.appUser);
        }
    }

    private navigateToPage(isAdmin: boolean, isInternalAdmin: boolean, isInternalUser: boolean) {
        if ( (isAdmin && UserFeature.HasAccess(UserFeatureEnum.hasAdminDashboardPage)) || isInternalAdmin) {
            this.props.history.push('/admin');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasClientDashboard) && isInternalUser === false ) { // if external User, client Dashboard is the first navigation
            this.props.history.push('/clientDashboard');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasUserDashboard) ) {
            this.props.history.push('/userDashboard');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboard) ) {
            this.props.history.push('/dashboard');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasIPAttorneyDashboard) ) {
            this.props.history.push('/lawyerDashboard');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasShowFeeEarnerDashboard) ) {
            this.props.history.push('/feeEarnerDashboard');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasMatterSummary) ) {
            this.props.history.push('/matterSummary');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasBulkMatterManagement) ) {
            this.props.history.push('/matterManagement');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasNameSummary) ) {
            this.props.history.push('/clientsAndContacts');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasReferrerSummary) ) {
            this.props.history.push('/referrers');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasStaffSummary) ) {
            this.props.history.push('/staffSummary');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasTimesheetSummary) ) {
            this.props.history.push('/timesheet');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasLeadsDashboard) ) {
            this.props.history.push('/enquiriesDashboard');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasLeadsSummary) ) {
            this.props.history.push('/enquiriesSummary');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasTaskSummary) ) {
            this.props.history.push('/tasksSummary');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasLicencedReports) ) {
            this.props.history.push('/reports');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink) ) {
            this.props.history.push('/innography');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasMailingList) ) {
            this.props.history.push('/mailingList');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasClientSenseLink) ) {
            this.props.history.push('/clientsense');
        } else if (UserFeature.HasAccess(UserFeatureEnum.hasConfigurationMaintenance) ) {
            this.props.history.push('/configurationDashboard');
        }
    }

    private navigateToMatterDetails(matterId: number) {
        client.mutate({
            mutation: AddUserMatterAccessActivity,
            variables: {
                matterId: matterId
            }
        }).then((results: {
            data: {
                addUserMatterAccessActivity: boolean
            }
        }) => {
            if (results.data.addUserMatterAccessActivity === false) {
                // tslint:disable-next-line:no-console
                console.log('AddUserMatterAccessActivity mutation failed');
            } 
        });

        this.props.history.push('/matterdetail/' + matterId);
    }

    private onToggleShowPassword() {
        this.setState({showPassword: !this.state.showPassword});
    }

    private setLoginValuesForChangePassword(response: TokenResponse | OneTimePassword, userId: string) {
        sessionStorage.setItem('tenantId', this.state.tenantId);
        sessionStorage.removeItem('isLoggedIn');
        sessionStorage.removeItem('accessToken');
        UserFeature.Clear();
        this.setUserId(userId);  
    }

    // tslint:disable-next-line:no-any
    private handlePasswordResetSuccess(response: any) {
        sessionStorage.setItem('isLoggedIn', 'true');
        sessionStorage.setItem('accessToken', response.accessToken);
        UserFeature.Init();
        this.setRefreshToken(response.refreshToken);

        this.setLoginValues();      
    }

    private handlePasswordResetFailure(failureMessage: string) {
        this.errorSummary = 'Failed to change password';
        this.errorDescription = failureMessage;

        sessionStorage.removeItem('accessToken');
        UserFeature.Clear();
        sessionStorage.removeItem('tenantId');        
        sessionStorage.removeItem(this.refreshToken);
        sessionStorage.removeItem(this.userIdToken);
        sessionStorage.removeItem('isLoggedIn');
    }

    private clearRefreshToken() {
        this.setState({ autoLogin: false });
        localStorage.removeItem(this.refreshToken);
    }

    private getRefreshToken(): string | null {
        return localStorage.getItem(this.refreshToken);
    }

    private setRefreshToken(data: string) {
        localStorage.setItem(this.refreshToken, data);
    }

    private getUserId(): string | null {
        return localStorage.getItem(this.userIdToken);
    }

    private setUserId(userId: string) {
        localStorage.setItem(this.userIdToken, userId);
    }

    private loginRemember() {
        var refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            this.setState({ autoLogin: false });
            return;
        }
        this.isLoggingIn = true;
        this.forceUpdate();
        var page = this;
        client.mutate({
            mutation: RefreshTokenMutation,
            variables: {
                tenantId: this.state.tenantId,
                refreshToken: refreshToken
            }
        }).then((results: {
            data: {
                refreshToken: TokenResponse
            }
        }) => {
            var response = results.data.refreshToken;
            if (!page.loginSuccess(response, true, this.state.userId)) {
                page.clearRefreshToken();
                page.isLoggingIn = false;
                page.errorLogin = true;
                page.errorSummary = 'Failed to auto login';
                page.errorDescription = 'Invalid Token';
                page.forceUpdate();
            }
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            page.isLoggingIn = false;
            page.errorLogin = true;
            page.clearRefreshToken();
            page.errorSummary = 'Refresh - System error';
            page.errorDescription = reason;
            page.forceUpdate();
        });
    }
}

const LoginMutation = gql`mutation login($tenantId: String!, $userName: String!, $password: String!) {
    login(tenantId: $tenantId, userName: $userName, password: $password) {
      accessToken
      refreshToken
      expiresIn
      statusCode
      isError
      errorDescription
      error
      authenticationMethod
      forceChangePassword
    }
  }`;

const MultifactorAuthenticationMutation = gql`mutation otp($tenantId: String!, $otp: String!, $token: String!) {
    otp(tenantId: $tenantId, otp: $otp, token: $token) {
        accessToken
        refreshToken
        expiresIn
        statusCode
        isError
        errorDescription
        error
        forceChangePassword
    }
  }`;

const SendVerificationCodeMutation = gql`mutation otp($tenantId: String!, $token: String!) {
    resendOtp(tenantId: $tenantId, token: $token) {
        accessToken
        refreshToken
        expiresIn
        statusCode
        isError
        errorDescription
        error
    }
  }`;

const RefreshTokenMutation = gql`mutation refreshToken($tenantId: String!, $refreshToken: String!) {
    refreshToken(tenantId: $tenantId, refreshToken: $refreshToken) {
      accessToken
      refreshToken
      expiresIn
      statusCode
      isError
      errorDescription
      authenticationMethod
      error
    }
  }`;

const UserData = gql`query AppUser {
    appUser {
        userId
        name
        role
        matterAccessCount
        defaultMatterId
        isAdministrator
        isInternalAdmin
        isInternal
    }
}`;

const TenantData = gql`query Tenant {
    tenant {
      sourceSystem
      leapProvisioningStatus      
    }
  }`;

const AddUserMatterAccessActivity = gql`mutation AddUserMatterAccessActivity($matterId: Int) {
    addUserMatterAccessActivity(matterId: $matterId)
}`;

const SplashScreenData = gql`
query splashScreen{
    appUser{
     splashScreen{
       template,
     }
   }
 }`;
  
// export const Login = RVLogin;

// tslint:disable-next-line:no-any
/* export const Login = graphql<any, LoginProps, any>(TenantData, {
    options: ({ match }) => ({ variables: { tenantId: match.params.tenantId } })
})(RvLogin); */
