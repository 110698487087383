import * as React from 'react';
import glamorous from 'glamorous';
import { RvButton } from './Button';
import { RvLabel, LabelStyle } from './Label/Label';
import { RvIcon } from './Icon';
import { RvInput } from './Input';
import { FormEvent } from 'react';
import { client } from '..';
import gql from 'graphql-tag';
import { showNotification } from '../App';
import { graphql } from 'react-apollo';
import { MatterTerminology } from '../MatterTerminology';
import EventSystem from '../EventSystem';
// import PasswordStrengthBar from 'react-password-strength-bar';
import { defaultPasswordLength, PasswordStrengthIndicator } from './PasswordStrengthIndicator';
import { FetchPolicy } from 'apollo-client';
import { CircularProgress } from '@material-ui/core';

// tslint:disable-next-line:no-any
const ChangePassButtons = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    [`& .${`button`}`]: {
        backgroundColor: props.theme.BrandColors.TemplatePrimary,
        color: props.theme.NeutralColors.BackgroundWhite
    }
}));

const LoaddingWrapper = glamorous.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100%'
});

const ChangePasswordWrapper = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 100
});

// tslint:disable-next-line:no-any
const ChangePassHeader = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    flexDirection: 'column',
    color: props.theme.BrandColors.TemplatePrimary,
    alignItems: 'center',
    [`& .${`icon`}`]: {
        fontSize: props.theme.FontSize.REM.size4,
        height: 40
    }
}));

const PasswordStrengthIndicatorWrapper = glamorous(PasswordStrengthIndicator)({
    margin: '0 1rem',
    width: '100%',
    maxWidth: '350px',
});

export interface AppUser {
    id: string;
    name: string;
    role: string;
}

export interface QueryResult {
    appUser: AppUser;
}

export interface PasswordPolicyOptionData {
    loading?: boolean;
    networkStatus?: number;
    options: Options;
}

export interface Options {
    minimumPasswordLength: MinimumPasswordLength;
    minimumPasswordStrength: MinimumPasswordStrength;
}

export interface MinimumPasswordLength {
    numberValue?: number;
}

export interface MinimumPasswordStrength {
    numberValue?: number;
}

interface ChangePasswordProps {
    onClickCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handlePasswordResetSuccess?: (response: TokenResponse) => void;
    handlePasswordResetFailure?: (failureMessage: string) => void;
    isForcedPasswordChange?: boolean;
    tenantId?: string;
    userName: string;
    visibility?: boolean;
    // tslint:disable-next-line:no-any
    pusher?: any;
    data?: QueryResult;
}

interface ChangePasswordState {
    current: string;
    newPassword: string;
    confirm: string;
    visibility: boolean;
    isPasswordChangeOnlogin?: boolean;
    minimumPasswordLength?: number;
    minimumPasswordStrength?: number;
    isLoading: boolean;
    isNewPasswordPolicySatisfied: boolean;
    isConfirmPasswordPolicySatisfied: boolean;
}

interface TokenResponse {
    accessToken: string;
    statusCode: number;
    message: string;
    expiresIn: number;
    refreshToken: string;
    isError: boolean;
}

export class ChangePasswordComponent extends React.Component<ChangePasswordProps, ChangePasswordState> {
    private errorSaving: boolean;   

    // tslint:disable-next-line:no-any
    constructor(props: ChangePasswordProps, context?: any) {
        super(props, context);
        this.state = {
            current: '',
            newPassword: '',
            confirm: '',
            visibility: true,
            isPasswordChangeOnlogin: false,
            minimumPasswordLength: defaultPasswordLength,
            minimumPasswordStrength: 1,
            isNewPasswordPolicySatisfied: false,
            isConfirmPasswordPolicySatisfied: false,
            isLoading: true
        };

        this.onPasswordStrengthChanged = this.onPasswordStrengthChanged.bind(this);
    }

    toggleVisibilityChangePass = () => this.setState((state) => ( { visibility: !state.visibility } ));

    public componentDidMount() {
        this.fetchPasswordPolicy();
    }

    render() {
        let canSave = this.state &&
            this.state.current.trim().length > 0 &&
            this.state.newPassword.trim().length > 0 &&
            this.state.confirm.trim().length > 0 &&
            this.state.isNewPasswordPolicySatisfied &&
            this.state.isConfirmPasswordPolicySatisfied;

        return (
            this.state.isLoading 
                ? (
                    <LoaddingWrapper>
                        <CircularProgress color="primary" size={20} />
                    </LoaddingWrapper>
                )
                : (
                <ChangePasswordWrapper>
                    <ChangePassHeader>
                        <RvIcon iconKey="user" />
                        <RvLabel 
                            label={this.props.data && this.props.data.appUser ? this.props.data.appUser.name : ''} 
                            style={LabelStyle.Label1}
                        />
                        <RvLabel
                            label={MatterTerminology.ChangePassword}
                            style={LabelStyle.Heading3}
                        />
                    </ChangePassHeader>
                    <RvInput
                        hint={MatterTerminology.CurrentPassword}
                        secure={true}
                        value={this.state.current}
                        onChange={this.changeCurrent}
                        error={this.errorSaving}
                    />
                    <RvInput
                        hint={MatterTerminology.NewPassword}
                        secure={true}
                        value={this.state.newPassword}
                        onChange={this.changeNewPassword}
                        error={this.errorSaving}
                    />
                    <PasswordStrengthIndicatorWrapper 
                        name={'isNewPasswordPolicySatisfied'}
                        value={this.state.newPassword}
                        minLenght={this.state.minimumPasswordLength}
                        minStrength={this.state.minimumPasswordStrength}
                        onPasswordChange={this.onPasswordStrengthChanged}
                    />
                    <RvInput
                        hint={MatterTerminology.ConfirmPassword}
                        secure={true}
                        value={this.state.confirm}
                        onChange={this.changeConfirm}
                        error={this.errorSaving}
                    />
                    <PasswordStrengthIndicatorWrapper 
                        name={'isConfirmPasswordPolicySatisfied'}
                        value={this.state.confirm}
                        minLenght={this.state.minimumPasswordLength}
                        minStrength={this.state.minimumPasswordStrength}
                        onPasswordChange={this.onPasswordStrengthChanged}
                    />
                    <ChangePassButtons>
                        <RvButton
                            label={MatterTerminology.Save}
                            onClick={this.handleClick}
                            disabled={!canSave}
                        />
                    </ChangePassButtons>
                </ChangePasswordWrapper>
            )
        );
    }

    private fetchPasswordPolicy()  {        
        fetchPasswordPolicyData(false,
                                (data: PasswordPolicyOptionData) => {
                                    this.handlePasswordPolicyFetch(data);
                                }, 
                                (reason) => {
                                        showNotification('Failed to Fetch Password Policy', reason, 'error'); 
                                       
                                        this.setState({
                                            isLoading: false
                                        });
                                });
        
    }

    private handlePasswordPolicyFetch(data: PasswordPolicyOptionData) {
        if (data && !data.loading && data.options) {
            let minimumPasswordLength = data.options.minimumPasswordLength 
                ? data.options.minimumPasswordLength.numberValue
                : undefined;

            let minimumPasswordStrength = data.options.minimumPasswordStrength 
                ? data.options.minimumPasswordStrength.numberValue
                : 1; // 1 - Checks only for the Password Length

            this.setState({
                minimumPasswordLength: minimumPasswordLength,
                minimumPasswordStrength: minimumPasswordStrength,
                isLoading: false
            });
        }
    }

    private changeCurrent = (event: FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ current: target.value });
    }

    private changeNewPassword = (event: FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ newPassword: target.value });
    }

    private changeConfirm = (event: FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ confirm: target.value });
    }

    private changePassSuccess = (response: TokenResponse): boolean => {
        if (response === null) {
            return false;
        } else if (response.statusCode === 0) {
            return true;
        }
        return false;
    }

    private onPasswordStrengthChanged(hasUpperCase: boolean, hasLowerCase: boolean, hasNumber: boolean, 
                                      hasSpecial: boolean, hasLength: boolean, strengths: number, name: string) {
        const state = this.state;

        if (this.state.minimumPasswordStrength) {
            /* 
                Minimum Password Strength will always be 1. 
                We should always check hasLength to make sure the min length is satisfied. This is the min Strength
            */

            if (strengths >= this.state.minimumPasswordStrength && hasLength) {                
                state[name] = true;
            } else {
                state[name] = false;
            }

            this.setState({
                isNewPasswordPolicySatisfied: state.isNewPasswordPolicySatisfied,
                isConfirmPasswordPolicySatisfied: state.isConfirmPasswordPolicySatisfied
            });
        } else {
            // This will never happen.
            this.setState({
                isNewPasswordPolicySatisfied: true,
                isConfirmPasswordPolicySatisfied: true
            });
        }
    }

    private handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (this.checkPasswordLength()) {
            if (this.checkPasswordMatch()) {
                if (this.props.isForcedPasswordChange) {
                    this.changeForcePassword();
                } else {
                    this.change();
                }
                
            } else {
                this.errorSaving = true;
                showNotification(null, 'Password does not match the confirm password.', 'error');
            }
        } else {
            showNotification(null, 'Password must contain ' + {defaultPasswordLength} + ' or more characters', 'error');
        }
    }

    private change() {
        this.forceUpdate();
        client.mutate({
            mutation: ChangePaswordMutation,
            variables: {
                oldPassword: this.state.current,
                newPassword: this.state.confirm
            }
        }).then((results: {
            data: {
                changePassword: TokenResponse
            }
        }) => {
            var response = results.data.changePassword;
            if (!this.changePassSuccess(response)) {
                this.errorSaving = true;
                showNotification('Change password failed', response.message, 'error');
                this.forceUpdate();

                if (this.props.handlePasswordResetFailure) {
                    this.props.handlePasswordResetFailure(response.message);
                }
            }
            if (this.changePassSuccess(response)) {
                this.errorSaving = false;
                showNotification(null, 'Successfully Changed', 'info');
                this.toggleVisibilityChangePass();
                this.blankPassword();
                this.forceUpdate();
                EventSystem.emit('changepassword:true');

                if (this.props.handlePasswordResetSuccess) { 
                    this.props.handlePasswordResetSuccess(response); 
                }
            }
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            this.errorSaving = true;
            showNotification(null, reason, 'error');
            this.forceUpdate();
        });
    }

    private changeForcePassword() {
        // this.forceUpdate();
        client.mutate({
            mutation: ChangePaswordMutationForForcedPasswordChange,
            variables: {
                oldPassword: this.state.current,
                newPassword: this.state.confirm,
                isForcedPasswordChange: this.props.isForcedPasswordChange,
                userName: this.props.userName,
                tenantIdForForcedPasswordChange: this.props.tenantId
            }
        }).then((results: {
            data: {
                changePassword: TokenResponse
            }
        }) => {
            var response = results.data.changePassword;
            if (!this.changePassSuccess(response)) {
                this.errorSaving = true;
                showNotification('Change password failed', response.message, 'error');

                if (this.props.handlePasswordResetFailure) {
                    this.props.handlePasswordResetFailure(response.message);
                }
            }
            if (this.changePassSuccess(response)) {
                this.errorSaving = false;
                showNotification(null, 'Successfully Changed', 'info');
                this.toggleVisibilityChangePass();
                this.blankPassword();
                EventSystem.emit('changepassword:true');

                if (this.props.handlePasswordResetSuccess) { 
                    this.props.handlePasswordResetSuccess(response); 
                }
            }
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            this.errorSaving = true;
            showNotification(null, reason, 'error');
        });
    }

    private blankPassword = () => {
        this.setState({
            current: '',
            newPassword: '',
            confirm: ''
        });
    }

    private checkPasswordMatch() {
        if (this.state.confirm === this.state.newPassword) {
            return true;
        }
        return false;
    }

    private checkPasswordLength() {
        let minPasswordLength = this.state.minimumPasswordLength ? this.state.minimumPasswordLength : defaultPasswordLength;
        if (this.state.newPassword.length >= minPasswordLength) {
            return true;
        }
        return false;
    }
}

const ChangePaswordMutation = 
gql`mutation ResetPassword(
        $oldPassword: String, 
        $newPassword: String
    ) {
    changePassword(
        oldPassword: $oldPassword, 
        newPassword: $newPassword
        ) {
            statusCode
            message
    }
}`;

const ChangePaswordMutationForForcedPasswordChange = 
gql`mutation ResetPassword(
        $oldPassword: String, 
        $newPassword: String,
        $isForcedPasswordChange: Boolean,
        $userName: String,
        $tenantIdForForcedPasswordChange: String
    ) {
    changePassword(
        oldPassword: $oldPassword, 
        newPassword: $newPassword,
        isForcedPasswordChange: $isForcedPasswordChange,
        userName: $userName,
        tenantIdForForcedPasswordChange: $tenantIdForForcedPasswordChange
        ) {
            statusCode
            message
            accessToken
            expiresIn,
            isError,
            refreshToken
    }
}`;

const UserData = gql`
query UserData {
    appUser {
      name
      role
    }
}`;

export function fetchPasswordPolicyData(refreshData: boolean,                                        
                                        onSuccess: (data?: PasswordPolicyOptionData) => void,
                                        // tslint:disable-next-line:no-any
                                        onError: (reason: any) => void,
                                        tenantId?: string)
: void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: PasswordPolicyData,
        variables: {
            tenantId: tenantId
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const PasswordPolicyData = gql`
query PasswordPolicyQuery($tenantId: String) {
    options{
        minimumPasswordLength(tenantId: $tenantId){
            numberValue
        }
        minimumPasswordStrength(tenantId: $tenantId){
            numberValue
        }
    }
}`;

// tslint:disable-next-line:no-any
export const ChangePassword = graphql<any, ChangePasswordProps, any>
  (UserData, {
      options: { variables: {} }
})
(ChangePasswordComponent);