import { fetchEventSource } from '@microsoft/fetch-event-source';
import axios from 'axios';

type AIRequest = {
  prompt: string;
}

const api_key = 'sk-proj-IHuhlcI1qz4f5amX18SXT3BlbkFJH0rQ1j2W7R71MiykDBKb';
    
export const ai_request = (request: AIRequest, respondWith: any) => {
    
    console.log('Request:', request);

    const systemMessage = [
      { role: 'system', content: 'Remove lines with ``` from the response start and response end.' }
    ];

    const messages = [
      ...systemMessage,
      { role: 'user', content: request.prompt }
    ];

    const openAiOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${api_key}`,
        'gpt-4o': 'org-lO8P5GN457AhwHGnDd5rIhbp',
        'Project': 'proj_3Ce7YpULhDWBCtut6WCnaMjj'
      },
      body: JSON.stringify({
        model: 'gpt-4o',
        temperature: 0.7,
        max_tokens: 4000,
        messages,
      })
    };
    
    respondWith.string((signal: any) => window.fetch('https://api.openai.com/v1/chat/completions', { signal, ...openAiOptions })
      .then(async (response) => {
        if (response) {
          const data = await response.json();
          if (data.error) {
            throw new Error(`${data.error.type}: ${data.error.message}`);
          } else if (response.ok) {
            // Extract the response content from the data returned by the API
            return data?.choices[0]?.message?.content?.trim();
          }
        } else {
          throw new Error('Failed to communicate with the ChatGPT API');
        }
      })
    );
};


// This example stores the API key in the client side integration. This is not recommended for any purpose.
// Instead, an alternate method for retrieving the API key should be used.
// const api_key = '<INSERT_API_KEY_HERE>';

export const streamingAIRequest = (request: any, respondWith: any) => {
  respondWith.stream((signal: any, streamMessage: any) => {
    // Adds each previous query and response as individual messages
    const conversation = request.thread.flatMap((event: any) => {
      if (event.response) {
        return [
          { role: 'user', content: event.request.query },
          { role: 'assistant', content: event.response.data }
        ];
      } else {
        return [];
      }
    });

    // System messages provided by the plugin to format the output as HTML content.
    const pluginSystemMessages = request.system.map((content: any) => ({
      role: 'system',
      content
    }));

    const systemMessages = [
      ...pluginSystemMessages,
      // Additional system messages to control the output of the AI
      { role: 'system', content: 'Remove lines with ``` from the response start and response end.' }
    ]

    // Forms the new query sent to the API
    const content = request.context.length === 0 || conversation.length > 0
      ? request.query
      : `Question: ${request.query} Context: """${request.context}"""`;

    const messages = [
      ...conversation,
      ...systemMessages,
      { role: 'user', content }
    ];

    const requestBody = {
      model: 'gpt-4o',
      temperature: 0.7,
      max_tokens: 800,
      messages,
      stream: true
    };

    const openAiOptions = {
      signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${api_key}`,
        'gpt-4o': 'org-lO8P5GN457AhwHGnDd5rIhbp',
        'Project': 'proj_3Ce7YpULhDWBCtut6WCnaMjj'
      },
      body: JSON.stringify(requestBody)
    };

    const onopen = async (response: any) => {
      if (response) {
        const contentType = response.headers.get('content-type');
        if (response.ok && contentType?.includes('text/event-stream')) {
          return;
        } else if (contentType?.includes('application/json')) {
          const data = await response.json();
          if (data.error) {
            throw new Error(`${data.error.type}: ${data.error.message}`);
          }
        }
      } else {
        throw new Error('Failed to communicate with the ChatGPT API');
      }
    };

    // This function passes each new message into the plugin via the `streamMessage` callback.
    const onmessage = (ev: any) => {
      const data = ev.data;
      if (data !== '[DONE]') {
        const parsedData = JSON.parse(data);
        const firstChoice = parsedData?.choices[0];
        const message = firstChoice?.delta?.content;
        if (message) {
          streamMessage(message);
        }
      }
    };

    const onerror = (error: any) => {
      // Stop operation and do not retry by the fetch-event-source
      throw error;
    };

    // Use microsoft's fetch-event-source library to work around the 2000 character limit
    // of the browser `EventSource` API, which requires query strings

    return fetchEventSource('https://api.openai.com/v1/chat/completions', {
      ...openAiOptions,
      openWhenHidden: true,
      onopen,
      onmessage,
      onerror
    });

  });
};

type ChatGptResponseData = {
  id:                 string;
  object:             string;
  created:            number;
  model:              string;
  choices:            Choice[];
  usage:              Usage;
  system_fingerprint: string;
};

type Choice = {
  index:         number;
  message:       Message;
  logprobs:      null;
  finish_reason: string;
};

type Message = {
  role:    string;
  content: string;
  refusal: null;
};

type Usage = {
  prompt_tokens:     number;
  completion_tokens: number;
  total_tokens:      number;
};

type ChatGptResponseParamsMessage = {
  role: 'system' | 'user';
  content: string;
};

type ChatGptResponseParams = {
  messages: ChatGptResponseParamsMessage[];
};

export async function getResponseFromApi(params: ChatGptResponseParams) {
    try {
      const result = await axios.post<ChatGptResponseData>(
        'https://api.openai.com/v1/chat/completions',
        {
            model: 'gpt-4o',
            temperature: 0.7,
            max_tokens: 4000,
            messages: params.messages
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer sk-proj-IHuhlcI1qz4f5amX18SXT3BlbkFJH0rQ1j2W7R71MiykDBKb`,
                'gpt-4o': 'org-lO8P5GN457AhwHGnDd5rIhbp',
                'Project': 'proj_3Ce7YpULhDWBCtut6WCnaMjj',
            },
        }
    );

    return result.data;

  } catch (error) {
      console.error(error);
  }
  
  // Add a return statement here
  return null;
  
}