import * as React from 'react';
import { Loader } from 'semantic-ui-react';

interface LoaderProps {
    label?: string;
    size?: 'large' | 'medium' | 'small' | 'big' | 'huge' | 'massive';
}

export class RvLoader extends React.Component<LoaderProps, {}> {

    public render() {

        return (
            <div>
                <Loader 
                    active={true}
                    inline="centered"
                    size={this.props.size ? this.props.size : 'small'}
                    content={this.props.label}
                />
            </div>
        );
    }
}
