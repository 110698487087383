import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import * as React from 'react';
import Wrapper from '../components/Wrapper';

const styles = (theme: Theme) => createStyles({
    frame: {
        border: 'none', 
        bottom: '0px',
        height: '100%', 
        left: '0px', 
        margin: 0, 
        padding: 0,
        position: 'relative', 
        right: '0px', 
        top: '0px', 
        width: '100%', 
    },
});

interface UrlViewerProps extends WithStyles<typeof styles> {
    className?: string;
    match: {
        params: {
            url: string
        }
    };
    // tslint:disable-next-line:no-any
    controlAboveFrame?: React.ReactElement<any>;
}

class UrlViewer extends React.Component<UrlViewerProps, {}> {
    // tslint:disable-next-line:no-any
    constructor(props: UrlViewerProps, context?: any) {
        super(props);
     }

    public render() {
         // tslint:disable-next-line:no-console
        console.log('@props:', this.props);
        const { url } = this.props.match.params;
        const { classes } = this.props; 
        // tslint:disable-next-line:no-console
        console.log('@rpt:', this.props.match.params.url);
        return (
            <Wrapper>
                {this.props.controlAboveFrame}
                <iframe 
                    id="urlViewer"
                    src={decodeURIComponent(url)}
                    className={classes.frame}                 
                />
            </Wrapper>
        );
    }

}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(UrlViewer as any) as any;