import * as React from 'react';
import { Alignment } from './Alignment';
import { DataDisplayProps } from './IDataDisplayProps';
import { IntlProvider, FormattedDate, FormattedRelativeTime } from 'react-intl';
import '../../App.css';
import { MomentConfig } from './DateTimeDisplay';
import { selectUnit } from '@formatjs/intl-utils';
// import { Unit } from '@formatjs/intl-relativetimeformat';

export enum DateTimeDisplayStyle {
    Default = 0,            // same as ShortDate
    ShortDate = 1,          //  'dd mmm yyyy'
    LongDate = 2,           //  'Monday, June 15, 2009'
    ShortTime = 3,          //  '1:45 PM'
    LongTime = 4,           //  '1:45:30 PM'
    ShortDateTime = 5,      //  'dd mmm yyyy 1:45 PM''
    LongDateTime = 6,       //  'Monday, June 15, 2009 1:45:30 PM'
    Custom = 7
}

export interface FormattedDateTimeProps extends DataDisplayProps {
    value?: Date;
    displayStyle?: DateTimeDisplayStyle;
    format?: string;           // an optional date format string
    labelAlignment?: Alignment;
    valueAlignment?: Alignment;
    config?: MomentConfig;
    DisplayInUTC?: boolean;
}

function convertUTCDateToLocalDate(date: Date, displayInUTC: boolean) {
    var convertedLocalTime = new Date(date);
    if (displayInUTC) {
        var hourOffset = convertedLocalTime.getTimezoneOffset() / 60;
        convertedLocalTime.setHours( convertedLocalTime.getHours() + hourOffset ); 
    }
    return convertedLocalTime;
}

export class FormattedDateTime extends React.Component<FormattedDateTimeProps, {}> {
    // tslint:disable-next-line:no-any
    format: any;
    displayInUTC: boolean;
    // tslint:disable-next-line:no-any
    constructor(props: FormattedDateTimeProps, context?: any) {
        super(props, context);
        let longDateFormat = { weekday: 'long', year: 'numeric', day: '2-digit', month: 'long' };
        let shortDateFormat = { year: 'numeric', day: 'numeric', month: 'short' };
        let shortTimeFormat = { hour: 'numeric', minute: 'numeric', hour12: true };
        let longTimeFormat = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };

        switch (this.props.displayStyle) {
            case DateTimeDisplayStyle.LongDate:
                this.format = { ...longDateFormat };
                break;
            case DateTimeDisplayStyle.ShortTime:
                this.format = { ...shortTimeFormat };
                break;
            case DateTimeDisplayStyle.LongTime:
                this.format = { ...longTimeFormat };
                break;
            case DateTimeDisplayStyle.ShortDateTime:
                this.format = { ...shortDateFormat, ...shortTimeFormat };
                break;
            case DateTimeDisplayStyle.LongDateTime:
                this.format = { ...longDateFormat, ...longTimeFormat };
                break;
            case undefined:
            default:
                this.format = { ...shortDateFormat };
        }
                
        this.displayInUTC = this.props.DisplayInUTC === undefined ? true : this.props.DisplayInUTC;
    }
         
    public render() {
        var date;
        var displayDate = null;
        var timeSinceDate;  
        
        if (this.props.value && this.props.config && this.props.config > MomentConfig.ShowOnlyDate) {
            // handle rv.DateTimes (which have .dt: Date)
            if (this.props.value) {
                if (this.props.value.hasOwnProperty('dt')) {
                    displayDate = convertUTCDateToLocalDate(
                        // tslint:disable-next-line:no-any
                        new Date(Date.parse((this.props.value as any).dt)), this.displayInUTC).getTime();
                } else if (this.props.value instanceof Date) {
                    // tslint:disable-next-line:max-line-length
                    displayDate = convertUTCDateToLocalDate(new Date(Date.parse(this.props.value.toString())), this.displayInUTC).getTime();
                }
            }
            
            const {value, unit} = selectUnit(displayDate === null ? 0 :  displayDate - 48 * 3600 * 1000);

            timeSinceDate = (
                <IntlProvider
                    locale="en-AU"
                    formats={{}}
                    defaultLocale="en"
                >
                         {
                            (displayDate) ? 
                                <span>(
                                        <FormattedRelativeTime 
                                            value={value}
                                            unit={unit}
                                        />
                                    )
                                    </span>
                                :
                                <div />
                        }
                </IntlProvider>
            );
        }

        if (!this.props.config || this.props.config < MomentConfig.ShowMomentOnly) {
            date = (
                <span>
                    <FormattedDate
                        // tslint:disable-next-line:max-line-length
                        value={this.props.value ? convertUTCDateToLocalDate(new Date(this.props.value), this.displayInUTC) : undefined}
                        {...this.format}
                    />
                </span>
            );
        }
        return this.props.value
            ? (
                <div>
                    <IntlProvider
                        locale="en-AU"
                        formats={{}}
                        defaultLocale="en"
                    >
                        {date}
                    </IntlProvider>
                    &nbsp;
                    {timeSinceDate}
                </div>
            )
            : <div />;

    }
}
