import * as React from 'react';
import { IntlProvider, FormattedNumber } from 'react-intl';

export interface FormattedPercentageProps {
    value?: number;
}

export class FormattedPercentage extends React.Component<FormattedPercentageProps, {}>  {
    // tslint:disable-next-line:no-any
    constructor(props: FormattedPercentageProps, context?: any) {
        super(props, context);
    }

    render() {
        return this.props.value !== undefined ? (
            <IntlProvider locale="en">
                <FormattedNumber
                    style="percent" // this is commented due to flooring values
                    value={this.props.value / 100} // We are doing this due to flooring the values in backend.
                     // refer currency string here https://www.currency-iso.org/dam/downloads/lists/list_one.xml
                />
            </IntlProvider>
        )
        : <div/>;
    }
}