import * as React from 'react';
import { Alignment } from './Alignment';
import { DataDisplayProps } from './IDataDisplayProps';
import { RvLabel, LabelStyle } from '../Label/Label';
import '../../App.css';
import { DateTimeDisplayStyle, FormattedDateTime } from './FormattedDateTime';
import { Grid } from 'semantic-ui-react';
import glamorous from 'glamorous';

const LabelData = glamorous(Grid.Column)({
    textAlign: 'left'
});

const ValueData = glamorous(Grid.Column)({
    textAlign: 'left',
    alignItems: 'center',
});

const DateTimeDisplayWrapper = glamorous(Grid)({
    display: 'flex',
    flexFlow: 'column',
});

export interface DateTimeDisplayProps extends DataDisplayProps {
    value?: Date;
    displayStyle?: DateTimeDisplayStyle;
    format?: string;           // an optional date format string
    labelAlignment?: Alignment;
    valueAlignment?: Alignment;
    style?: LabelStyle;
    config?: MomentConfig;
    DisplayInUTC?: boolean;
}

export enum MomentConfig {
    ShowOnlyDate,
    ShowDateWithMoment,
    ShowMomentOnly
}

export class RvDateTimeDisplay extends React.Component<DateTimeDisplayProps, {}> {
    hiddenOnEmpty: boolean;
    isHidden: boolean;
    styleList: Array<string> = [' ', ' ', 'decimal', ' '];

    // tslint:disable-next-line:no-any
    constructor(props: DateTimeDisplayProps, context?: any) {
        super(props, context);
        // default to true if not set
        this.hiddenOnEmpty = (this.props.hiddenOnEmpty == null ? true : this.props.hiddenOnEmpty);
    }

    public render() {
        this.isHidden = this.props.value == null && this.hiddenOnEmpty;
        if (this.isHidden) {
            return null;
        }

        return (
            <DateTimeDisplayWrapper columns={2} className="date-time-display">                   
                <LabelData className="label" width={this.props.LabelDataWidth}>
                    <RvLabel
                        label={this.props.label}
                        style={this.props.style ? this.props.style : LabelStyle.Default}
                    />
                </LabelData>                              
                <ValueData className="value" style={{display: 'flex'}} width={this.props.ValueDataWidth}>
                    <FormattedDateTime
                        value={this.props.value}
                        displayStyle={this.props.displayStyle}
                        config={this.props.config}
                        DisplayInUTC={this.props.DisplayInUTC}
                    />
                </ValueData>
            </DateTimeDisplayWrapper>
        );
    }
}
