import * as React from 'react';
import { RvLabel, LabelStyle } from '../Label/Label';
import '../../App.css';
import { Grid } from 'semantic-ui-react';
import { DataDisplayProps } from './IDataDisplayProps';
import { CurrencyDisplayStyle, FormattedCurrency } from './FormattedCurrency';
import glamorous from 'glamorous';

const LabelData = glamorous(Grid.Column) ({
    textAlign: 'left'
});

const ValueData = glamorous(Grid.Column) ({
    textAlign: 'left',
    alignItems: 'center'
});

const TabbedDisplay = `TabbedDisplay`;
const CurrencyWrapper = glamorous.div ({
    [`& .${TabbedDisplay}`] : {
        paddingLeft: '25px !important'
    }
});

interface DataDisplay {
    isHidden: boolean;
}

export interface CurrencyDisplayProps extends DataDisplayProps {
    value?: number;
    displayStyle?: CurrencyDisplayStyle;
    format?: string;            // an optional currency format string
    style?: LabelStyle;
    tabbed?: boolean;
}

export class CurrencyDisplay extends React.Component<CurrencyDisplayProps, {}> implements DataDisplay {
    hiddenOnEmpty: boolean;
    isHidden: boolean;
    styleList: Array<string> = [' ', ' ', 'decimal', ' '];

    // tslint:disable-next-line:no-any
    constructor(props: CurrencyDisplayProps, context?: any) {
        super(props, context);
        // default to true if not set
        this.hiddenOnEmpty = (this.props.hiddenOnEmpty == null ? true : this.props.hiddenOnEmpty);
    }

    public render() {
        this.isHidden = this.props.value == null && this.hiddenOnEmpty;
        if (this.isHidden)    {
        return null;
        }

        return (
        <CurrencyWrapper>
            <Grid columns={2}>
                <LabelData 
                    className={this.props.tabbed ? 'TabbedDisplay' : 'LabelData'} 
                    width={this.props.LabelDataWidth}
                >
                    <RvLabel 
                        label={this.props.label} 
                        style={this.props.style ? this.props.style : LabelStyle.Default}
                    />
                </LabelData>
                <ValueData 
                    style={{display: 'flex'}} 
                    className="ValueData"
                    width={this.props.ValueDataWidth}
                >
                    <FormattedCurrency value={this.props.value} displayStyle={this.props.displayStyle}/>
                </ValueData>
            </Grid>
        </CurrencyWrapper>
    );
    }
}
