/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    strengthContainer: {
        display: 'flex',
        '& .bar': {
            border: '1px solid rgb(221, 221, 221)',
            borderRadius: '5px',
            flex: 1,
            margin: '0 2px',
        }
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '12px',
        paddingTop: 5
    },
    suggestion: {

    },
    strength: {

    }
});

export enum StrengthEnum {
    ZERO = 0,
    ONE,
    TWO,
    THREE,
    FOUR,
    FIVE
}
interface PasswordStrengthIndicatorProps {
    name: string;
    value: string;
    minLenght?: number;
    minStrength?: number;
    className?: string;
    onPasswordChange?: (
        hasUpperCase: boolean,
        hasLowerCase: boolean,
        hasNumber: boolean,
        hasSpecial: boolean,
        hasLength: boolean,
        strengths: number, // value: 0-5
        name: string
    ) => void;
}

interface PasswordStrengthIndicatorState {
    strengths: StrengthEnum;
}

export const defaultPasswordLength = 7;

export const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ( props ) => {

    const classes = useStyles();

    const [state, setState] = useState<PasswordStrengthIndicatorState>({
        strengths: 0,
    });

    const checkNumber = (value: string) => {
        return new RegExp(/[0-9]/).test(value);
    };

    const checkUppercase = (value: string) => {
        return new RegExp(/[A-Z]/).test(value);
    };

    const checkLowerCase = (value: string) => {
        return new RegExp(/[a-z]/).test(value);
    };

    const checkSpecial = (value: string) => {
        return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
    };

    useEffect(() => {
        
        setState((prevState) => {

            let strengths = 0;
            let hasUpperCase = false;
            let hasLowerCase = false;
            let hasNumber = false;
            let hasSpecial = false;
            let hasLength = false;

            if (props.value.length >= (props.minLenght ? props.minLenght : defaultPasswordLength)) { 
                strengths++;
                hasLength = true;
            }
            if (checkUppercase(props.value)) {
                strengths++;
                hasUpperCase = true;
            }
            if (checkLowerCase(props.value)) {
                strengths++;
                hasLowerCase = true;
            }
            if (checkNumber(props.value)) {
                strengths++;
                hasNumber = true;
            }
            if (checkSpecial(props.value)) {
                strengths++;
                hasSpecial = true;
            }

            if (props.onPasswordChange) {
                props.onPasswordChange(
                    hasUpperCase,
                    hasLowerCase,
                    hasNumber,
                    hasSpecial,
                    hasLength,
                    strengths,
                    props.name
                );
            }

            return {
                ...prevState,
                strengths,
            };
        });

    // tslint:disable-next-line: align
    }, [props.value]);

    const defaultColor = 'rgb(221, 221, 221)';

    const strengthColor = (count: StrengthEnum) => {

        if (count === StrengthEnum.ZERO) {
            return defaultColor;
        }
        
        if (count === StrengthEnum.ONE) {
            return 'red';
        }

        if (count === StrengthEnum.TWO) {
            return 'yellow';
        }

        if (count === StrengthEnum.THREE) {
            return 'orange';
        }

        if (count === StrengthEnum.FOUR) {
            return 'lightgreen';
        }

        if (count === StrengthEnum.FIVE) {
            return 'green';
        }
        
        return defaultColor;
    };

    const getSuggestion = (value: string) => {

        let suggestion = undefined;

        if (value.length < (props.minLenght ? props.minLenght : defaultPasswordLength)) {
            suggestion = `Minimum required length is ${props.minLenght ? props.minLenght : defaultPasswordLength}`; 
        } else if (!checkUppercase(value) && (props.minStrength && props.minStrength >= 2)) {
            suggestion = `At least 1 capital letter`;
        } else if (!checkLowerCase(value) && (props.minStrength && props.minStrength >= 3)) {
            suggestion = `At least 1 small letter`;
        } else if (!checkSpecial(value) && (props.minStrength && props.minStrength >= 4)) {
            suggestion = `At least 1 special character`;
        } else if (!checkNumber(value) && (props.minStrength && props.minStrength >= 5)) {
            suggestion = `At least 1 number`;
        }

        return suggestion;
    };

    const getWordStrength = () => {
        
        if (state.strengths === StrengthEnum.ONE) {
            return 'Basic';
        } else if (state.strengths === StrengthEnum.TWO) {
            return 'Good';
        } else if (state.strengths === StrengthEnum.THREE) {
            return 'Strong';
        } else if (state.strengths === StrengthEnum.FOUR) {
            return 'Stronger';
        } else if (state.strengths === StrengthEnum.FIVE) {
            return 'Very Strong';
        }
        return undefined;
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            <div className={classes.strengthContainer}>
                <span 
                    className="bar" 
                    style={{ borderColor: state.strengths >= StrengthEnum.ONE ? strengthColor(state.strengths) : defaultColor}} 
                />
                <span 
                    className="bar" 
                    style={{ borderColor: state.strengths >= StrengthEnum.TWO ? strengthColor(state.strengths) : defaultColor}} 
                />
                <span 
                    className="bar" 
                    style={{ borderColor: state.strengths >= StrengthEnum.THREE ? strengthColor(state.strengths) : defaultColor}} 
                />
                <span 
                    className="bar" 
                    style={{ borderColor: state.strengths >= StrengthEnum.FOUR ? strengthColor(state.strengths) : defaultColor}} 
                />
                <span 
                    className="bar" 
                    style={{ borderColor: state.strengths >= StrengthEnum.FIVE ? strengthColor(state.strengths) : defaultColor}} 
                />
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.suggestion}>
                    {props.value.length > 0 && getSuggestion(props.value)}
                </div>
                <div className={classes.strength}>
                    {getWordStrength()}
                </div>
            </div>
        </div>
        
    );
};