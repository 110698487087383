import * as React from 'react';
import { RvLabel, LabelStyle } from './Label/Label';
import glamorous from 'glamorous';
import { MatterTerminology } from '../MatterTerminology';
import { RvInput } from './Input';
import { RvButton } from './Button';
import { RvPanel } from './Panel';
import { FormEvent } from 'react';
import gql from 'graphql-tag';
import { client } from '..';
import { showNotification } from '../App';

// tslint:disable-next-line:no-any
const InputErrorMessage = glamorous.div<{ theme?: any }>((props) => ({
    color: '#f44336',
    textAlign: 'left',
    paddingLeft: '15px',
}));

// tslint:disable-next-line:no-any
const PanelContent = glamorous.div<{ theme?: any }>((props) => ({
    textAlign: 'center',
    paddingTop: '40px'
}));

// tslint:disable-next-line:no-any
const PanelBorder = glamorous.div<{ theme?: any }>((props) => ({
    boxShadow: '0 0 14px 0 rgba(81,115,255,0.09)',
    borderRadius: '8px',
    maxWidth: '412px',
    margin: '46px auto 0 auto',
}));

// tslint:disable-next-line:no-any
const LabelContainer = glamorous.div<{ theme?: any }>((props) => ({
    padding: '46px 51px 0 51px',
    color:  props.theme.NeutralColors.Dark,
}));

// tslint:disable-next-line:no-any
const NewPasswordLabelContainer = glamorous.div<{ theme?: any }>((props) => ({
    padding: '0px 51px 36px 51px',
    color:  props.theme.NeutralColors.Dark,
}));

// tslint:disable-next-line:no-any
const InputContainer = glamorous.div<{ theme?: any }>((props) => ({
    padding: '20px 26px 20px 26px'
}));

// tslint:disable-next-line:no-any
const ButtonContianer = glamorous.div<{ theme?: any }>((props) => ({
    padding: '33px 0',
    borderTop: '1px solid ' + props.theme.NeutralColors.LightBlue1,
    [`& .${`button`}`]: {
        color: props.theme.NeutralColors.BackgroundWhite,
        backgroundColor: props.theme.BrandColors.TemplatePrimary,
        padding: '17px 43px',
        textTransform: 'uppercase'
    }
}));

interface ForgotPasswordState {
    showPanel: boolean;
    error: boolean;
    userID?: string;
}

interface ForgotPasswordProps {
    userID: string;
    tenantId: string;
}

export class ForgottenPassword extends React.Component<ForgotPasswordProps, ForgotPasswordState> {
    constructor(props: ForgotPasswordProps, state: ForgotPasswordState) {
        super(props, state);
        this.onChange = this.onChange.bind(this);
        this.RequestPassword = this.RequestPassword.bind(this);
        this.state = {
          showPanel: false,
          error: false,
        };
    }
      
    toggleVisibilityForgottenPassword = () => {
        this.setState((state) => ( { showPanel: !state.showPanel } ));
    }

    public render() {
        return (
            <RvPanel
                label={MatterTerminology.ForgottenPassword}
                isOpen={this.state.showPanel}
                onOpen={this.toggleVisibilityForgottenPassword}
            >
                <PanelContent>
                    <RvLabel style={LabelStyle.Heading1} label={MatterTerminology.RequestNewPassword} />
                    <PanelBorder>
                                <div>
                                    <LabelContainer>
                                        <RvLabel 
                                            // tslint:disable-next-line:max-line-length
                                            label="Please enter your username in the field below then click 'request new password' to reset and request a new password" 
                                        />
                                    </LabelContainer>
                                    <InputContainer>
                                            <RvInput
                                                label={MatterTerminology.Username}
                                                iconKey="user"
                                                iconPosition="left"
                                                onChange={this.onChange}
                                                hint={MatterTerminology.Username}
                                                value={this.state.userID === undefined ? this.props.userID : this.state.userID}
                                                type="email"
                                                
                                            />
                                            {this.state.error && this.state.userID && <InputErrorMessage>Enter a valid email address.</InputErrorMessage>}
                                    </InputContainer>
                                    
                                </div>
                                <ButtonContianer>
                                    <RvButton 
                                        label={MatterTerminology.RequestNewPassword} 
                                        onClick={this.RequestPassword}
                                        disabled={this.state.error || (this.state.userID === undefined ? this.props.userID === '' ? true : false : false)}
                                    />
                                </ButtonContianer>
                                <NewPasswordLabelContainer>
                                    <RvLabel 
                                            // tslint:disable-next-line:max-line-length
                                        label="If you a licensed user of the software you will be emailed instructions to reset your password. If this is not received the next 5-10 minutes, please check your junk mail and spam filters." 
                                    />
                                </NewPasswordLabelContainer>
                    </PanelBorder>
                </PanelContent>
            </RvPanel>
        );
    }

    private onChange(event: FormEvent<HTMLInputElement>) {
        var target = event.target as HTMLInputElement;

        this.setState({
            userID: target.value,
            // tslint:disable-next-line:max-line-length
            error: !/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(target.value),
            showPanel: true
        });
    }

    private RequestPassword() {
        if (this.state.error) {
            return;
        }

        let userID = this.state.userID ? this.state.userID : this.props.userID;
        
        client.mutate({
            mutation: PasswordMutation,
            variables: {
                email: userID,
                tenantId: this.props.tenantId
            }
        // tslint:disable-next-line:no-any
        }).then((results: any) => {
            if (results!.data!.requestNewPassword) {
                showNotification(null, 'If you are a registered user an email will be sent with your new password'
                               , 'info');
                this.setState({showPanel: false});
            } else {
                showNotification(null, 'Something goes wrong. check your username', 'error');
            }
        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            showNotification(null, reason, 'error');
        });
    }
}

const PasswordMutation = gql`mutation ResetPassword($email: String!, $tenantId: String!) {
    requestNewPassword(email: $email, tenantId: $tenantId)
}`;
