import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
    wrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '0 15px',
        position: 'relative',
    },
});

interface WrapperProps extends WithStyles<typeof styles> {
    className?: string;
}

class Wrapper extends React.Component<WrapperProps, {}> {
    // tslint:disable-next-line:no-any
    constructor(props: WrapperProps, context?: any) {
        super(props);
    }

    public render() {
        const { classes } = this.props;
        return (
           <React.Fragment>
                <div className={classes.wrapper + ' ' + this.props.className}>
                    {this.props.children}
                </div>
           </React.Fragment>
        );
    }
}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(Wrapper as any) as any;