import * as React from 'react';
import { Modal, Button } from 'semantic-ui-react';

interface ModalProps {
    trigger?: JSX.Element;
    buttonName?: string;
    content?: JSX.Element;
    actionButtons?: JSX.Element;
    header?: string;
    open?: boolean;
    size?: 'fullscreen' | 'large' | 'mini' | 'small' | 'tiny';
    centered?: boolean;
}

export class RvModal extends React.Component<ModalProps, {}> {

    public render() {
        return (
            <Modal
                open={this.props.open} 
                size={this.props.size}
                trigger={this.props.buttonName ? <Button>{this.props.buttonName}</Button> : undefined}
                centered={this.props.centered !== undefined ? this.props.centered : false}
            >
                <Modal.Header>{this.props.header}</Modal.Header>
                <Modal.Content>
                    {this.props.content}
                </Modal.Content>
                <Modal.Actions>
                    {this.props.actionButtons}
                </Modal.Actions>
            </Modal>
        );
    }
}
