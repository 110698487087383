import * as React from 'react';
import { LabelStyle } from './LabelStyle';
import glamorous from 'glamorous';

// tslint:disable-next-line:no-any
const Label = glamorous.label<{theme?: any}> ((props) => ({
  '&&': {
    fontWeight: props.theme.FontWeight.Normal,
  }
}));

interface LabelProps {
  label?: string;
  hint?: string;
  style?: LabelStyle;
  color?: string;
  cssStyle?: {};
  // tslint:disable-next-line:no-any
  onClick?: any;
  className?: string;
}

export class RvLabel extends React.Component<LabelProps, {}> {

  remList: Array<string> =
    ['0.75rem', '14px', '1.1rem', '1.25rem', '1.5rem', '1.875rem', '2.0rem', '1.5rem', '1.2rem', '36px'];

  render() {

    let styleIdx = (this.props.style ? this.props.style : LabelStyle.Default) - 1;
    let labelStyle: {} = { fontSize: this.remList[styleIdx], ...(this.props.cssStyle || []) };
    if (this.props.color) {
      labelStyle = { color: this.props.color, ...(labelStyle || []) };
    }

    return (
      <Label
        className={this.props.className ? this.props.className : 'rvlabel'}
        style={labelStyle}
        title={this.props.hint}
        onClick={this.props.onClick}
      >
        {this.props.label}
      </Label>
    );
  }
}
export * from './LabelStyle';