import * as React from 'react';
// import glamorous from 'glamorous';
import { MatterTerminology } from '../../MatterTerminology';
import { linkToPage } from '../../App';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { RvLoader } from '../Loader';
import { RvMainDrawer, LinkProps } from '../MainDrawer';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TranslationTextList } from '../../locales/common';

// tslint:disable-next-line:no-any
/* const StyledAppMenu = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0 !important',
    [`& .${`button`}`]: {
      color: props.theme.BrandColors.TemplatePrimary,
      backgroundColor: props.theme.NeutralColors.BackgroundWhite,
      fontSize: props.theme.FontSize.EM.size1,
      padding: '12.54px 12.25px 13.67px 13px',
      textAlign: 'center',
      height: '48.19px',
      width: '48px',
      border: '1px solid' + props.theme.NeutralColors.LightBlue1,
      borderRadius: '4px',
      boxShadow: '0 0 4px 0 ' + props.theme.NeutralColors.BackgroundGrey2,
      marginRight: '10px'
    }
  }));
   */
export enum TasksMenu {
    TASKS = 0,
    PHONE_CALLS,
    HIDDEN
}

const initialState = {
    show: false
};
type AppMenuState = Readonly<typeof initialState>;
export interface AppMenuProps extends WithTranslation {
    show: boolean;
}
interface QueryResult {
    loading?: boolean;
    networkStatus?: number;
    error?: ApolloError;
    appUser: AppUser;
}

export interface AppUser {
    isAdministrator: boolean;
    isInternalAdmin: boolean;
    canViewDashboard: boolean;
}

export interface QAppMenuProps extends AppMenuProps {
    id?: string;
    data?: QueryResult;
    handleSidebarHide?: (value: boolean) => void;
}

class AppMenuDrawer extends React.Component<QAppMenuProps, AppMenuState> {
    // readonly state: AppMenuState = initialState;

    constructor(props: AppMenuProps, state: AppMenuState) {
        super(props, state);
        // this.onMenuSelection = this.onMenuSelection.bind(this);
        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.handleSidebarHide = this.handleSidebarHide.bind(this);
        this.toggleTaskAndPhone = this.toggleTaskAndPhone.bind(this);
        this.state = { show: this.props.show };
    }

    UNSAFE_componentWillReceiveProps(
        nextProps: AppMenuProps,
        nextContext: AppMenuState
    ): void {
        this.setState({ show: nextProps.show });
    }

    render() {

        const { t } = this.props as {
            t: (key: keyof TranslationTextList) => string;
        };

        var drawerLinks: Array<LinkProps>;
        if (this.state.show) {
            if (
                !this.props.data ||
                !this.props.data.appUser ||
                this.props.data.loading
            ) {
                return <RvLoader size="small" />;
            }
            // tslint:disable-next-line: no-console
            console.log(this.props.data);
            // at the moment only admins can view dashboard
            this.props.data.appUser = {
                canViewDashboard: this.props.data.appUser
                    ? this.props.data.appUser.isAdministrator
                    : false,
                isAdministrator: this.props.data.appUser
                    ? this.props.data.appUser.isAdministrator
                    : false,
                isInternalAdmin: this.props.data.appUser
                    ? this.props.data.appUser.isInternalAdmin
                    : false    
            };
            drawerLinks = [
                // { label: MatterTerminology.Home,
                //     iconKey: 'home',
                //     linkTo: linkToPage('home'),
                //     visible: this.props.data.appUser ? this.props.data.appUser.isAdministrator : false,
                //     // onClick: this.toggleVisibility.bind(this)
                // },
                {
                    label: MatterTerminology.ClientDashboard,
                    iconKey: 'vcard',
                    linkTo: linkToPage('clientDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasClientDashboard) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.UserDashboard,
                    iconKey: 'object group outline',
                    linkTo: linkToPage('userDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasUserDashboard)
                },
                {
                    label: MatterTerminology.FirmDashboard,
                    iconKey: 'chart line',
                    linkTo: linkToPage('dashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasFirmDashboard) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.FeeEarnerDashboard,
                    iconKey: 'chart area',
                    linkTo: linkToPage('feeEarnerDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasShowFeeEarnerDashboard) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.AttorneyDashboard,
                    iconKey: 'chart bar outline',
                    linkTo: linkToPage('lawyerDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasIPAttorneyDashboard)
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.MattersSummary,
                    iconKey: 'list layout',
                    linkTo: linkToPage('matterSummary'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasMatterSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.MatterManagement,
                    iconKey: 'tasks',
                    linkTo: linkToPage('matterManagement'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasBulkMatterManagement) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.ClientsAndContacts,
                    iconKey: 'users',
                    linkTo: linkToPage('clientsAndContacts'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasNameSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.ClientContactNotes,
                    iconKey: 'sticky note',
                    linkTo: linkToPage('notesSummary'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasNotesSummary)
                },
                {
                    label: MatterTerminology.Referrers,
                    iconKey: 'handshake outline',
                    linkTo: linkToPage('referrers'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasReferrerSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.EventsAndCampaigns,
                    iconKey: 'calendar alternate outline',
                    linkTo: linkToPage('eventsAndCampaigns'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasEventsAndCampaignsSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.Staff,
                    iconKey: 'user outline',
                    linkTo: linkToPage('staffSummary'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasStaffSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.Timesheet,
                    iconKey: 'hourglass outline',
                    linkTo: linkToPage('timesheet'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasTimesheetSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: t('EnquiriesDashboard'),
                    iconKey: 'pie graph',
                    linkTo: linkToPage('enquiriesDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasLeadsDashboard) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: t('Enquiries'),
                    iconKey: 'id badge outline',
                    linkTo: linkToPage('enquiriesSummary'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasLeadsSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.MailingList,
                    iconKey: 'mail outline',
                    linkTo: linkToPage('mailingList'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasMailingList) 
                },
                {
                    label: MatterTerminology.TaskDashboard,
                    iconKey: 'chart bar',
                    linkTo: linkToPage('taskDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasTaskDashboard) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    // tslint:disable-next-line: max-line-length
                    label: this.toggleTaskAndPhone() === TasksMenu.TASKS ? MatterTerminology.Tasks : this.toggleTaskAndPhone() === TasksMenu.PHONE_CALLS ? MatterTerminology.PhoneCalls : MatterTerminology.Tasks,
                    // tslint:disable-next-line: max-line-length
                    iconKey: this.toggleTaskAndPhone() === TasksMenu.TASKS ? 'ordered list' : this.toggleTaskAndPhone() === TasksMenu.PHONE_CALLS ? 'phone' : 'ordered list',
                    linkTo: linkToPage('tasksSummary'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasTaskSummary) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.Reports,
                    iconKey: 'file alternate outline',
                    linkTo: linkToPage('reports'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasLicencedReports) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.Innography,
                    iconKey: 'external',
                    linkTo: linkToPage('innography'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink) 
                },
                {
                    label: MatterTerminology.ClientSense,
                    iconKey: 'external',
                    linkTo: linkToPage('clientsense'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasClientSenseLink) 
                },
                {
                    label: MatterTerminology.Admin,
                    iconKey: 'settings',
                    linkTo: linkToPage('admin'),
                    visible: (UserFeature.HasAccess(UserFeatureEnum.hasAdminDashboardPage) || this.props.data.appUser.isInternalAdmin)
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.Configuration,
                    iconKey: 'configure',
                    linkTo: linkToPage('configurationDashboard'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasConfigurationMaintenance) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.GeneralMaterial,
                    iconKey: 'briefcase',
                    linkTo: linkToPage('generalMaterial'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasGeneralMaterial) 
                    // onClick: this.toggleVisibility.bind(this)
                },
                {
                    label: MatterTerminology.ClientDocuments,
                    iconKey: 'file text',
                    linkTo: linkToPage('clientDocuments'),
                    visible: UserFeature.HasAccess(UserFeatureEnum.hasClientDocuments) 
                    // onClick: this.toggleVisibility.bind(this)
                }
            ];
        } else {
            drawerLinks = new Array<LinkProps>();
        }
        return (
            /*                <main className="Site-content">
                          <Router>
        */

            <RvMainDrawer
                animation="overlay"
                visible={this.state.show}
                links={drawerLinks}
                handleSidebarHide={this.handleSidebarHide}
            >
                {this.props.children}
            </RvMainDrawer>
            /*                    </Router>
                      </main>
       */
        );
    }

    // private onMenuSelection() {
    //     this.toggleVisibility();
    // }

    private toggleVisibility() {
        this.setState(state => ({ show: !state.show }));
    }

    private handleSidebarHide(value: boolean) {
        if (this.props.handleSidebarHide) {
            this.props.handleSidebarHide(value);
        }
    }
    private toggleTaskAndPhone() {
        if (
            // UserFeature.HasAccess(UserFeatureEnum.hasMatterTasks) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasArchiveMatter) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasBillMatter) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasCloseMatter) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasDeleteTask) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonActing) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonResponsible) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasFollowUpMatterTask) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasWriteOffDebtors) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasWriteOffWIP) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasTransferMatterTrust) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasTaskDetail) ||
            UserFeature.HasAccess(UserFeatureEnum.hasAdHocTask)
        ) {
            return TasksMenu.TASKS;
        } else if (
            UserFeature.HasAccess(UserFeatureEnum.hasPhoneTask)
        ) {
            return TasksMenu.PHONE_CALLS;
        } else {
            return TasksMenu.HIDDEN;
        }
    }
}

const UserData = gql`
  query UserData {
    appUser {
      isAdministrator
      isInternalAdmin
    }
  }
`;

// tslint:disable-next-line:no-any
const AppMenu = graphql<any, AppMenuProps, any>(UserData, {
    options: { variables: {} /*fetchPolicy: 'cache-only'*/ }
})(AppMenuDrawer);

export default withTranslation()(AppMenu);
