import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
// import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import { CircularProgress, IconButton, InputAdornment, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { omit } from 'lodash';
import clsx from 'clsx';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            width: '100%',
        },
        popover: {
            padding: theme.spacing(2),
        },
        treeView: {
            width: '100%',
            maxHeight: 400,
            overflowY: 'auto',
        },
        textBold: {
            fontWeight: 600,
        },
        progress: {
            margin: '0 10px',
        },
        treeItemContent: {
            padding: '5px 10px',
        },
    }),
);

export interface TreeSelectNode {
    label: string;
    value: string;
    children?: TreeSelectNode[];
    // tslint:disable-next-line: no-any
    [key: string]: any;
}

interface TreeSelectProps extends BaseTextFieldProps {
    options: TreeSelectNode[];
    value?: TreeSelectNode | null;
    onSelection: (value: TreeSelectNode | null) => void;
    loading?: boolean;
    expandAll?: boolean;
    enabledSearch?: boolean;
}

const TreeSelect: React.FC<TreeSelectProps> = ({ onSelection, ...props }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const classes = useStyles();
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchQuery, setSearchQuery] = useState<string>(props.value ? props.value.label : '');
    const [isSearch, setIsSearch] = useState<boolean>(false);

    useEffect(() => {
        setSearchQuery(props.value ? props.value.label : ''); },
              [props.value]);

    // tslint:disable-next-line: no-any
    const handleTreeClick = (selectedItem: TreeSelectNode, popupState: any) => (event: React.MouseEvent<HTMLLIElement>) => {
        setIsSearch(false);
        onSelection(selectedItem);
        setSearchQuery(selectedItem.label);
        popupState.close();
    };

    const onClear = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSearch(false);
        setSearchQuery('');
        onSelection(null);
    };

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSearch(true);
        setSearchQuery(event.target.value);
    };

    // get all the nodes value and put it in a string array
    const flattenNodes = (nodes: TreeSelectNode[]): string[] => {
        const stack: TreeSelectNode[] = [...nodes];
        const result: string[] = [];
        while (stack.length) {
            const node = stack.pop();
            if (node) {
                result.push(node.value);
                if (node.children) {
                    stack.push(...node.children);
                }
            }
        }
        return result;
    };

    // search from all nodes and return what is ask including the parent object
    const searchOptions = (searchString: string, options: TreeSelectNode[]): TreeSelectNode[] => {
        const filteredOptions: TreeSelectNode[] = [];

        options.forEach((option) => {
            if (searchString === '' || option.label.toLowerCase().includes(searchString.toLowerCase()) || option.value.toLowerCase().includes(searchString.toLowerCase())) {
                if (!filteredOptions.some((item) => item.value === option.value)) {
                    filteredOptions.push(option);
                }
            }

            if (option.children) {
                const matchingChildren = searchOptions(searchString, option.children);
                if (matchingChildren.length > 0) {
                    const existingIndex = filteredOptions.findIndex((item) => item.value === option.value);
                    if (existingIndex !== -1) {
                        filteredOptions[existingIndex] = {
                            ...option,
                            children: matchingChildren,
                        };
                    } else {
                        filteredOptions.push({
                            ...option,
                            children: matchingChildren,
                        });
                    }
                }
            }
        });

        return filteredOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else if (a.label > b.label) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    // render all the tree
    // tslint:disable-next-line: no-any
    const renderTree = (node: TreeSelectNode, popupState: any) => {
        if (node.children && node.children.length > 0) {
            // If the node has children, recursively render the children
            return (
                <TreeItem
                    key={node.value}
                    nodeId={node.value}
                    label={node.label}
                    classes={{
                        content: classes.treeItemContent,
                    }}
                >
                    {node.children.map((child) => renderTree(child, popupState))}
                </TreeItem>
            );
        } else {
            // If the node has no children, render the node
            return (
                <TreeItem
                    key={node.value}
                    nodeId={node.value}
                    label={<Typography className={node.value === props.value?.value ? classes.textBold : undefined}>{node.label}</Typography>}
                    // icon={node.value === props.value?.value ? <InsertDriveFileIcon /> : <InsertDriveFileOutlinedIcon />}
                    onClick={handleTreeClick(node, popupState)}
                    classes={{
                        content: classes.treeItemContent,
                    }}
                />
            );
        }
    };

    const textFieldRef = inputRef.current?.getBoundingClientRect();

    // style the popover and assigned width coming from the textfield
    const CustomPopover = withStyles((theme: Theme) => ({
        root: {
            '& .MuiPaper-root': {
                minWidth: textFieldRef ? textFieldRef.width : 'auto',
                padding: '10px',
            },
        },
    }))(Popover);

    const allNodes = flattenNodes(props.options);

    return (
        <PopupState variant="popover" popupId="demo-popup-popover" disableAutoFocus={true}>
            {(popupState) => (
                <>
                    <TextField
                        {...omit(props, ['onSelection'])}
                        {...omit(bindTrigger(popupState), ['onClick'])}
                        onClick={props.disabled ? undefined : bindTrigger(popupState).onClick}
                        className={clsx(classes.textField, props.className)}
                        onBlur={undefined}
                        value={searchQuery}
                        // onClick={handleClick}
                        ref={inputRef}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {props.loading ? <CircularProgress size={16} className={classes.progress} /> : undefined}
                                    {props.value ? (
                                        <IconButton edge="end" size="small" onClick={onClear} className="closeIconButton">
                                            <CloseOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    ) : undefined}
                                    <IconButton edge="end" size="small" disabled={props.disabled}>
                                        {popupState.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            readOnly: props.enabledSearch ? false : true
                        }}
                        onChange={onSearch}
                        helperText={popupState.isOpen ? undefined : props.helperText}
                    />
                    <CustomPopover
                        {...bindPopover(popupState)}
                        onBlur={undefined} // prevent react-final-form to only trigger on the 2nd time
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        style={{
                            width: textFieldRef?.width,
                            // top: popoverTop,
                        }}
                        disableScrollLock={true}
                    >
                        <TreeView
                            className={classes.treeView}
                            defaultExpanded={props.expandAll ? allNodes : undefined}
                            defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
                            defaultExpandIcon={<AddBoxOutlinedIcon />}
                        >
                            {searchOptions(isSearch ? searchQuery : '', props.options).map((option) => renderTree(option, popupState))}
                        </TreeView>
                    </CustomPopover>
                </>
            )}
        </PopupState>
    );
};

export default TreeSelect;
