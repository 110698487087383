import React, { useState } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../typings/autoComplete';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//         minWidth: '150px',
//     },
//   })
// );

interface ReminderSelectorProps {
    disabled?: boolean;
    required?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    showIcon?: boolean;
    size?: 'small' | 'medium';
    isNoZeroMinutes?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    classes?: Partial<ClassNameMap<string>>;
}

interface ReminderSelectorState {
    isLoading: boolean;
    data: IAutoCompleteItem[];
}

export const ReminderSelector: React.FC<ReminderSelectorProps> = props => {

    // const classes = useStyles();

    const [state] = useState<ReminderSelectorState>({
        isLoading: false,
        data: props.isNoZeroMinutes ?
        [
            { label: 'None', value: 'None' },
            { label: '5 minutes', value: '5 minutes' },
            { label: '10 minutes', value: '10 minutes' },
            { label: '15 minutes', value: '15 minutes' },
            { label: '30 minutes',  value: '30 minutes' },
            { label: '1 hour', value: '1 hour' },
            { label: '2 hours', value: '2 hours' },
            { label: '3 hours', value: '3 hours' },
            { label: '4 hours', value: '4 hours' },
            { label: '5 hours', value: '5 hours' },
            { label: '6 hours', value: '6 hours' },
            { label: '7 hours', value: '7 hours' },
            { label: '8 hours', value: '8 hours' },
            { label: '9 hours', value: '9 hours' },
            { label: '10 hours', value: '10 hours' },
            { label: '11 hours', value: '11 hours' },
            { label: '0.5 days', value: '0.5 days' },
            { label: '18 hours', value: '18 hours' },
            { label: '1 day', value: '1 day' },
            { label: '2 days', value: '2 days' },
            { label: '3 days', value: '3 days' },
            { label: '4 days', value: '4 days' },
            { label: '1 week', value: '1 week' },
            { label: '2 weeks', value: '2 weeks' },
        ] : [
            { label: 'None', value: 'None' },
            { label: '0 minutes', value: '0 minutes' },
            { label: '5 minutes', value: '5 minutes' },
            { label: '10 minutes', value: '10 minutes' },
            { label: '15 minutes', value: '15 minutes' },
            { label: '30 minutes',  value: '30 minutes' },
            { label: '1 hour', value: '1 hour' },
            { label: '2 hours', value: '2 hours' },
            { label: '3 hours', value: '3 hours' },
            { label: '4 hours', value: '4 hours' },
            { label: '5 hours', value: '5 hours' },
            { label: '6 hours', value: '6 hours' },
            { label: '7 hours', value: '7 hours' },
            { label: '8 hours', value: '8 hours' },
            { label: '9 hours', value: '9 hours' },
            { label: '10 hours', value: '10 hours' },
            { label: '11 hours', value: '11 hours' },
            { label: '0.5 days', value: '0.5 days' },
            { label: '18 hours', value: '18 hours' },
            { label: '1 day', value: '1 day' },
            { label: '2 days', value: '2 days' },
            { label: '3 days', value: '3 days' },
            { label: '4 days', value: '4 days' },
            { label: '1 week', value: '1 week' },
            { label: '2 weeks', value: '2 weeks' },
        ]
    });

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    // tslint:disable-next-line: no-any
    const handleOnClose = (event: React.ChangeEvent<{}>) => {
        // tslint:disable-next-line: no-console
        console.log('handleOnClose', event);

        // tslint:disable-next-line: no-string-literal
        if (event.currentTarget && event.currentTarget['value']) {
            // tslint:disable-next-line
            console.log('handleOnClose Value', event.currentTarget['value']);

            // tslint:disable-next-line: no-string-literal
            let newValue =  getMatchedValue(event.currentTarget['value']);
            if (props.onSelection) {
                // tslint:disable-next-line: no-string-literal
                let tempValue = { value: newValue, label: newValue };
                props.onSelection(tempValue, props.name);
            }
        }
    };

    const getMatchedValue = (value: string) => {
        // Split number and string
        var numberSplit = value.match(/\d+/g);
        var stringSplit =  value.match(/[a-zA-Z]+/g);

        // tslint:disable-next-line: no-any
        let numberValue: any = 0;
        if (numberSplit && numberSplit.length > 1) {
            let numberString = '';
                
            let numOne = numberSplit[0].toString().length > 3 ? numberSplit[0].substring(0, 3).toString() : numberSplit[0];
            let numTwo = numberSplit[1].toString().length > 2 ? numberSplit[1].substring(0, 2).toString() : numberSplit[1];
            numberString = numOne + '.' + numTwo;                

            numberValue = parseFloat(numberString);
            if (numberValue === 'NaN') {
                numberValue = 0;
            }
        } else if (numberSplit && numberSplit?.length > 0) {
            let numOne = numberSplit[0].toString().length > 3 ? numberSplit[0].substring(0, 3).toString() : numberSplit[0];
            numberValue = numOne;
        } else {
            numberValue = 0;
        }

        if (stringSplit && stringSplit.length > 0 && stringSplit[0]) {
            let stringValue = stringSplit[0].toLowerCase();
            if (stringValue) {
                if (stringValue.match(/^(m|min|mins|minute|minutes)$/)) {
                    if (numberValue === '1') {
                        return numberValue + ' minute';
                    }
                    return numberValue + ' minutes';
                } else if (stringValue.match(/^(h|ho|hr|hrs|hou|hour|hours)$/)) {
                    if (numberValue === '1') {
                        return numberValue + ' hour';
                    }
                    return numberValue + ' hours';
                } else if (stringValue.match(/^(d|da|dy|day|days)$/)) {
                    if (numberValue === '1') {
                        return numberValue + ' day';
                    }
                    return numberValue + ' days';
                } else if (stringValue.match(/^(w|we|wk|wee|week|weeks)$/)) {
                    if (numberValue === '1') {
                        return numberValue + ' week';
                    }
                    return numberValue + ' weeks';
                } else {
                    return numberValue + ' minutes';
                }
            } else {
                if (numberValue > 60 || (numberValue && numberValue.toString().length > 5)) {
                    return 36 + ' minutes';
                } else {
                    return numberValue + ' minutes';
                }
            }
        } else {   
            if (numberValue > 60 || (numberValue && numberValue.toString().length > 5)) {
                return 36 + ' minutes';
            } else {
                return numberValue + ' minutes';
            }            
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
            startAdornment: props.showIcon ? (
                <InputAdornment position="start">
                    <NotificationsNoneOutlinedIcon fontSize="small" />
                </InputAdornment>
            ) : undefined,
         });
    };

    return (
        <>  
            <Autocomplete
                style={props.style}
                className={props.className}
                classes={props.classes}
                value={props.value}
                loading={state.isLoading}
                disabled={props.disabled}
                onChange={handleChange}
                onClose={handleOnClose}
                options={state.data}
                disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                // tslint:disable-next-line: jsx-no-lambda
                getOptionLabel={(option: IAutoCompleteItem) => option.label}      
                getOptionSelected={(option, value) => value.value === option.value}      
                onBlur={props.onBlur}     
                // tslint:disable-next-line: jsx-no-lambda
                renderInput={params => (
                    <TextField 
                        {...params} 
                        label={props.label}
                        variant="standard" 
                        fullWidth={true} 
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isLoading)}
                        error={props.error}    
                        helperText={props.helperText}     
                        size={props.size}            
                    />
                )}
                // tslint:disable-next-line: jsx-no-lambda
                renderOption={(option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);
            
                    return (
                        <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                            </span>
                        ))}
                        </div>
                    );
                }}
            />
        </>
    );
};