import * as React from 'react';
import { IntlProvider, FormattedNumber } from 'react-intl';
import { RvLoader } from '../Loader';

export enum CurrencyDisplayStyle {
    Default = 0,            // same as Integer     (with default currency symbol)
    Integer = 1,            // zero decimal places (with default currency symbol)
    Decimal = 2,           //  2 decimal places    (with default currency symbol)
    Custom = 3
}

export interface FormattedCurrencyProps {
    value?: number;
    displayStyle?: CurrencyDisplayStyle;
    format?: string;            // an optional currency format string
    isLoading?: boolean;
}

export class FormattedCurrency extends React.Component<FormattedCurrencyProps, {}>  {
    styleList: Array<string> = [' ', ' ', 'decimal', ' '];

    // tslint:disable-next-line:no-any
    constructor(props: FormattedCurrencyProps, context?: any) {
        super(props, context);
    }

    render() {
        // todo: this should be handled by styling separately but is ok for now - we also need to use correct fonts etc
        // Following is commented after Package upgrades. This is not supported. 
        // Have to find a new way to implement this, if the existing function is broken
        // let numberStyle = {
        //     style: this.props.displayStyle 
        //             ? this.styleList[this.props.displayStyle] &&  'currency' 
        //             : this.styleList[CurrencyDisplayStyle.Default] && 'currency'
        // };

        let twoDecimals = {
            minimumFractionDigits: this.props.displayStyle === CurrencyDisplayStyle.Decimal ? 2 : 0,
            maximumFractionDigits: this.props.displayStyle === CurrencyDisplayStyle.Integer ? 0 : 2,
        };

        if (this.props.isLoading && this.props.isLoading === true) {
            return <RvLoader size="small" />;
        }
        return this.props.value !== undefined ? (
            <IntlProvider locale="en">
                <FormattedNumber
                    value={this.props.value}
                    // {...numberStyle}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    {...twoDecimals} 
                    currency={this.props.format ? this.props.format : 'USD'} 
                    // format={this.props.format}
                    currencyDisplay="narrowSymbol"
                    // refer currency string here https://www.currency-iso.org/dam/downloads/lists/list_one.xml
                    // {...numberStyle}
                    // {...twoDecimals} 
                />
            </IntlProvider>
        )
            : <div/>;

    }
}
