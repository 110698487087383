import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField, Tooltip } from '@material-ui/core';

// tslint:disable-next-line
export const CurrencyInput = (props: any) => {
    return (
        <NumberFormat
            customInput={TextField}
            className={props.className}
            thousandSeparator={true}
            fixedDecimalScale={props.fixedDecimalScale}
            decimalScale={2}
            allowNegative={false}             
            prefix={props.prefix}
            suffix={props.suffix}
            onBlur={props.input.onBlur}
            onFocus={props.input.onFocus}
            label={props.label}
            disabled={props.disabled}
            helperText={props.helperText}
            value={props.input.value}
            InputProps={props.InputProps}
            InputLabelProps={props.InputLabelProps}
            size={props.size}
            classes={props.classes}
            // onChange={value => props.input.onChange(value)}
            // onValueChange={({ formattedValue }) =>
            //     props.input.onChange(formattedValue)}
            onValueChange={(values) => 
                props.input.onChange(values.floatValue)
            }
            isAllowed={ values => {
                if (props.maxValue !== undefined) {
                    const { formattedValue, floatValue } = values;
                    if (floatValue == null) {
                        return formattedValue === '';
                    } else {
                        return (floatValue <= props.maxValue);
                    }
                } else {
                    return true;
                }
            }}
        />
    );
};

// tslint:disable-next-line
export const CurrencyInput2 = (props: any) => {

    const meta = props.meta;

    return (
        <NumberFormat
            customInput={TextField}
            className={props.className}
            thousandSeparator={true}
            fixedDecimalScale={props.fixedDecimalScale}
            decimalScale={props.decimalScale ? props.decimalScale : 2}
            allowNegative={false}             
            prefix={props.prefix}
            suffix={props.suffix}
            onBlur={props.input.onBlur}
            onFocus={props.input.onFocus}
            label={props.label}
            disabled={props.disabled}
            value={props.input.value}
            InputProps={props.InputProps}
            error={meta.error && meta.touched}
            InputLabelProps={props.InputLabelProps}
            size={props.size}
            classes={props.classes}
            helperText={
                meta.error &&
                meta.touched
                    ? meta.error
                    : props.helperText ? props.helperText : ''
            }
            // onChange={value => props.input.onChange(value)}
            // onValueChange={({ formattedValue }) =>
            //     props.input.onChange(formattedValue)}
            onValueChange={(values) => {
                props.input.onChange(values.floatValue);
                if (props.onChangeValue) {
                    props.onChangeValue(values.floatValue);
                }
            }}
            isAllowed={ values => {
                    if (props.maxValue !== undefined) {
                        const { formattedValue, floatValue } = values;
                        if (floatValue == null) {
                            return formattedValue === '';
                        } else {
                            return (floatValue <= props.maxValue);
                        }
                    } else {
                        return true;
                    }
            }}
        />
    );
};

// tslint:disable-next-line
export const CurrencyInputWithTooltip = (props: any) => {

    const meta = props.meta;

    const title = props.title;

    return (
        <Tooltip hidden={title === undefined} title={title}>
            <NumberFormat
                customInput={TextField}
                className={props.className}
                thousandSeparator={true}
                fixedDecimalScale={props.fixedDecimalScale}
                decimalScale={props.decimalScale ? props.decimalScale : 2}
                allowNegative={false}             
                prefix={props.prefix}
                suffix={props.suffix}
                onBlur={props.input.onBlur}
                onFocus={props.input.onFocus}
                label={props.label}
                disabled={props.disabled}
                value={props.input.value}
                InputProps={props.InputProps}
                error={meta.error && meta.touched}
                InputLabelProps={props.InputLabelProps}
                size={props.size}
                classes={props.classes}
                helperText={
                    meta.error &&
                    meta.touched
                        ? meta.error
                        : props.helperText ? props.helperText : ''
                }
                // onChange={value => props.input.onChange(value)}
                // onValueChange={({ formattedValue }) =>
                //     props.input.onChange(formattedValue)}
                onValueChange={(values) => {
                    props.input.onChange(values.floatValue);
                    if (props.onChangeValue) {
                        props.onChangeValue(values.floatValue);
                    }
                }}
                isAllowed={ values => {
                        if (props.maxValue !== undefined) {
                            const { formattedValue, floatValue } = values;
                            if (floatValue == null) {
                                return formattedValue === '';
                            } else {
                                return (floatValue <= props.maxValue);
                            }
                        } else {
                            return true;
                        }
                }}
            />
        </Tooltip>
    );
};