import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

// tslint:disable-next-line: no-any
export function limitTime(val: any, max: any) {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }
    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '00';
        // this can happen when user paste number
        } else if (val > max) {
            val = max;
        }
    }
    return val;
}

// tslint:disable-next-line: no-any
export function timeFormat(val: any) {
    let hour = limitTime(val.substring(0, 2), '99');
    let minute = limitTime(val.substring(2, 4), '59');

    if (minute === '') {
        minute = '000';
    }
    return hour + (hour.length ? ':' + minute : '');
}

export function TimeNumberFormatCustom(prop: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = prop;
    
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: prop.name,
                        value: values.value,
                    },
                });
            }}
            format={timeFormat}
        />
    );
}