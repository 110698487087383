import { ColDef, Column, IAggFunc, ValueGetterParams } from '@ag-grid-community/core';
import moment from 'moment';
// import moment from 'moment';
import { MatterTaskAction } from '../../matterSummary/cellRenderer/MatterTaskAction';
import { BoolRenderer, CurrencyRenderer, CurrencyRendererWithoutDecimal, DateRenderer, DateTimeRenderer, 
    DurationRenderer, 
    ImageRenderer, NumberRenderer, PercentageRenderer } from '../../matterSummary/MatterSummaryCellRenderer';
import { exposure, percentageEstimate } from '../../matterSummary/MatterSummaryColumns';
import {
    monthToDateBillableHours,
    monthToDateBudgetBilledVariance,
    monthToDateBudgetChargeableHours,
    monthToDateBudgetCollectionVariance,
    monthToDateBudgetNonChargeableHours,
    monthToDateBudgetProducedVariance,
    monthToDateNonBillableHours,
    weekToDateBillableHours,
    weekToDateBudgetBilledVariance,
    weekToDateBudgetChargeableHours,
    weekToDateBudgetCollectionVariance,
    weekToDateBudgetNonChargeableHours,
    weekToDateBudgetProducedVariance,
    weekToDateNonBillableHours,
    yearToDateBillableHours,
    yearToDateBudgetBilledVariance,
    yearToDateBudgetChargeableHours,
    yearToDateBudgetCollectionVariance,
    yearToDateBudgetNonChargeableHours,
    yearToDateBudgetProducedVariance,
    yearToDateNonBillableHours,
    monthToDateRealisation,
    yearToDateRealisation,
    fullFinancialYearBillableHoursBgt,
    fullFinancialYearNonBillableHoursBgt,
    yearToDateCollection,
    monthToDateCollection,
    previousMonthYearToDateBudgetBilledVariance,
    previousMonthYearToDateBudgetCollectionVariance,
    previousMonthYearToDateBudgetProducedVariance,
    previousMonthYearToDateBillableHours,
    previousMonthYearToDateNonBillableHours,
    previousMonthYearToDateBudgetChargeableHours,
    previousMonthYearToDateBudgetNonChargeableHours,
    previousMonthToMonthToDateBudgetBilledVariance,
    previousMonthToMonthToDateBudgetCollectionVariance,
    previousMonthToMonthToDateBudgetProducedVariance,
    previousMonthToMonthToDateBillableHours,
    previousMonthToMonthToDateNonBillableHours,
    previousMonthToMonthToDateBudgetChargeableHours,
    previousMonthToMonthToDateBudgetNonChargeableHours,
} from '../../staffBillingSummary/StaffBillingSummaryColumns';
import { StaffTaskAction } from '../../staffSummary/StaffTaskAction';
import { DataType } from '../DataDisplay/ValueFormatter';

var menuTabs = ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'];
var nonFilterMenuTabs = ['generalMenuTab', 'columnsMenuTab'];

export interface ColumnProperty extends ColDef {
    canSearch?: boolean;
    cardDisplay?: boolean; // Do not display on summary card or is already displayed
    format: number;
    // tslint:disable-next-line: no-any
    valueGetter?: string | ((params: ValueGetterParams) => any);
    isHideCustom?: boolean; // To display / hide column if it is not an admin user
}

export interface SummaryViewColumnConfiguration {
    field: string;
    headerName: string;
    width: number;
    dataType: number;
    show: boolean;
    isLocked: boolean;
    sort: string;
    aggFunc?: string | IAggFunc;
    enableValue?: boolean;
    valueGetter?: string;
    // tslint:disable-next-line: no-any
    cellClass?: any;
}

export function filterParamsOptions() {
    return {
        filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
        // use inRangeInclusive: true for the range filter to include the selected 
        // from and to dates. Setting it false would fetch only the inbetween dates
        inRangeInclusive: true,  
        // tslint:disable-next-line: no-any
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var dateAsString = moment(cellValue).format('DD/MM/YYYY');
            var dateParts = dateAsString.split('/');
            var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            } else if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return -1;
            }
            
        }
    };
}

// converts our json view configuration to the coulumn layout used by ag-grid
export function ConvertViewConfigToLayout(layoutConfiguration: string, isInMemory: boolean) {
    return( ConvertColumnFromConfig(JSON.parse(layoutConfiguration), isInMemory));
}

// converts our the coulumn layout used by ag-grid to our view configuration
// tslint:disable-next-line: no-any
export function ConvertViewLayoutToConfig(viewLayout: Column[]): string {
    var columnConfig = ConvertColumnsToConfig(viewLayout);
    return JSON.stringify(columnConfig);
}

export function ConvertColumnFromConfig(columncfgs: Array<SummaryViewColumnConfiguration>, isInMemory: boolean): Array<ColDef> {
    var result = new Array<ColDef>();
    columncfgs.forEach(columncfg => {
        var filter = getFilterForDataType(columncfg.dataType);
        
        let showMenuTab = (columncfg.dataType !== DataType.ContextMenu || columncfg.dataType !== DataType.ContextMenuStaff) && isInMemory;
        let isDateFilter = columncfg.dataType === DataType.Date || DataType.DateTime;

        var config = {
            field: columncfg.field,
            headerName: columncfg.headerName,
            width: columncfg.width,
            format: columncfg.dataType,
            filter: filter ? filter : '',
            filterParams: isDateFilter ? filterParamsOptions() : null,
            hide: !columncfg.show,
            pinned: columncfg.isLocked ? 'left' : 'none',
            menuTabs: showMenuTab ? menuTabs : nonFilterMenuTabs,
            enableRowGroup: true,
            sort: columncfg.sort,
            tooltipField: columncfg.dataType === DataType.String ? columncfg.field : undefined,
            aggFunc: columncfg.aggFunc,
            enableValue: columncfg.enableValue,
            valueGetter: getValueGetter(columncfg.valueGetter),
            cellClass: columncfg.cellClass,
        };
        
        applyCellRendererToColumn(config, columncfg.dataType);
        result.push(config);
    });
    return result;
}

// tslint:disable-next-line: no-any
function ConvertColumnsToConfig(columns: Column[]): SummaryViewColumnConfiguration[] {
    var result = new Array<SummaryViewColumnConfiguration>();
    // tslint:disable-next-line: no-any
    columns.forEach((column: Column | any) => {
        var colDef = column.getColDef();

        var columnCfg = {
            field: colDef.field ? colDef.field : '',
            headerName: colDef.headerName ? colDef.headerName : '',
            width: column.getActualWidth(),
            dataType: getDataTypeForColumn(colDef),
            show: column.isVisible() || column.isRowGroupActive(),
            isLocked: colDef.field === 'FileNumber',
            sort: column.getSort(),
            aggFunc: colDef.aggFunc,
            enableValue: colDef.enableValue,
            valueGetter: colDef.field,
            cellClass: colDef.cellClass,
        };
        result.push(columnCfg);
    });
    return result;
}

export function DefaultColumnConfig(columns: Array<ColumnProperty>): Array<ColDef> {
    var result = new Array<ColDef>();
    columns.forEach(column => {
        var filter = getFilterForDataType(column.format);

        var isLocked = column.field === 'FileNumber';
        let showMenuTab = (column.format !== DataType.ContextMenu || column.format !== DataType.ContextMenuStaff );

        var colDef = {
            field: column.field ? column.field : '',
            headerName: column.headerName ? column.headerName : '',
            dataType: column.format,
            width: column.width ? column.width : 50,
            format: column.format,
            filter: filter ? filter : '',
            hide: column.hide,
            show: !column.hide,
            pinned: isLocked ? 'left' : 'none',
            isLocked: isLocked,
            menuTabs: showMenuTab ? menuTabs : nonFilterMenuTabs,
            enableRowGroup: true,
            sort: column.sort ? column.sort : '',
            tooltipField: column.format === DataType.String ? column.field : undefined,
            aggFunc: column.aggFunc,
            enableValue: column.enableValue,
            valueGetter: getValueGetter(column.field),
            cellClass: column.cellClass,
            // rowHeight: column.format === DataType.Image ? 50 : 40 ,
        };
        applyCellRendererToColumn(colDef, column.format);
        result.push(colDef);
    });
    return result;   
}

function getDataTypeForColumn(column: ColDef): number {
    if (column.cellRendererFramework === DateTimeRenderer) {
        return DataType.DateTime;
    }
    if (column.cellRendererFramework === DateRenderer) {
        return DataType.Date;
    }
    if (column.cellRendererFramework === NumberRenderer) { // filter === 'number') {
        return DataType.Number;
    }
    if (column.cellRendererFramework === CurrencyRenderer) {
        return DataType.Currency;
    }
    if (column.cellRendererFramework === CurrencyRendererWithoutDecimal) {
        return DataType.CurrencyWithoutDecimals;
    }
    if (column.cellRendererFramework === BoolRenderer) {
        return DataType.Boolean;
    }
    if (column.cellRendererFramework === ImageRenderer) {
        return DataType.Image;
    }
    if (column.cellRendererFramework === PercentageRenderer) {
        return DataType.Percentage;
    }
    if (column.cellRendererFramework === MatterTaskAction) {
        return DataType.ContextMenu;
    }
    if (column.cellRendererFramework === StaffTaskAction) {
        return DataType.ContextMenuStaff;
    }
    if (column.cellRendererFramework === DurationRenderer) {
        return DataType.Duration;
    }
    if (column.filter === 'date') {
        return DataType.Date;
    }
    if (column.filter === 'dateTime') {
        return DataType.DateTime;
    }
    if (column.filter === 'image') {
        return DataType.Image;
    }
    if (column.filter === 'menu') {
        return DataType.ContextMenu;
    }
    if (column.filter === 'percentage') {
        return DataType.Percentage;
    }
    if (column.filter === 'longTextRenderer') {
        return DataType.LongText;
    }
    if (!column.cellRenderer) {
        return DataType.String;
    }
    return DataType.Boolean;
}

export function getFilterForDataType(dataType: number): string | void {
    switch (dataType) {
        case DataType.Date:
            return 'agDateColumnFilter';
        case DataType.DateTime:
            return 'agDateColumnFilter';
        case DataType.Percentage:
            return 'percentage';
        case DataType.Number:
            return 'number';
        case DataType.Currency:
            return 'agNumberColumnFilter';
        case DataType.CurrencyWithoutDecimals:
            return 'agNumberColumnFilter';
        case DataType.Image:
            return 'image';   
        case DataType.Boolean:
            return 'boolean';
        case DataType.LongText:
            return 'text';
        case DataType.Duration:
            return 'duration';
        case DataType.ContextMenu:
            return 'menu';
        default:
            return 'text';
    }
}

export function applyCellRendererToColumn(column: ColDef, dataType: number) {
    switch (dataType) {
        case DataType.DateTime:
            column.cellRendererFramework = DateTimeRenderer;
            break;
        case DataType.Date:
            column.cellRendererFramework = DateRenderer;
            break;
        case DataType.Number:
            column.cellRendererFramework = NumberRenderer;
            break;
        case DataType.Currency:
            column.cellRendererFramework = CurrencyRenderer;
            break;
        case DataType.CurrencyWithoutDecimals:
            column.cellRendererFramework = CurrencyRendererWithoutDecimal;
            break;
        case DataType.Boolean:
            column.cellRendererFramework = BoolRenderer;
            break;
        case DataType.Image:
            column.cellRendererFramework = ImageRenderer;
            break;
        case DataType.Percentage:
            column.cellRendererFramework = PercentageRenderer;
            break;
        case DataType.ContextMenu:
            column.cellRendererFramework =  MatterTaskAction;
            break;
        case DataType.ContextMenuStaff:
            column.cellRendererFramework =  StaffTaskAction;
            break;
        case DataType.Duration:
            column.cellRendererFramework =  DurationRenderer;
            break;
        case DataType.String:
        default:
            return;
    }
}

// var filterParams = {
//     // tslint:disable-next-line: no-any
//     comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
//         var dateAsString = cellValue;
//         if (dateAsString == null) {
//             return -1;
//         }
//         const diff = moment(cellValue).diff(moment(filterLocalDateAtMidnight), 'days');
//         if (diff === 0) {
//             return 0;
//         }
//         if (diff < 0) {
//             return -1;
//         }
//         if (diff > 0) {
//             return 1;
//         } else {
//             return -1;
//         }
//         // if (moment(filterLocalDateAtMidnight).toDate().getDate() === moment(cellValue).format('DD-MMM-YYYY')) {
//         //     return 0;
//         // }
//         // if (moment(cellValue).format('DD-MMM-YYYY') < moment(filterLocalDateAtMidnight).format('DD-MMM-YYYY')) {
//         //     return -1;
//         // }
//         // if (moment(cellValue).format('DD-MMM-YYYY') > moment(filterLocalDateAtMidnight).format('DD-MMM-YYYY')) {
//         //     return 1;
//         // } else {
//         //     return -1;
//         // }
//     },
//     browserDatePicker: true,
// };

export const getValueGetter = (valueGetter?: string) => {
    if (valueGetter === 'weekToDateBudgetBilledVariance') { // Staff Billing
        return weekToDateBudgetBilledVariance;
    } else if (valueGetter === 'weekToDateBudgetCollectionVariance') { // Staff Billing
        return weekToDateBudgetCollectionVariance;
    } else if (valueGetter === 'weekToDateBudgetProducedVariance') { // Staff Billing
        return weekToDateBudgetProducedVariance;
    } else if (valueGetter === 'monthToDateBudgetBilledVariance') { // Staff Billing
        return monthToDateBudgetBilledVariance;
    } else if (valueGetter === 'monthToDateBudgetCollectionVariance') { // Staff Billing
        return monthToDateBudgetCollectionVariance;
    } else if (valueGetter === 'monthToDateBudgetProducedVariance') { // Staff Billing
        return monthToDateBudgetProducedVariance;
    } else if (valueGetter === 'yearToDateBudgetBilledVariance') { // Staff Billing
        return yearToDateBudgetBilledVariance;
    } else if (valueGetter === 'yearToDateBudgetCollectionVariance') { // Staff Billing
        return yearToDateBudgetCollectionVariance;
    } else if (valueGetter === 'yearToDateBudgetProducedVariance') { // Staff Billing
        return yearToDateBudgetProducedVariance;
    } else if (valueGetter === 'weekToDateBillableHours') { // Staff Billing
        return weekToDateBillableHours;
    } else if (valueGetter === 'weekToDateNonBillableHours') { // Staff Billing
        return weekToDateNonBillableHours;
    } else if (valueGetter === 'weekToDateBudgetChargeableHours') { // Staff Billing
        return weekToDateBudgetChargeableHours;
    } else if (valueGetter === 'weekToDateBudgetNonChargeableHours') { // Staff Billing
        return weekToDateBudgetNonChargeableHours;
    } else if (valueGetter === 'monthToDateBillableHours') { // Staff Billing
        return monthToDateBillableHours;
    } else if (valueGetter === 'monthToDateNonBillableHours') { // Staff Billing
        return monthToDateNonBillableHours;
    } else if (valueGetter === 'monthToDateBudgetChargeableHours') { // Staff Billing
        return monthToDateBudgetChargeableHours;
    } else if (valueGetter === 'monthToDateBudgetNonChargeableHours') { // Staff Billing
        return monthToDateBudgetNonChargeableHours;
    } else if (valueGetter === 'yearToDateBillableHours') { // Staff Billing
        return yearToDateBillableHours;
    } else if (valueGetter === 'yearToDateNonBillableHours') { // Staff Billing
        return yearToDateNonBillableHours;
    } else if (valueGetter === 'yearToDateBudgetChargeableHours') { // Staff Billing
        return yearToDateBudgetChargeableHours;
    } else if (valueGetter === 'yearToDateBudgetNonChargeableHours') { // Staff Billing
        return yearToDateBudgetNonChargeableHours;
    } else if (valueGetter === 'fullFinancialYearBillableHoursBgt') { // Staff Billing
        return fullFinancialYearBillableHoursBgt;
    } else if (valueGetter === 'fullFinancialYearNonBillableHoursBgt') { // Staff Billing
        return fullFinancialYearNonBillableHoursBgt;
    } else if (valueGetter === 'percentageEstimate') { // Matter Summary
        return percentageEstimate;
    } else if (valueGetter === 'exposure') { // Matter Summary
        return exposure;
    } else if (valueGetter === 'monthToDateRealisation') { // Staff Billing
        return monthToDateRealisation;
    } else if (valueGetter === 'yearToDateRealisation') { // Staff Billing
        return yearToDateRealisation;
    } else if (valueGetter === 'yearToDateCollection') {
        return yearToDateCollection;
    } else if (valueGetter === 'monthToDateCollection') {
        return monthToDateCollection;
    } else if (valueGetter === 'previousMonthYearToDateBudgetBilledVariance') {
        return previousMonthYearToDateBudgetBilledVariance;
    } else if (valueGetter === 'previousMonthYearToDateBudgetCollectionVariance') {
        return previousMonthYearToDateBudgetCollectionVariance;
    } else if (valueGetter === 'previousMonthYearToDateBudgetProducedVariance') {
        return previousMonthYearToDateBudgetProducedVariance;
    } else if (valueGetter === 'previousMonthYearToDateBillableHours') {
        return previousMonthYearToDateBillableHours;
    } else if (valueGetter === 'previousMonthYearToDateNonBillableHours') {
        return previousMonthYearToDateNonBillableHours;
    } else if (valueGetter === 'previousMonthYearToDateBudgetChargeableHours') {
        return previousMonthYearToDateBudgetChargeableHours;
    } else if (valueGetter === 'previousMonthYearToDateBudgetNonChargeableHours') {
        return previousMonthYearToDateBudgetNonChargeableHours;
    } else if (valueGetter === 'previousMonthToMonthToDateBudgetBilledVariance') {
        return previousMonthToMonthToDateBudgetBilledVariance;
    } else if (valueGetter === 'previousMonthToMonthToDateBudgetCollectionVariance') {
        return previousMonthToMonthToDateBudgetCollectionVariance;
    } else if (valueGetter === 'previousMonthToMonthToDateBudgetProducedVariance') {
        return previousMonthToMonthToDateBudgetProducedVariance;
    } else if (valueGetter === 'previousMonthToMonthToDateBillableHours') {
        return previousMonthToMonthToDateBillableHours;
    } else if (valueGetter === 'previousMonthToMonthToDateNonBillableHours') {
        return previousMonthToMonthToDateNonBillableHours;
    } else if (valueGetter === 'previousMonthToMonthToDateBudgetChargeableHours') {
        return previousMonthToMonthToDateBudgetChargeableHours;
    } else if (valueGetter === 'previousMonthToMonthToDateBudgetNonChargeableHours') {
        return previousMonthToMonthToDateBudgetNonChargeableHours;
    } else {
        return undefined;
    }
};