import React from 'react';
import { List } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, FilterOptionsState } from '@material-ui/lab';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList, ListOnScrollProps, ListChildComponentProps } from 'react-window';
import { IAutoCompleteItem } from '../typings/autoComplete';
// import List from '@material-ui/core/List';
import { matchSorter } from 'match-sorter';

let scrollOffset = 0;

const withInfinite = ({
    hasNextPage,
    isNextPageLoading,
    items,
    loadNextPage,
    itemSize,
    height,
    reverseRender
// tslint:disable-next-line: no-any
}: InfiniteProps) => {
    const itemCount = hasNextPage ? items.length + 1 : items.length;
    // tslint:disable-next-line: no-empty
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
    // tslint:disable-next-line: no-any
    const isItemLoaded = (index: any) => !hasNextPage || index < items.length;
  
    // tslint:disable-next-line: typedef no-any
    return React.forwardRef(function ListboxComponent(props: any, ref: any) {
        const { children, ...other } = props;
        //   const theme = useTheme();
        //   const smUp = useMediaQuery(theme.breakpoints.up('sm'));

        //   const tempItemSize = smUp ? itemSize.small : itemSize.large;
  
        const outerElementType = React.useMemo(() => {
            // tslint:disable-next-line: no-any
            return React.forwardRef((props2: any, ref2: any) => (
                <div ref={ref}>
                    <div ref={ref2} {...props2} {...other} />
                </div>
            ));
        // tslint:disable-next-line: align
        }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
        // tslint:disable-next-line: no-any
        const renderRow = ({ data, index, style }: ListChildComponentProps) => {

            if (!data || index >= data.length) {
                return <div />;
            }

            // render the data in reverse or normal display;
            const individualData = reverseRender ? data[(data.length - 1) - index] : data[index];

            return (
                React.cloneElement(individualData, {
                    style: {
                        // overflow: 'hidden',
                        // textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap',
                        // display: 'block',
                        ...style
                    }
                })
            );
        };
    
        return (
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                threshold={5}
            >
            {
                // tslint:disable-next-line: no-shadowed-variable
                ({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        className="List"
                        layout="vertical"
                        style={{
                            padding: 0,
                            // height: Math.min(8, itemCount) * itemSize,
                            maxHeight: 'auto',
                            // width: '100%',
                            maxWidth: '100%'
                        }}
                        // tslint:disable-next-line: no-console no-any
                        onScroll={(scroll: ListOnScrollProps) => {
                            scrollOffset = scroll.scrollOffset;
                        }}
                        initialScrollOffset={scrollOffset}
                        itemData={children}
                        height={height ? height : 250}
                        width="100%"
                        outerElementType={outerElementType}
                        innerElementType={List}
                        itemSize={itemSize}
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                    >
                        {renderRow}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        );
    });
};

// tslint:disable-next-line: no-any
export interface AutocompleteStandardProps<T> extends AutocompleteProps<T> {
    // id?: string;
    // className?: string;
    // style?: React.CSSProperties;
    isMultiple?: boolean;
    // label?: string;
    name: string;
    // disablePortal?: boolean;
    // disableCloseOnSelect?: boolean;
    // disableListWrap?: boolean;
    // // tslint:disable-next-line: no-any
    // classes?: any;
    // open?: boolean;
    // tslint:disable-next-line: no-any
    value?: T | any; // value only available in single select
    // // tslint:disable-next-line: no-any
    // renderOption?: (option: any, state: RenderOptionState) => React.ReactNode;
    // tslint:disable-next-line: no-any
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[] | any, name: string) => void;
    // tslint:disable-next-line: no-any
    onClose?: (event: React.ChangeEvent<{}>) => void;
    onOpen?: (event: React.ChangeEvent<{}>) => void;
    // onClose?: (event: React.ChangeEvent<{}>) => void;
    // // tslint:disable-next-line: no-any
    // renderTags?: (value: any, getTagProps: GetTagProps) => React.ReactNode;
}

interface InfiniteProps {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    // tslint:disable-next-line: no-any
    items: any;
    // tslint:disable-next-line: no-any
    loadNextPage: any;
    itemSize: number;
    isLoading?: boolean;
    height?: number;
    reverseRender?: boolean;
}

interface InfiniteAutocompleteProps<T> extends InfiniteProps, AutocompleteStandardProps<T> {
    disabledFilterOptions?: boolean;
}

// tslint:disable-next-line: no-any
export const InfiniteAutocomplete: React.FC<InfiniteAutocompleteProps<any>> = props => {

    // tslint:disable-next-line: no-any
    const ListboxComponent: any = withInfinite({
        hasNextPage: props.hasNextPage,
        isNextPageLoading: props.isNextPageLoading,
        items: props.items,
        loadNextPage: props.loadNextPage,
        itemSize: props.itemSize,
        isLoading: props.isLoading,
        height: props.height,
        reverseRender: props.reverseRender
    });

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const handleClose = (event: React.ChangeEvent<{}>) => {
        if (props.onClose) {
            props.onClose(event);
        }
    };

    const handleOpen = (event: React.ChangeEvent<{}>) => {

        scrollOffset = 0;

        if (props.onOpen) {
            props.onOpen(event);
        }
    };

    /* 
        Why are we using match sorter?
        Consider a scenario where the records from DB returns – “Amanda Louise Heather”
        AutoComplete component can't search by 1st word and last word. It doesn't have that capacity. 
        It will work for "Louise Heather" or "Amanda Louise" But not "Amanda Heather". This is an issue.
        Using matchSorter, we can search on any text in label. (in memory)
    */
    // tslint:disable-next-line: no-any
    const filterOptions = (options: any, filterOptionsState: FilterOptionsState) => {
        // tslint:disable-next-line: no-console
        if (props.disabledFilterOptions) {
            return props.items;
        } else if (filterOptionsState && filterOptionsState.inputValue && filterOptionsState.inputValue.length > 0) {
            const terms = filterOptionsState.inputValue.split(' ');
            if (!terms) {
                return null;
            }
            // reduceRight will mean sorting is done by score for the _first_ entered word.
            return terms.reduceRight(
                (results, term) => matchSorter(results, term, {keys: ['label']}), options);

           //  return matchSorter(options, filterOptionsState.inputValue.trim(), {keys: ['label']}); // 3 = CONTAINS. Default is match
        } else {
            return props.items;
        }        
    };

    return (
        <>
            {
                props.isMultiple ?
                (
                    <Autocomplete
                        {...props}
                        // disableListWrap={props.disableListWrap}
                        // value={props.value}
                        // open={props.open}
                        // onClose={props.onClose}
                        // disablePortal={props.disablePortal}
                        // disableCloseOnSelect={props.disableCloseOnSelect}
                        // renderTags={props.renderTags}
                        multiple={true}
                        // id={props.id}
                        // style={props.style}
                        classes={props.classes}
                        // className={props.className}
                        ListboxComponent={ListboxComponent}
                        // filterOptions={filterOptions}
                        options={props.items}
                        onChange={handleChange}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        // renderOption={props.renderOption}
                    />
                )
                :
                (
                    <Autocomplete
                        {...props}
                        // disableListWrap={props.disableListWrap}
                        // value={props.value}
                        // open={props.open}
                        // onClose={props.onClose}
                        // disablePortal={props.disablePortal}
                        // disableCloseOnSelect={props.disableCloseOnSelect}
                        // renderTags={props.renderTags}
                        // id={props.id}
                        // style={props.style}
                        classes={props.classes}
                        // className={props.className}
                        ListboxComponent={ListboxComponent}
                        options={props.items}
                        filterOptions={(options, filterOptionsState) => filterOptions(options, filterOptionsState)}
                        onChange={handleChange}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        loading={props.isLoading}                   
                        // renderOption={props.renderOption}
                    />
                )
            }
        </>
    );
};
