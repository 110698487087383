import * as React from 'react';
import glamorous from 'glamorous';

const Container = glamorous.div ({
    padding: '0 1rem',
    maxWidth: '100%',
    margin: '0 auto'
});

export interface ContinerProps {
    rvMaxWidth?: string;
    rvClassName?: string;
}

export class RvContainer extends React.Component<ContinerProps, {}> {
    public render() {
        return	(
                <Container
                    className={'rv-Container ' + ' ' + this.props.rvClassName}
                    style={{maxWidth: this.props.rvMaxWidth}}
                >
                    {this.props.children}
                </Container>
      );
    }
}