/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TextField, CircularProgress, ListItemText, Tooltip } from '@material-ui/core';
// import { IAutoCompleteItem } from '../../typings/autoComplete';
import { RenderInputParams } from '@material-ui/lab';
import { InfiniteAutocomplete } from './InfiniteAutocomplete';
import { showNotification } from '../App';
import { client } from '..';
import { MatterSummaryListXData, MatterSummaryQueryXParams, MatterSummaryX } from '../matterSummary/MatterSummaryRepository';
import { IAutoCompleteItem } from '../typings/autoComplete';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            }
        }
      },
      secondaryWrapper: {
        display: 'flex',
        flexFlow: 'column',
      }
  })
);

interface MatterAutoCompleteSelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    disabled?: boolean;
    disablePortal?: boolean;
    enableSecondary?: boolean;
    rootClassName?: string;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    textLimit?: {
        description?: number;
        title?: number;
    };
}

interface ItemsProps extends IAutoCompleteItem {
    fileNumber: string;
    title: string;
    client: string;
    id: number;
}

interface MatterAutoCompleteSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    // tslint:disable-next-line: no-any
    items: ItemsProps[];
    filter: string;
}

export const MatterAutoCompleteSelector: React.FC<MatterAutoCompleteSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 20;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = true; // Clear on search
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );
    
    const [state, setState] = useState<MatterAutoCompleteSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        items: [],
        filter: '',
    });

    const fetchData = (filter: string, offset: number, isClear: boolean) => {
        
        // console.log('fetchData', offset);
        
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const matterSummaryQueryXParams: MatterSummaryQueryXParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            isIncludeCompleted: false,
            sortColumn: 'fileNumber',
            sortDirection: 'asc',
            isLimitedMatterSearch: true,
            isDropdownSearch: true
        };

        fetchMatterData(
            matterSummaryQueryXParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onDataRetrieved(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onDataRetrieved = (data: MatterSummaryListXData, filter: string, offset: number, isClear: boolean) => {

        const matterData = data.matterSummaryListX.matters;
        const rowCountWithoutFilters = data.matterSummaryListX.rowCountWithoutFilters; 

        // console.log('matterData', matterData);

        // tslint:disable-next-line: no-any
        let itemsList: ItemsProps[] = [];
        let items = state.items;
        
        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = matterData.map( (source: MatterSummaryX) => (
                {
                    label: source.fileNumber + ' (' + source.title + ')',
                    value: source.matterGuidId,
                    fileNumber: source.fileNumber,
                    title: source.title,
                    client: source.client,
                    id: source.id
                }));
        } else {
            for (const matter of matterData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item.value === matter.matterGuidId).length;

                // const isExists = items.filter((item: IndividualNameSummary) => 
                //     item.guidID.toString().toLowerCase().includes(nameSummary.guidID.toLowerCase())).length;

                if (isExists === 0) {
                    itemsList.push({
                        label: matter.fileNumber + ' (' + matter.title + ')',
                        value: matter.matterGuidId,                       
                        fileNumber: matter.fileNumber,
                        title: matter.title,
                        client: matter.client,
                        id: matter.id   
                    });
                }
            }
        }

        setState((prevState) => {
            
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: matterData.length !== 0 && (offset + loadBlockLimit) < rowCountWithoutFilters,
            };
        });

    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    // const onInputChange = (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
    //     let isNextPageLoading = false;
    //     let hasNextPage = false;
    //     let isClear = false;
    //     let offset = 0;
    //     let filter = '';

    //     if (reason === 'input') {
    //         filter = value;
    //         isClear = true;
    //         isNextPageLoading = true;
    //         hasNextPage = true;

    //         fetchData(filter, offset, isNextPageLoading, hasNextPage, isClear);
    //     }        
    // };

    const getInputProps = (params: RenderInputParams, loading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        
        fetchData(state.filter, 0, true);
    };

    // tslint:disable-next-line: no-any
    const onSelection = (selection: any, name: string) => {

        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
        arrow: {
            '&:before': {
              border: '1px solid #E6E8ED'
            },
            color: theme.palette.common.white
          },
      }))(Tooltip);

    const shortingTitle = (title: string, limit?: number) => {

        // const _limit = limit ? limit : 0;

        if (limit === undefined || limit === null) {
            return title;
        } else if (title === undefined || title === null) {
            return title;
        } else if (title.length < limit) {
            return title;
        } else {
            return (                
                <LightTooltip title={title} aria-label="client" arrow={true} placement="right">
                    <span>{`${title.substring(0, limit)}...`}</span>
                </LightTooltip>           
            );
        }
    };

    return (
        <div className={`${classes.root} ${props.rootClassName}`}>
            <InfiniteAutocomplete
                key={props.id}
                itemSize={70}
                loading={state.isNextPageLoading}
                name={props.name}
                disabled={props.disabled}
                disablePortal={props.disablePortal}
                disableCloseOnSelect={false}                
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                // inputValue={props.value}
                options={state.items}
                loadNextPage={loadNextPage}                
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={option => typeof option === 'string' ? option : option.label}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                disabledFilterOptions={true}
                getOptionSelected={(option, value) => option.value === value.value} 
                // reverseRender={true}
                // tslint:disable-next-line: no-any
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText}  
                    />
                )}
                renderOption={(option, { selected }) => {
                    return (
                        <ListItemText 
                            primary={option.fileNumber} 
                            secondary={(
                                <div className={classes.secondaryWrapper}>
                                    <span>{shortingTitle(option.title, props.textLimit?.title)}</span>
                                    <span>{shortingTitle(option.client, props.textLimit?.description)}</span>
                                </div>
                            )}
                        />
                    );
                }}
            />
        </div>
    );
};

export function fetchMatterData(
    query: MatterSummaryQueryXParams, 
    refreshData: boolean,
    onSuccess: (data: MatterSummaryListXData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    const fetchPolicies: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: MatterSummaryXData,
        variables: {
            filter: query.filter,
            first: query.first,      
            offset: query.offset,
            sortColumn: query.sortColumn,
            sortDirection: query.sortDirection,
            isLimitedMatterSearch: query.isLimitedMatterSearch,
            isDropdownSearch: query.isDropdownSearch
        },
        // tslint:disable-next-line:object-literal-sort-keys
        fetchPolicy: fetchPolicies}
    )
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

const MatterSummaryXData = gql`
    query MatterSummaryXData(
        $offset: Int, 
        $first: Int, 
        $filter: String, 
        $isIncludeCompleted: Boolean, 
        $sortColumn: String, 
        $sortDirection: String,
        $isLimitedMatterSearch: Boolean,
        $isDropdownSearch: Boolean,
    ) {
        matterSummaryListX(
            offset: $offset, 
            first: $first, 
            filter: $filter, 
            isIncludeCompleted: $isIncludeCompleted, 
            sortColumn: $sortColumn, 
            sortDirection: $sortDirection,
            isLimitedMatterSearch: $isLimitedMatterSearch,
            isDropdownSearch: $isDropdownSearch
        ) {
                recordCount,
                rowCountWithoutFilters
                matters {
                    id      
                    fileNumber 
                    title 
                    matterGuidId
                    client
                }    
        }
    }
`;