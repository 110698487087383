import { CircularProgress } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { RenderInputParams } from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import * as React from 'react';
import { fetchRoleCategoryData, DropDownRoleCategoryData } from '../featureConfigurationLayout/FeatureLayoutConfigurationRepository';
import { IAutoCompleteItem } from '../typings/autoComplete';

interface SelectorProps {
    onSelection?: (selection: IAutoCompleteItem, name: string) => void;
    // tslint:disable-next-line: no-any
    items?: any;
    required?: boolean;
    label?: string;
    name: string;
    value: string;
    disabled?: boolean;
    isIncludeAdministrator?: boolean;
}

interface SelectorState {
    selectedCode: string;
    selectedLabel: string;
}

export class RoleCategorySelector extends React.Component<SelectorProps, SelectorState> {
    // tslint:disable-next-line: no-any
    public roleCategoryData: any;

    constructor(props: SelectorProps, state?: SelectorState) {
        super(props, state);
        
        this.handleChange = this.handleChange.bind(this);
        
        this.state = {
            selectedCode: '',
            selectedLabel: ''
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: SelectorProps) {
        if (this.props.value !== nextProps.value && this.roleCategoryData) {
            // tslint:disable-next-line: no-any
            const filteredItem = this.roleCategoryData.find((item: any) => item.value.toString().includes(nextProps.value.toString()));

            if (filteredItem && nextProps.value.toString() !== '') {
                this.setState({
                    selectedCode: filteredItem.value,
                    selectedLabel: filteredItem.label
                });
            } else {
                this.setState({
                    selectedCode: '',
                    selectedLabel: ''
                });
            }           
        }
    }

    public render() {
        let roleCategoryList = fetchRoleCategoryData(this.props.isIncludeAdministrator ? this.props.isIncludeAdministrator : undefined);

        this.roleCategoryData = roleCategoryList.map( (roleCategory: DropDownRoleCategoryData) => (
            {
                value: roleCategory.value, 
                label: roleCategory.text 
            })
        );
        
        return (
            <div className={'downshift-wrapper'}>
                <Autocomplete
                    loading={false}
                    disabled={this.props.disabled}
                    autoSelect={true}
                    value={{value: this.state.selectedCode, label: this.state.selectedLabel}}
                    onChange={this.handleChange}
                    options={this.roleCategoryData}
                    // tslint:disable-next-line: jsx-no-lambda
                    getOptionLabel={option => option.label}           
                    getOptionSelected={(option, value) => value.value === option.value}         
                    // tslint:disable-next-line: jsx-no-lambda
                    renderInput={params => (
                        <TextField 
                            {...params} 
                            label={this.props.label} 
                            variant="standard" 
                            fullWidth={true} 
                            margin="none" 
                            required={this.props.required}
                            InputProps={this.getInputProps(params, false)}  
                        />
                    )}
                    // tslint:disable-next-line: jsx-no-lambda
                    renderOption={(option, { inputValue }) => {
                        const matches = match(option.label, inputValue);
                        const parts = parse(option.label, matches);
                
                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                />
            </div>
        );
    }

    private getInputProps(params: RenderInputParams, isLoading?: boolean) {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    }

    // tslint:disable-next-line: no-any
    private handleChange(event: object, item: any) {        
        this.setState( {
            selectedCode: item ? item.value : '',
            selectedLabel: item ? item.label : ''
        });

        if (this.props.onSelection) {
            this.props.onSelection({label: item ? item.label : '', value: item ? item.value.toString() : ''},
                                   this.props.name);
        }     
    }
}