import {
    Dialog,
    IconButton,
    makeStyles,
    Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import ReactPlayer from 'react-player';
import { RvLoader } from './Loader';
import CloseIcon from '@material-ui/icons/Close';

export const useStyles = makeStyles((theme) => ({
    root: {},
    dialogPaper: {
        position: 'relative',
        minHeight: '1000px',
        overflowY: 'initial'
    },
    innerContainer: {
        display: 'flex',
        flex: 1,
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        '& > div': {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        }
    },
    loaderWrapper: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: '-45px',
        top: '-45px',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        }
    },
}));

interface VideoModalProps {
    open: boolean;
    url: string;
    onClose?: () => void;
}

const SlideTransition = React.forwardRef(function Transition(props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const VideoModal: React.FC<VideoModalProps> = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = React.useState(true);

    const handleReady = () => {
        setLoading(false);
    };
    
    const handleBuffer = () => {
        setLoading(true);
    };

    return (
        <Dialog
            fullWidth={true}
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={SlideTransition}
            maxWidth="xl"
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <div className={classes.innerContainer}>
                <IconButton className={classes.closeButton} size="medium" onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
                {loading && (
                    <div className={classes.loaderWrapper}>
                        <RvLoader 
                            label="Preparing content..."
                        />
                    </div>
                )}
                <ReactPlayer 
                    url={props.url} 
                    width="100%" 
                    height="100%" 
                    controls={true} 
                    playing={true}
                    onReady={handleReady} 
                    onBuffer={handleBuffer}
                />
            </div>
        </Dialog>
    );
};
