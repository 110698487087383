import * as React from 'react';
import { RvLabel, LabelStyle } from '../Label/Label';
import '../../App.css';
import { Grid } from 'semantic-ui-react';
import { DataDisplayProps } from './IDataDisplayProps';
import glamorous from 'glamorous';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

const LabelData = glamorous(Grid.Column) ({
    textAlign: 'left'    
});

const ValueData = glamorous(Grid.Column) ({
    textAlign: 'left',
    whiteSpace: 'pre-line'
});

interface DataDisplay {
    isHidden: boolean;
}

interface StringDisplayProps extends DataDisplayProps {
    value?: string | React.ReactNode;
    style?: LabelStyle;
    color?: string;
}

export class RvStringDisplay extends React.Component<StringDisplayProps, {}> implements DataDisplay {
    hiddenOnEmpty: boolean;
    isHidden: boolean;

    // tslint:disable-next-line:no-any
    constructor(props: StringDisplayProps, context?: any) {
        super(props, context);
        // default to true if not set
        this.hiddenOnEmpty = (this.props.hiddenOnEmpty == null ? true : this.props.hiddenOnEmpty);
    }

    public render() {
          this.isHidden = this.props.value == null && this.hiddenOnEmpty;
          if (this.isHidden)    {
            return null;
          }
          return (
            <Grid columns={2} className="stringDisplay"> 
                <LabelData className="LabelData" width={this.props.LabelDataWidth}>
                    <RvLabel 
                        label={this.props.label} 
                        style={this.props.style ? this.props.style : LabelStyle.Default}
                        color={this.props.color}
                    />
                </LabelData>
                <ValueData className="ValueData" width={this.props.ValueDataWidth}>
                    {typeof this.props.value === 'string' ? (
                        <RvLabel label={this.props.value} color={this.props.color} />
                    ) : this.props.value}
                </ValueData>
            </Grid>
        );
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            flexFlow: 'row wrap'
        },
        label: {
            flex: 1,
        }
    })
);

type TStringDisplayProps = {
    value?: string;
    style?: LabelStyle;
    color?: string;
    label?: string;
};

export const StringDisplay = (props: TStringDisplayProps) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.label}>
                <RvLabel 
                    label={props.label} 
                    style={props.style ? props.style : LabelStyle.Default}
                    color={props.color}
                />
            </div>
            <div className={classes.label}>
                <RvLabel label={props.value} color={props.color} />
            </div>
        </div>
    );
};