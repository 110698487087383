import * as React from 'react';
import { Grid, Sidebar, Menu, Segment, SemanticICONS, MenuItem, Icon } from 'semantic-ui-react';
import { ButtonProps } from './Button';
import glamorous from 'glamorous';
import '../index.css';
// import { getGlobalHistory } from './GlobalHistory';
import { browserHistory } from './AppContent';

const SidebarPushable = glamorous(Sidebar.Pushable)({
    width: '100%',
});

const DrawerGrid = glamorous(Grid)({
    margin: '0 !important',
    width: '100%'
});

// tslint:disable-next-line:no-any
const SideBarMenu = glamorous(Sidebar)<{theme?: any}> ((props) => ({
    padding: '10px 20px',
    // top: '95px !important',
    minWidth: '200px',
    // width: 'auto !important',
    [`& .${`button`}`] : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        fontWeight: props.theme.FontWeight.Normal + ' !important',
        color: props.theme.BrandColors.TemplatePrimary,
        marginTop: 10,
        fontSize: props.theme.FontSize.PX.size1,
        width: '100%',
        ':hover' : {
            background: 'transparent'
        },
    },
    [`& .${`icon`}`] : {
        fontSize: props.theme.FontSize.EM.size1,
        // marginBottom: '7px !important',
        // margin: '0 !important'
    },
    [`& .${`input`}`] : {
        fontSize: props.theme.FontSize.REM.size1,
    },
    [`& .${`link`}`] : {
        color: props.theme.BrandColors.TemplatePrimary,
        marginBottom: '10px',
        '::before': {
            height: 'auto !important',
        }
    }    
}));

const SegmentContainer = glamorous(Segment)({
    // top: '90px',
    // minHeight: window.innerHeight - 250,
    padding: '0 !important',
    // flex: 1,
    // display: 'flex',
});

// tslint:disable-next-line:no-any
const SidebarPusher = glamorous(Sidebar.Pusher)<{theme?: any}>((props) => ({
    // background: props.theme.NeutralColors.BackgroundGrey5 
    // display: 'flex',
    // flexFlow: 'column wrap',   

}));

interface DrawerProps {
    visible?: boolean;
    links?: Array<LinkProps>;
    // isOpen?: boolean;
    orientation?: 'left' | 'right' | 'top' | 'bottom';
    page?: string;
    // tslint:disable-next-line:no-any
    drawerContent?: any;
    animation?: 'overlay' | 'push' | 'scale down' | 'uncover' | 'slide out' | 'slide along';
    width?: 'very thin' | 'thin' | 'wide' | 'very wide';
    handleSidebarHide?: (value: boolean) => void;
    className?: string;
}
export interface LinkProps {
    iconKey?: SemanticICONS;
    label?: string;
    // tslint:disable-next-line:no-any
    linkTo?: any;
    visible: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
}

interface DrawerState {
    visible?: boolean;
}
export class RvDrawer extends React.Component<DrawerProps, DrawerState> {

    constructor(props: DrawerProps, context?: DrawerState) {
        super(props, context);
        this.handleSidebarHide = this.handleSidebarHide.bind(this);
        this.state = {
            visible: this.props.visible,
        };
    }

    // tslint:disable-next-line:no-any
    public UNSAFE_componentWillReceiveProps(nextProps: DrawerProps, nextContext: any): void {
        // this.NavigateTo = this.NavigateTo.bind(this);
        if (nextProps.visible !== this.state.visible) {
            this.setState({visible: nextProps.visible});
        }
    }

    render() {       
        return (
            <DrawerGrid className="drawergrid">
                <SidebarPushable as={Segment} basic={true}>
                    <SideBarMenu
                        as={Menu}
                        animation={this.props.animation ? this.props.animation : 'push'}
                        width={this.props.width ? this.props.width : 'thin'}
                        // visible={this.props.visible}
                        visible={this.state.visible}
                        icon="labeled"
                        vertical={true}
                        direction={this.props.orientation}
                        className={'sidebarmenu ' + this.props.className}
                        onHide={this.handleSidebarHide}
                        // onHide={this.props.hide}
                    >
                        {this.props.links ? this.props.links
                            .filter(link => link.visible)
                            .map((link, idx) => {
                                return (
                                    <MenuItem 
                                        key={'ML' + idx.toString()}
                                        name={link.label}
                                        onClick={ (e, name) => {
                                            browserHistory.push(link.linkTo);
                                            // this.NavigateTo(link.linkTo);
                                        }}
                                        label={link.label}
                                        link={true}
                                    >
                                        <Icon name={link.iconKey} />
                                        <label>
                                            {link.label}
                                        </label>
                                    </MenuItem>                                    
                                );
                            }) :
                            undefined}
                        {this.props.drawerContent}
                    </SideBarMenu>
                    <SidebarPusher
                        className="sidebarpusher"
                        id="sidebarpusher"
                    >
                        <SegmentContainer
                            basic={true}
                            className="segment-container"
                        >
                        {this.props.children}
                        </SegmentContainer>
                    </SidebarPusher>
                </SidebarPushable>
                </DrawerGrid>
        );
    }

    private handleSidebarHide() {
        if (this.props.handleSidebarHide) {
            this.setState({visible: false});
            this.props.handleSidebarHide(false);
        }
    }
/*     private NavigateTo(link: string) {
        console.log(' Navigating to:', link);
        //Redirect(link);
        getHistory().push(link);
    }
 */
}
