import * as React from 'react';
import glamorous from 'glamorous';
import { Button } from 'semantic-ui-react';

const Logo = glamorous(Button) ({
    backgroundColor: 'transparent !important', 
    padding: '0  !important',   
    margin: '0 !important',
    [`& .${`image`}`] : {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '58px',
    }
  });
  
export interface AppLogoProps {
    logoImg?: string;
    logoUrl?: string;
  }

export class AppLogo extends React.Component<AppLogoProps, {}> {

    render() {
        if (!this.props.logoImg) { return <div />; }
        return (
             <Logo>
              <div className="container">
                <a className="imageLink" target="_blank" href={this.props.logoUrl}>
                  <img
                    className="image"
                    src={this.props.logoImg}
                  />
                </a>
              </div>
            </Logo>
        );
    }
}