export enum LabelStyle {
    Label0 = 1,
    Default = 2,
    Label1 = 3,
    Label2 = 4,
    Label3 = 5,
    Label4 = 6,
    Heading1 = 7,
    Heading2 = 8,
    Heading3 = 9,
    Heading4 = 10
}
