import * as React from 'react';
import './../App.css';
import glamorous from 'glamorous';
import moment from 'moment';

// tslint:disable-next-line:no-any
const Footer = glamorous.div<{theme?: any}> ((props) => ({
  // position: 'fixed',
  // right: 0,
  // bottom: 0,
  // left: 0,
  padding: '10px',
  textAlign: 'center',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  minHeight: '51px',
  borderTop: '1px solid ' + props.theme.NeutralColors.BackgroundGrey3,
  backgroundColor: props.theme.NeutralColors.BackgroundWhite,
  margin: '0 -10px'
}));

// tslint:disable-next-line:no-any
const FooterLink = glamorous.a<{theme?: any}> ((props) => ({
  textDecoration: 'none',
  color: props.theme.BrandColors.TextSecondary,
  fontSize: props.theme.FontSize.PX.size1,
  display: 'flex',
  alignItems: 'center',
  fontWeight: props.theme.FontWeight.Bold,
  letterSpacing: '0.5px',
  lineHeight: props.theme.LineHeight.Small,
}));

// tslint:disable-next-line:no-any
const FooterLabel = glamorous.label<{theme?: any}> ((props) => ({
  fontSize: props.theme.FontSize.PX.size1,
  letterSpacing: '0.5px',
  lineHeight: props.theme.LineHeight.Small,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  color: props.theme.BrandColors.TextTertiary,
  marginRight: '5px',
}));

// tslint:disable-next-line: no-any
const FooterLabelWrapper = glamorous.div<{theme?: any}> ((props) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px'
}));

export class AppFooter extends React.Component {
  
  componentDidMount() {
    var addScript = document.createElement('script');
    addScript.setAttribute('src', 'https://seal.godaddy.com/getSeal?sealID=6MIcUbJvMUdfIW6xyTPWbpvAG8ULHuKzTTMV5rkCWKAdjpx64Sc29YrrnaEb');
    document.body.appendChild(addScript);
  }

  render() {
    
    const currentYear = moment().format('YY'); 
    var currentVersion = sessionStorage.getItem('currentVersion');

    return (
        <Footer>
          <FooterLabelWrapper>
            <FooterLabel> Version {currentVersion} (c) Copyright 2011-{currentYear}. All rights reserved. </FooterLabel>
            <FooterLink href="http://www.redraincorp.com" target="_blank"> www.redraincorp.com</FooterLink>
          </FooterLabelWrapper>
          <span id="siteseal" style={{ padding: '0 10px' }}/>
        </Footer>
    );
  }
}
