import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { TextField, CircularProgress } from '@material-ui/core';
import { IAutoCompleteItem } from '../typings/autoComplete';
import { showNotification } from '../App';
import { InfiniteAutocomplete } from './InfiniteAutocomplete';
import { client } from '..';
import { RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            }
        }
      }
  })
);

interface ClientAndContactSelectorProps {
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    required?: boolean;
    id?: string;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    className?: string;
    disablePortal?: boolean;
    includeNonPmsContacts: boolean;
    includePmsContacts: boolean;
}

interface ClientAndContactSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: IAutoCompleteItem[];
}

export const ClientAndContactSelector: React.FC<ClientAndContactSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 200;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {

                setState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: true
                    };
                });
                
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );

    const [state, setState] = useState<ClientAndContactSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: [],
    });

    // useEffect(() => {
    //     fetchData(state.filter, 0, state.isNextPageLoading, state.hasNextPage, false);        
    // // tslint:disable-next-line: align
    // }, []);

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {    
        if (state.hasNextPage && state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true
            };
        });

        const userQueryParams: ClientQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            includeIndividual: true,
            includeOrganisation: true,
            includeStaff: false,
            includeNonPmsContacts: props.includeNonPmsContacts,
            includePmsContacts: props.includePmsContacts
        };

        retrieveClientAndContactListData(
            userQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveList(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                        isLoading: false
                    };
                });
            }
        );
    };

    const onRetrieveList = (data: ClientAndContactListData, filter: string, offset: number, isClear: boolean) => {
        const clientAndContactData = data.clientAndContactList.clientAndContacts;
        const recordCount = data.clientAndContactList.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: IAutoCompleteItem[] = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = clientAndContactData.map( (source: ClientAndContact) => ({value: source.guid, label: source.name}));
        } else {
            // tslint:disable-next-line: no-shadowed-variable
            for (const name of clientAndContactData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: IAutoCompleteItem) => item.value.toLowerCase().includes(name.guid)).length;

                if (isExists === 0) {
                    itemsList.push({
                        label: name.name,
                        value: name.guid,
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: clientAndContactData.length !== 0 && (offset + loadBlockLimit) < recordCount,
                isLoading: false
            };
        });
    };

    const onSelection = (selection: IAutoCompleteItem, name: string) => {
        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        fetchData(state.filter, 0, true);
    };

    // tslint:disable-next-line: no-any
    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={clsx(classes.root, props.className)}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={70}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal ?? true}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                renderOption={(option, { selected }) => {
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="icon-wrapper">
                                {option.isStaff ? <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>}
                            </div>
                            <div className="other">
                                <div className="primary">{option.label}</div>
                                <div className="secondary">{option.email}</div>
                            </div>
                        </div>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        required={props.required}
                        fullWidth={true}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                    />
                )}
            />
        </div>
    );
};

export function retrieveClientAndContactListData(
    query: ClientQueryParams,
    refreshData: boolean,
    onSuccess: (data: ClientAndContactListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: ClientAndContactsQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                includeOrganisation: query.includeOrganisation,
                includeIndividual: query.includeIndividual,
                includeStaff: query.includeStaff,
                includePmsContacts: query.includePmsContacts,
                includeNonPmsContacts: query.includeNonPmsContacts
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface ClientQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
    includeOrganisation: boolean;
    includeIndividual: boolean;
    includeStaff: boolean;
    includePmsContacts: boolean | null;
    includeNonPmsContacts: boolean | null;
}

export interface ClientAndContactListData {
    loading?: boolean;
    networkStatus?: number;
    clientAndContactList: ClientAndContactList;
}

export interface ClientAndContactList {
    recordCount: number;
    clientAndContacts: ClientAndContact[];
}

export interface ClientAndContact {
    nameID: number;
    name: string;
    guid: string;
}

const ClientAndContactsQuery = gql`
    query ClientAndContactList($offset: Int, $first: Int, $filter: String, 
        $includeOrganisation: Boolean, $includeIndividual: Boolean, $includeStaff: Boolean,
        $includePmsContacts: Boolean,
        $includeNonPmsContacts: Boolean){
        clientAndContactList(
            offset:$offset, first: 
            $first, filter: $filter, 
            includeOrganisation: $includeOrganisation,
            includeIndividual: $includeIndividual,
            includeStaff: $includeStaff,
            includePmsContacts: $includePmsContacts,
            includeNonPmsContacts: $includeNonPmsContacts
        ){
            recordCount,
            clientAndContacts{
                nameID,
                name,
                guid
            }
        }
    }
`;