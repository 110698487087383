import { Checkbox, Chip, createStyles, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, Theme, withStyles } 
        from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import * as React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';
// import PersonOffOutlinedIcon from '@material-ui/icons/PersonOffOutlined';

const styles = (theme: Theme) => createStyles({
    chip: {
        margin: theme.spacing() / 4,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        flexGrow: 1,
        margin: theme.spacing(),
    },
    checkedIcon: {
        color: '#5173FF'
    },    
    personIcons: {
        color: '#5173FF'
    },
    menuItemWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .checkboxDescriptionWrapper': {
            flex: 1
        }
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//         width: 250,
//       },
//     },
// };

interface MultiSelectProps extends WithStyles<typeof styles> {
    className?: string;
    label?: string;
    // tslint:disable-next-line: no-any
    items: any[];
    multiple?: boolean;
    onSelectedItem?: (selected: Array<string | number | boolean> | string | number | boolean, name: string) => void;
    onRemoveItem?: (selected: Array<string | number | boolean> | string | number | boolean, name: string) => void;
    name: string;
    onClear?: () => void;
    required: boolean;
    // tslint:disable-next-line: no-any
    selectedItems?: any;
    disabled?: boolean;
    itemsToShow?: number;
    isShowUserActiveStatusIcons?: boolean;
}

interface MultiSelectState {
    // tslint:disable-next-line: no-any
    itemSelected: any;
}

class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {
    // tslint:disable-next-line:no-any
    constructor(props: MultiSelectProps, state: MultiSelectState) {
        super(props, props);

        this.handleChange = this.handleChange.bind(this);
        this.onSelectedItem = this.onSelectedItem.bind(this);
        this.onClear = this.onClear.bind(this);
        this.renderSelections = this.renderSelections.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.state = {
            itemSelected: []
        };
    }

    public componentDidUpdate(prevProps: MultiSelectProps) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems) {
                this.setState({
                    itemSelected : this.props.selectedItems
                });
            } 
        }
    }

    public render() {
        const { classes } = this.props;
        let selectedItems = this.state.itemSelected.length !== 0 ? this.state.itemSelected : this.props.selectedItems;

        if (selectedItems === undefined) {
            selectedItems = [];
        }

        const itemCountToShow = this.props.itemsToShow ? this.props.itemsToShow : 4.5;

        return (
           <React.Fragment>
                <FormControl className={classes.formControl} required={true} error={true}>
                    <InputLabel htmlFor={this.props.name}>{this.props.label}</InputLabel>
                    {
                        this.props.multiple ?
                        (
                            <Select
                                disabled={this.props.disabled}
                                multiple={this.props.multiple}
                                // value={this.state.itemSelected}
                                value={selectedItems}
                                onChange={this.handleChange}
                                input={<Input id={this.props.name} name={this.props.name}/>}
                                // tslint:disable-next-line
                                renderValue={(selection: any) => this.renderSelections(selection)}
                                // MenuProps={MenuProps}
                                required={this.props.required}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * itemCountToShow + ITEM_PADDING_TOP,
                                            // minHeight: ITEM_HEIGHT * itemCountToShow + ITEM_PADDING_TOP,
                                            width: 250,
                                        },
                                    },
                                    anchorOrigin: {
                                        horizontal: 'left',
                                        vertical: 'bottom'                                      
                                      },
                                    getContentAnchorEl: null,                                   
                                    transformOrigin: {
                                        horizontal: 'left',
                                        vertical: 'top',
                                      
                                    }                                   
                                }} 
                            >
                                {   this.props.items
                                    // tslint:disable-next-line: no-any
                                    ? this.props.items.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id} className={classes.menuItemWrapper}>
                                                {/* <Checkbox checked={this.state.itemSelected.indexOf(item.id) > -1} /> */}
                                                <div className="checkboxDescriptionWrapper">
                                                    <Checkbox 
                                                        checked={selectedItems.indexOf(item.id) > -1} 
                                                        checkedIcon={<CheckBoxIcon className={classes.checkedIcon} />}
                                                    />
                                                    {item.description}
                                                </div>
                                                {
                                                    this.props.isShowUserActiveStatusIcons !== undefined && 
                                                    this.props.isShowUserActiveStatusIcons === true && (
                                                        item.isActive 
                                                            ? <PersonIcon className={classes.personIcons}/>
                                                            : <PersonOutlineOutlinedIcon className={classes.personIcons}/>
                                                    )
                                                }
                                                
                                            </MenuItem>
                                        ))
                                    : null
                                }
                            </Select>
                        )
                        :
                        (
                            <Select
                                disabled={this.props.disabled}
                                multiple={this.props.multiple}
                                // value={this.state.itemSelected}
                                value={selectedItems}
                                onChange={this.handleChange}
                                input={<Input id={this.props.name} name={this.props.name}/>}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                          maxHeight: ITEM_HEIGHT * itemCountToShow + ITEM_PADDING_TOP,
                                          width: 250,
                                        },
                                      }
                                }}
                                required={this.props.required}
                            >
                                this.props.items 
                                {/* tslint:disable-next-line: no-any */}
                                ? {this.props.items.map((item: any) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                                : null
                            </Select>
                        )
                    }
                    <FormHelperText>Member is required</FormHelperText>
                </FormControl>
           </React.Fragment>
        );
    }
    
    // tslint:disable-next-line: no-any
    private renderSelections(selected: any): React.ReactNode {
        const { classes, items } = this.props;
        if (items) {
            const chips: React.ReactNode = (
                <div className={classes.chips}>
                {/* tslint:disable-next-line: no-any */}
                {selected.map((value: any) => {
                    // tslint:disable-next-line: no-any
                    const idx = items.findIndex( (i: any) => i.id === value);
                    return ( 
                        idx === -1 ? null
                        : 
                            this.props.disabled 
                            ? 
                                <Chip 
                                    key={items[idx].id} 
                                    label={items[idx].description} 
                                    className={classes.chip} 
                                
                                />
                            :
                                <Chip 
                                    key={items[idx].id} 
                                    label={items[idx].description} 
                                    className={classes.chip} 
                                    onMouseDown={event => event.stopPropagation()}
                                    // tslint:disable-next-line:jsx-no-lambda
                                    onDelete={() => this.handleDelete(items[idx])} 
                                />
                    );
                    }
                )}
                </div>);
            return chips;
        } else {
            return null;
        }
    }

    private handleChange(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) {
        this.setState({ itemSelected: event.target.value });
        this.onSelectedItem(event.target.value, this.props.name);
    }

    private onSelectedItem(item: Array<string | number | boolean> | string | number | boolean, name: string) {
        if (this.props.onSelectedItem) {
            return this.props.onSelectedItem(item, name);
        }
    }

    // tslint:disable-next-line: no-any
    private handleDelete(item: any): React.EventHandler<any> {
        // if(this.props.disabled) { 
        //     return this.state.itemSelected; 
        // };

        // tslint:disable-next-line: no-console
        console.log('handleDelete');

        const chipItems = this.state.itemSelected;
        const chipToDelete = chipItems.indexOf(item.id);

        chipItems.splice(chipToDelete, 1);

        this.setState({
            itemSelected: chipItems
        });

        if (this.props.onRemoveItem) {
            this.props.onRemoveItem(item.id, this.props.name);
        }

        return chipItems;
    }

    private onClear() {
        if (this.props.onClear) {
            this.setState({itemSelected: []});
            return this.props.onClear();
        }
    }
}

// tslint:disable-next-line: no-any
export default withStyles(styles as any, { withTheme: true })(MultiSelect as any) as any;