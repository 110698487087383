import * as React from 'react';
import { Input, Form, TextArea } from 'semantic-ui-react';
import { FormEvent } from 'react';
import glamorous from 'glamorous';

const AppInput = glamorous(Input)({
    margin: '1rem',
    width: '100%',
    maxWidth: '350px',
    '& input': {
        width: '100%',
    },
    '& .button': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0
    }
});

interface InputProps {
    label?: string;
    hint?: string;
    iconKey?: string;
    secure?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    focus?: boolean;
    multiline?: boolean;
    value?: string;
    rows?: number;
    error?: boolean;
    onChange?: (event?: React.FormEvent<HTMLElement>) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    iconPosition?: 'left';
    size?: 'big';
    className?: string;
    // tslint:disable-next-line:no-any
    action?: any | boolean;
    type?: 'email' | 'password' | 'text';
}

interface InputState {
    value?: string;
    capsLockOn: boolean;
}

export class RvInput extends React.Component<InputProps, InputState> {

    // tslint:disable-next-line:no-any
    private textInput: any;

    // tslint:disable-next-line:no-any
    constructor(props: InputProps, context?: any) {
        super(props, context);
        this.state = { value: props.defaultValue ? '' : props.defaultValue, capsLockOn: false };
        this.onChangeInput = this.onChangeInput.bind(this);
    }

    public focus() {
        if (this.textInput) {
            this.textInput.focus();
        }
    }

    public render() {
        return (
            this.props.multiline ? (
                <Form>
                    <TextArea
                        className="textarea"
                        autoHeight={true}
                        placeholder={this.props.hint}
                        onChange={this.onChangeTextArea}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        rows={this.props.rows}
                        autoCapitalize="none"
                    />
                </Form>)
                : (
                    <AppInput
                        innerRef={component => this.textInput = component}
                        focus={this.props.focus}
                        icon={this.props.iconKey}
                        placeholder={this.props.hint}
                        type={this.props.secure ? 'password' : this.props.type}
                        onChange={this.onChangeInput}
                        onKeyDown={this.onKeyDownInput}
                        value={this.props.value}
                        disabled={this.props.disabled}
                        error={this.props.error}
                        iconPosition={this.props.iconPosition ? this.props.iconPosition : undefined}
                        size={this.props.size}
                        className={this.props.className}
                        autoCapitalize="none"
                        action={this.props.action}
                    />)
        );
    }

    // tslint:disable-next-line:no-any
    public UNSAFE_componentWillReceiveProps(nextProps: InputProps, nextContext: any): void {
        this.setState({ value: nextProps.value });
    }

    private onChangeTextArea = (event: FormEvent<HTMLElement>) => {
        var target = event.target as HTMLTextAreaElement;
        this.setState({ value: target.value });
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    private onChangeInput = (event: React.FormEvent<HTMLElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ value: target.value });
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    private onKeyDownInput = (event: KeyboardEvent) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }
}