/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Avatar, TextField } from '@material-ui/core';
import { DataFunc, Mention, MentionItem, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem } from 'react-mentions';
import { Colors, hashString } from '../enquirySummary/board/BoardCardDraggable';
import { retrieveUserListData, User, UserListData, UserQueryParams } from './UserAutocompleteSelector';
import { showNotification } from '../App';
import { retrieveTeamData } from '../lookupConfiguration/selectors/FirmTeamSelector';
import { FirmData, Team } from '../lookupConfiguration/models/Firm';
import _ from 'lodash';
import { TeamQueryParams } from '../lookupConfiguration/selectors/FirmTeamSelector';
// import { name, random } from 'faker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
        '& .MuiInputLabel-shrink': {
            color: '#3f51b5'
        }
    },
    fieldFocus: {
        '& .MuiInput-underline:before': {
            borderBottom: '2px solid #3f51b5 !important',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important'
        }
    },
    mentionsInput: {
        width: '100%',
        '& .mentionsInput__highlighter': {
          border: 0
        },
        '& .mentionsInput__input': {
          padding: 0,
          border: 0
        },
        '& .mentionsInput__suggestions': {
            minWidth: '200px !important',
            borderRadius: '5px',
            boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        },
        '& .mentionsInput__input:focus': {
          outline: 0
        },
        '& .mentionsInput__suggestions__list': {
            maxHeight: 350,
            overflowY: 'auto',
            padding: '0 10px !important',
        },
        '& .mentionsInput__suggestions__item': {
            // padding: '5px 15px',
            // borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
        },
        '& .mentionsInput__suggestions__item--focused': {
            backgroundColor: '#F2F2F2',
            margin: '0 -10px',
            padding: '0 10px',
        },
        '& .mentionsInput__mention': {
            // backgroundColor: '#cee4e5'
        }
    },
    mention: {
        backgroundColor: '#D1E4FC'
    },
    suggestedList: {
      padding: '8px 0',
      display: 'flex',
      '& .MuiAvatar-root': {
          marginRight: 10
      },
      '& .suggested-detail': {
          flex: 1,
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
      },
      '& .suggested-name': {
          fontWeight: 700,
      },
      '& .suggested-other': {
          fontSize: 11
      }
    },
    avatar: {
      // width: '20px',
      // height: '20px',
      // fontSize: '8px',
    },
  })
);

interface MetionsCustomProps {
    value?: string;
    data: SuggestionDataItem[] | DataFunc;
    onChange?: OnChangeHandlerFunc;
    onAdd?: (id: string | number, display: string) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>, clickedSuggestion: boolean) => void;
    onSelect?: (event: React.UIEvent) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => void;
    markup?: string;
}

export function parseMentionedComment(comment: string) {
    let regex = /@\[.+?\]\(.+?\)/gm;
    let displayRegex = /@\[.+?\]/g;
    let idRegex = /\(.+?\)/g;
    let matches = comment.match(regex);
    // tslint:disable-next-line: no-any
    let arr: any = [];
    // tslint:disable-next-line: no-unused-expression
    matches &&
        // tslint:disable-next-line: no-any
        matches.forEach((m: any) => {
            let id = m.match(idRegex)[0].replace('(', '').replace(')', '');
            let display = m.match(displayRegex)[0].replace('@[', '').replace(']', '');

            arr.push({ id: id, display: display });
        });
    let newComment = comment.split(regex);
    let output = '';
    for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i];
        if (i === newComment.length - 1) {
            output += c;
        } else {
            output += c + `<a href="/${arr[i].id}">${arr[i].display}</a>`;
        }
    }

    return output;
}

// tslint:disable-next-line: no-any
function MultipleTriggers({ value, data, onChange, onAdd, onBlur, onSelect, onKeyDown, markup }: MetionsCustomProps) {

    const classes = useStyles();
  
    // const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/
  
    // tslint:disable-next-line: max-line-length
    const renderSuggestion = (suggestion: SuggestionDataItem, search: string, highlightedDisplay: React.ReactNode, index: number, focused: boolean) => {
  
    // console.log('suggestion:', suggestion, 'search:', search, 'highlightedDisplay:', highlightedDisplay, 'index:', index, 'focused:', focused);

    // tslint:disable-next-line: no-any
    const stringSplit = suggestion.display.split(' ').map((n: any) => n[0]).join('');

    const stringInitials = stringSplit.slice(0, 2).toLocaleUpperCase();

    // tslint:disable-next-line: max-line-length
    const stringColour = Colors[(suggestion.display === '' || suggestion.display === null) ? Colors[Colors.length] : (hashString(suggestion.display) % Colors.length) - 1];

    const display = suggestion.display.split('|');
  
    return (
        <div className={classes.suggestedList}>
            <Avatar className={classes.avatar} style={{ backgroundColor: stringColour, color: '#fff' }}>{stringInitials}</Avatar>
            <div className="suggested-detail">
                <div className="suggested-name">{display[0]}</div>
                <div className="suggested-other">{display[1]}</div>
            </div>
        </div>
      );
    };
  
    const displayTransform = (id: string, display: string) => {
      // return the name
      return display.split('|')[0];
    };
  
    return (
        <MentionsInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onSelect={onSelect}
          onKeyDown={onKeyDown}
          allowSpaceInQuery={true}
          allowSuggestionsAboveCursor={true}
          className={`mentionsInput ${classes.mentionsInput}`}
        >
          <Mention
            className={classes.mention}
            appendSpaceOnAdd={true}
            markup={markup}
            trigger="@"
            data={data}
            displayTransform={displayTransform}
            renderSuggestion={renderSuggestion}
            onAdd={onAdd}
            style={{ textDecoration: 'underline' }}
          />
  
          {/* <Mention
            markup="@[__display__](email:__id__)"
            trigger={emailRegex}
            data={(search) => [{ id: search, display: search }]}
            onAdd={onAdd}
            style={{ backgroundColor: '#d1c4e9' }}
          /> */}
        </MentionsInput>
    );
}

interface TextFieldWithMentionProps {
    label?: string;
    name?: string;
    className?: string;
    value?: string;
    // tslint:disable-next-line: no-any
    mentions?: MentionItem[] | any;
    variant?: 'filled' | 'outlined';
    onChange?: OnChangeHandlerFunc;
    onAdd?: (id: string | number, display: string) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>, clickedSuggestion: boolean) => void;
    onSelect?: (event: React.UIEvent) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => void;
    markup?: string;
    isLoadData: boolean;
    onlyUsedByEnquiries?: boolean;
}

interface TextFieldWithMentionState {
    value?: string;
    // tslint:disable-next-line: no-any
    mentions?: MentionItem[] | any;
    shrink?: boolean;
}

export const TextFieldWithMention: React.FC<TextFieldWithMentionProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<TextFieldWithMentionState>({
        value: undefined,
        mentions: undefined,
        shrink: undefined
    });

    const [suggestions, setSuggestions] = useState<SuggestionDataItem[]>();

    useEffect(() => {
        if (props.isLoadData) {
            // tslint:disable-next-line: no-console
            // console.log(props);
            fetchData('');

            setState((prevState) => {
                return {
                    ...prevState,
                    value: props.value,
                    mentions: props.value
                };
            });
        }       
    // tslint:disable-next-line: align
    }, [props.isLoadData]);

    const fetchData = (query: string) => {
        getUserData(query);
    };

    // tslint:disable-next-line: no-any
    const getUserData = (query: string): any => {       

        let tempData: SuggestionDataItem[] = [];

        // Fetch User and Team details
        const userQueryParams: UserQueryParams = {
            filter: query,
            first: 1000,
            offset: 0,
            // isStaff: props.isStaffOnly ? props.isStaffOnly : undefined,
            isExternal: false
        };
        
        retrieveUserListData(
            userQueryParams,
            false,
            // tslint:disable-next-line: no-console
            (data: UserListData) => {
                const userData = data.administrator.users.user;

                userData.forEach((source: User) => {
                    tempData.push({
                        id: source.userID.toLocaleLowerCase(),
                        display: `${source.friendlyName}${source.roleName ? `|${source.roleName}` : ''}`,
                    });
                });

                getTeamData(query, tempData);
                // return tempData;
            },
            // tslint:disable-next-line:no-any
            function (reason: any): any {
                showNotification(null, reason, 'error');
                return tempData;
            }
        );
    };

    // tslint:disable-next-line: no-any
    const getTeamData = (query: string, userData: SuggestionDataItem[]): any => {
        let teamData: SuggestionDataItem[] = [];
        let tempData: SuggestionDataItem[] = [];

        let teamQueryParams: TeamQueryParams = {
            onlyUsedByEnquiries: props.onlyUsedByEnquiries ? props.onlyUsedByEnquiries : null,
            isMyTeamOnly: null,
            onlyUsedByFinancials: null,
            onlyUsedByTasks: null
        };

        retrieveTeamData(
            teamQueryParams,
            false,
            (data: FirmData) => {
                const firmData = data.firm.teams.team;
                firmData.forEach((team: Team) => {
                    teamData.push({
                        id: team.guidID.toLocaleLowerCase(), 
                        display: `${team.description}${`|Team`}`
                        // isTeam: true
                    });
                });

                let filteredData = teamData;
                
                if (query) {
                    filteredData = _.filter(teamData, (x) => {
                        return x.display.includes(query);
                    });
                }

                filteredData.forEach(element => {
                    tempData.push({
                        id: element.id,
                        display: element.display
                    });
                });

                // const sortedData = _.sortBy(tempData, [(x) => { return x.display; }]);

                let mergedData = _.unionWith(userData, tempData);
                let sortedData = _.sortBy(mergedData, [(x) => { return x.display; }]);

                setSuggestions(sortedData);
                // return sortedData;
            },
            // tslint:disable-next-line:no-any
            function (reason: any): any {
                showNotification(null, reason, 'error');
                return tempData;
            }
        );
    };

    const onChange = (event: { target: { value: string } }, newValue: string, newPlainTextValue: string, mentions: MentionItem[]) => {
        setState((prevState) => {
            return {
                ...prevState,
                value: newValue
            };
        });

        if (props.onChange) {
            props.onChange(event, newValue, newPlainTextValue, mentions);
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>, clickedSuggestion: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                shrink: false
            };
        });

        if (props.onBlur) {
            props.onBlur(event, clickedSuggestion);
        }
    };

    const onSelect = (event: React.UIEvent) => {

        setState((prevState) => {
            return {
                ...prevState,
                shrink: true
            };
        });

        if (props.onSelect) {
            props.onSelect(event);
        }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>) => {
        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    };

    const onAdd = (id: string | number, display: string) => {
        if (props.onAdd) {
            props.onAdd(id, display);
        }
    };

    return (
        <TextField
            label={props.label}
            name={props.name}
            className={`${classes.root} ${state.shrink ? classes.fieldFocus : ''} ${props.className ? props.className : ''}`}
            value={state.value}
            multiline={true}
            variant={props.variant}
            InputProps={{
                // tslint:disable-next-line: no-any
                inputComponent: MultipleTriggers as any,
                inputProps: {
                    data: suggestions, // fetchData,
                    // tslint:disable-next-line: no-any
                    onChange: onChange as any,
                    onAdd: onAdd,
                    // tslint:disable-next-line: no-any
                    onBlur: onBlur as any,
                    // tslint:disable-next-line: no-any
                    onSelect: onSelect as any,
                    // tslint:disable-next-line: no-any
                    onKeyDown: onKeyDown as any,
                    markup: props.markup
                }
            }}
            InputLabelProps={{ shrink: state.value && state.value.length > 0 ? true : false || state.shrink }}
        />
    );
};