import * as React from 'react';
import glamorous from 'glamorous';
import { browserHistory } from './AppContent';

// tslint:disable-next-line: no-any
const ErrorWrapper = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column wrap',
    flex: 1,
    [`& ${`h1`}`] : {
        fontSize: '186px',
        fontWeight: 200,
        margin: '0px',
        color: props.theme.BrandColors.TemplatePrimary,
        backgroundClip: 'text',
        textTransform: 'uppercase',
    },
    [`& ${`h2`}`] : {
        fontSize: '33px',
        fontWeight: 200,
        textTransform: 'uppercase',
        letterSpacing: '3px',
    },
}));

export interface ErrorProps {

}

interface ErrorState {

}

export class PageNotFound extends React.Component<ErrorProps, ErrorState>  {

    constructor(props: ErrorProps, context?: ErrorState) {
        super(props, context);
        this.goToHome = this.goToHome.bind(this);
    }

    public render() {
        return (
            <ErrorWrapper>
                <h1>404</h1>
                <h2>oops! nothing was found</h2>
                <p>
                    The page you are looking for might have been removed 
                    had its name changed or is temporarily unavailable.
                </p>
                <a href="#" onClick={this.goToHome}>Return to homepage</a>
            </ErrorWrapper>
        );
    }

// tslint:disable-next-line: no-any
    private goToHome(event: any) {
        event.preventDefault();
        browserHistory.push('/home');
    }

}
