import * as React from 'react';
import { Message } from 'semantic-ui-react';

export interface ErrorProps {
    message: string;
    iconKey?: string;
    headerMessage?: string;
    list?: [string];
}

export class Error extends React.Component<ErrorProps, {}> {
    public render() {

        return		(
            <Message
                negative={true}
                icon={this.props.iconKey}
                header={this.props.headerMessage}
                content={this.props.message}
                list={this.props.list}
            />
      );
    }
}
