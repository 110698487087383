import * as React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { FormEvent } from 'react';

interface CheckboxProps {
    isChecked?: boolean;
    label?: string;
    disabled?: boolean;
    onChange?: (event: FormEvent<HTMLInputElement>, data: CheckboxState) => void;
}

export interface CheckboxState {
    checked: boolean;
}

export class RvCheckbox extends React.Component<CheckboxProps, CheckboxState> {
    private event?: React.FormEvent<HTMLInputElement>;

    public Check() {
        this.setState((state) => ( { checked: true } ));
    }

    // tslint:disable-next-line:no-any
    constructor(props: CheckboxProps, context?: any) {
        super(props, context);
        this.state = { checked: this.props.isChecked ? true : false };
        this.event = undefined;
    }

    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.event = event;
        this.setState((state) => ( { checked: !state.checked } ));
    }

    componentDidUpdate(prevProps: CheckboxProps, prevState: CheckboxState) {
        if (this.props.onChange && this.event && prevState.checked !== this.state.checked) {
            this.props.onChange(this.event, this.state);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: CheckboxProps) {
        if (this.props.isChecked !== nextProps.isChecked) {
            this.setState({
                checked: nextProps.isChecked !== undefined ? nextProps.isChecked : false
            });   
        }
    }

    public render() {
        return (
            <Checkbox
                label={this.props.label}
                checked={this.state.checked}
                disabled={this.props.disabled}
                onChange={this.onChange}
            />
        );
    }
}
