import * as React from 'react';
import { RvLabel, LabelStyle } from '../Label/Label';
import '../../App.css';
import { Grid } from 'semantic-ui-react';
import { DataDisplayProps } from './IDataDisplayProps';
import { IntlProvider, FormattedNumber } from 'react-intl';
import glamorous from 'glamorous';

const LabelData = glamorous(Grid.Column)({
    textAlign: 'left'
});

const ValueData = glamorous(Grid.Column)({
    textAlign: 'left'
});

interface DataDisplay {
    isHidden: boolean;
}

export enum NumberDisplayStyle {
    Default = 0,            // same as Integer
    Integer = 1,          //
    Decimal = 2,           //  2 decimal places
    Custom = 3
}

interface NumberDisplayProps extends DataDisplayProps {
    value: number;
    displayStyle?: NumberDisplayStyle;
    format?: string;            // an optional currency format string
    style?: LabelStyle;
}

export class NumberDisplay extends React.Component<NumberDisplayProps, {}> implements DataDisplay {
    hiddenOnEmpty: boolean;
    isHidden: boolean;

    // tslint:disable-next-line:no-any
    constructor(props: NumberDisplayProps, context?: any) {
        super(props, context);
        // default to true if not set
        this.hiddenOnEmpty = (this.props.hiddenOnEmpty == null ? true : this.props.hiddenOnEmpty);
    }

    public render() {
        let twoDecimals = {
            minimumFractionDigits: this.props.displayStyle === NumberDisplayStyle.Decimal ? 2 : 0
        };

        const HasValue = () => (
            <IntlProvider locale="en">
                <FormattedNumber
                    value={this.props.value}
                    {...twoDecimals}
                />
            </IntlProvider>
        );
        this.isHidden = this.props.value == null && this.hiddenOnEmpty;
        if (this.isHidden) {
            return null;
        }

        return (
            <Grid columns={2}>
                <LabelData width={this.props.LabelDataWidth}>
                    <RvLabel
                        label={this.props.label}
                        style={this.props.style ? this.props.style : LabelStyle.Default}
                    />
                </LabelData>
                <ValueData width={this.props.ValueDataWidth}>
                    {<HasValue />}
                </ValueData>
            </Grid>
        );
    }
}
