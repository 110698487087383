import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { RvButton } from './Button';
import { SemanticICONS } from 'semantic-ui-react';
import glamorous from 'glamorous';

// tslint:disable-next-line:no-any
const PanelDiv = glamorous.div<{ theme?: any }>((props) => ({
  [`& .${`custom`}`]: {
    [`& .${`ms-Panel-main`}`]: {
      top: 80,
      width: 170,
      [`& .${`button`}`]: {
        padding: '10px',
        width: 140,
      } 
    }
  }
}));

interface PanelProps {
  label?: string;
  iconKey?: SemanticICONS; 
  left?: boolean;
  isOpen?: boolean;
  // tslint:disable-next-line:no-any
  onOpen?: any;
  size?: string;
}

interface PanelState {
  showPanel: boolean;
}

export class RvPanel extends React.Component<PanelProps, PanelState> {

  constructor(props: PanelProps) {
    super(props);
    this.state = {
      showPanel: false
    };
    this.onShowPanel = this.onShowPanel.bind(this);
  }

  // tslint:disable-next-line:no-any
  public UNSAFE_componentWillReceiveProps(nextProps: PanelProps, nextContext: any): void {
    if (nextProps.isOpen !== undefined) {
        this.setState({ showPanel: nextProps.isOpen });
    }
}
  public render() {
    
    return (
      <PanelDiv>
        <RvButton 
          iconKey={this.props.iconKey}
          label={this.props.label}
          onClick={this.onShowPanel}
        />
        <Panel
          isOpen={this.state.showPanel}
          type={this.props.left ? PanelType.smallFixedNear : 
                this.props.size === 'small' ? PanelType.smallFixedFar : 
                this.props.size === 'custom' ? PanelType.custom : 
                PanelType.medium
              }
          customWidth="450px"
          onDismiss={this.onClosePanel}
          isBlocking={false}
        >
          {this.props.children}
        </Panel>
      </PanelDiv>
    );
  }
  
    private onShowPanel(): void {
      this.setState((state) => ( { showPanel: !state.showPanel } ));
    }

    private onClosePanel = (): void => {
      this.setState({ showPanel: false });
    }
 
  }