import { debounce } from 'lodash';
import MuiDownshift, { IDownShiftItem } from 'mui-downshift';
import * as React from 'react';
import { client } from '..';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import glamorous from 'glamorous';

// tslint:disable-next-line: no-any
const ListItemIconWrapper = glamorous(ListItemIcon)<{theme?: any}> ((props) => ({
    minWidth: 'auto !important',
    marginRight: '10px !important'
}));

// Interfaced to be used with Intinite scrolling control. 
interface GroupProperties {
    startIndex: number;
    stopIndex: number;
}

interface RowIndexProperties {
    index: number;
}

interface DownShiftProperties {
    // tslint:disable-next-line: no-any
    downshiftProps: any;
}

export interface UserQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
    isStaff?: boolean;
    isExternal?: boolean;
    isAuthorized?: boolean;
}

export interface UserListData {
    loading?: boolean;
    networkStatus?: number;
    administrator: Administrator;
}

export interface Administrator {
    users: UserList;    
}

export interface UserList {
    recordCount: number;
    user: User[];
}

export interface User {
    userID: string;
    email: string;
    friendlyName: string;
    roleName: boolean;
    isStaff: boolean;
    staffName: string;
    isExternal: boolean;
}

// Find a better way to do this
enum StateChangeTypes {
    unknown = '__autocomplete_unknown__', // 0
    mouseUp = '__autocomplete_mouseup__', // 1
    itemMouseEnter = '__autocomplete_item_mouseenter__', // 2
    keyDownArrowUp = '__autocomplete_keydown_arrow_up__', // 3
    keyDownArrowDown = '__autocomplete_keydown_arrow_down__', // 4
    keyDownEscape = '__autocomplete_keydown_escape__', // 5
    keyDownEnter = '__autocomplete_keydown_enter__', // 6
    clickItem = '__autocomplete_click_item__', // 7
    blurInput = '__autocomplete_blur_input__', // 8
    changeInput = '__autocomplete_change_input__', // 9
    keyDownSpaceButton = '__autocomplete_keydown_space_button__', // 10
    clickButton = '__autocomplete_click_button__', // 11
    blurButton = '__autocomplete_blur_button__', // 12
    controlledPropUpdatedSelectedItem = '__autocomplete_controlled_prop_updated_selected_item__', // 13
    touchEnd = '__autocomplete_touchend__', // 14
}

interface UserSelectorProps {
    onSelection?: (selection: IDownShiftItem, name: string) => void;
    // tslint:disable-next-line: no-any
    items?: any;
    placeholder?: string;
    required?: boolean;
    label?: string;
    name?: string;
    value?: string;
    displayLabel?: string;
    isGuidAsValue?: boolean;
    isStaffOnly?: boolean;
    disable?: boolean;
    isExternal?: boolean;
    isAuthorized?: boolean; // To get Active licensed users
}

interface UserSelectorState {
    selectedCode?: string;
    selectedLabel?: string;
    loading: boolean;
    // tslint:disable-next-line: no-any
    selectedItem?: any;
}

const loadBlockLimit: number = 30;

export default class UserSelector extends React.Component<UserSelectorProps, UserSelectorState> {
    // tslint:disable-next-line: no-any
    public list: any = [];
    public rowCount: number = 0;
    public offset: number = 0;
    public filter: string = '';

    constructor(props: UserSelectorProps, state?: UserSelectorState) {
        super(props, state);

        // if (this.props.value === undefined || this.props.value === null) {
        this.FetchData = this.FetchData.bind(this);
        // }
        
        this.loadMoreRows = this.loadMoreRows.bind(this);
        this.onDataRetrieved = this.onDataRetrieved.bind(this);
        this.clearData = this.clearData.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = debounce(this.handleStateChange.bind(this), 500);
        this.getInputProps = this.getInputProps.bind(this);

        this.state = {
            loading: false
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: UserSelectorProps) {
        if (this.props.value !== nextProps.value && nextProps.value === '') {            
            const selectedItem = { value: '', label: '' };
            this.setState({
                selectedItem
            });       
        }
    }

    public componentWillMount() {
        if (this.props.value !== undefined && this.props.displayLabel !== undefined
            && this.props.value !== null && this.props.displayLabel !== null) {
            const selectedItem = { value: this.props.value, label: this.props.displayLabel };

            if (this.props.displayLabel.length > 0 && this.props.value.length > 0) {
                this.setState({
                    selectedItem
                });
            }
        }
    }

    public componentDidUpdate(prevProps: UserSelectorProps) {
        if (prevProps.value !== this.props.value || prevProps.displayLabel !== this.props.displayLabel) {
            if (this.props.value && this.props.displayLabel) {

                const selectedItem = { value: this.props.value, label: this.props.displayLabel };

                if (this.props.displayLabel.length > 0 && this.props.value.length > 0) {
                    this.setState({
                        selectedItem
                    });
                }
            } else if (this.props.isStaffOnly 
                && this.props.value === '' 
                && (this.props.displayLabel === '' || this.props.displayLabel === undefined) 
                ) {                
                    const selectedItem = { value: '', label: '' };

                    this.setState({
                        selectedItem
                    });
            }
        } else {
            if (this.props.value && this.props.displayLabel && this.state.selectedItem === undefined) {

                const selectedItem = { value: this.props.value, label: this.props.displayLabel };

                if (this.props.displayLabel.length > 0 && this.props.value.length > 0) {
                    this.setState({
                        selectedItem
                    });
                }
            }
        }
    }

    public render() {
        return (
            <div className={'downshift-wrapper'}>
                <MuiDownshift
                    focusOnClear={false}
                    onStateChange={this.handleStateChange}
                    onChange={this.handleChange('' + this.props.name)}
                    getInputProps={this.getInputProps}
                    items={this.list}
                    loading={this.state.loading}
                    showEmpty={true}
                    // tslint:disable-next-line: no-any
                    getListItem={({ getItemProps, item }: any) => item && (
                        <ListItem button={true} {...getItemProps()}>
                            <ListItemIconWrapper>
                                    {item.isStaff ? <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>}
                            </ListItemIconWrapper>
                            <ListItemText 
                                primary={item.label} 
                                secondary={(
                                    <>
                                        <div
                                            style={{
                                                wordBreak: 'break-all',
                                                wordWrap: 'break-word',
                                            }}
                                        >
                                            {item.email}
                                        </div>
                                        <div 
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                fontSize: '0.675rem',
                                                lineHeight: 1.43,
                                                letterSpacing: '0.01071em',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {item.roleName}
                                        </div>
                                    </>
                                )}
                            />
                        </ListItem>
                    )}
                    // tslint:disable-next-line:jsx-no-lambda
                    getInfiniteLoaderProps={({ downshiftProps }: DownShiftProperties) => ({
                        isRowLoaded: ({ index }: RowIndexProperties) => {
                            // tslint:disable-next-line:no-console
                            // console.log("isRowLoaded, Index - " + index + " And " + !!this.list[index]);
                            return !!this.list[index];
                        },
                        loadMoreRows: this.state.loading
                            // tslint:disable-next-line:no-console
                            ? () => {/*console.log("MuiDownshift - Data is still loading")*/ }
                            : ({ startIndex, stopIndex }: GroupProperties) => {
                                // If this is not set, then the rows will jump up to the last highlight on each data load
                                downshiftProps.setHighlightedIndex(null);

                                // Fetch records
                                return (this.loadMoreRows(startIndex, stopIndex));
                            },
                        rowCount: this.rowCount,
                        threshold: 20, // Threshold at which to pre-fetch data   
                    })}
                    selectedItem={this.getSelectedItem()}
                    
                />
            </div>
        );
    }  

    private getSelectedItem() {
        if (this.state.selectedItem !== undefined && this.state.selectedItem !== null) {
            if (this.state.selectedItem.value === undefined) {
                return null;
            }
            return this.state.selectedItem;
        } else {
            return null;
        }
    }

    // tslint:disable-next-line: no-any
    private handleStateChange = (changes: any) => {
        // Need improvements
        let userQueryParams: UserQueryParams = {};

        // Any changes with typing involved   
        if ((changes.type !== undefined && (changes.type === StateChangeTypes.changeInput || changes.type === 9))
            || (
                changes.isOpen !== undefined && changes.isOpen === false && changes.inputValue !== undefined && changes.inputValue === '' &&
                (changes.type === StateChangeTypes.mouseUp || changes.type === 1)
            ) // This will clear the filter on mouse up without selecting a value on a filter and refetch the records
            || (
                changes.isOpen !== undefined && changes.isOpen === true && changes.inputValue === undefined &&
                (changes.type === StateChangeTypes.unknown || changes.type === 0)
            ) // When user selects an input and clicks the clear button.            
        ) {

            this.setState({
                loading: true
            });

            if (changes.inputValue !== undefined && changes.inputValue.length > 0) {
                // Filter search
                this.clearData();

                this.filter = changes.inputValue;

                userQueryParams = {
                    filter: this.filter,
                    first: loadBlockLimit,
                    offset: this.offset,
                    isStaff: this.props.isStaffOnly ? this.props.isStaffOnly : undefined,
                    isExternal: this.props.isExternal === undefined ? undefined : this.props.isExternal,
                    isAuthorized: this.props.isAuthorized === undefined ? undefined : this.props.isAuthorized
                };
            } else {
                // Clear data
                this.clearData();
                this.filter = '';

                userQueryParams = {
                    filter: this.filter,
                    first: loadBlockLimit,
                    offset: this.offset,
                    isStaff: this.props.isStaffOnly ? this.props.isStaffOnly : undefined,
                    isExternal: this.props.isExternal === undefined ? undefined : this.props.isExternal,                    
                    isAuthorized: this.props.isAuthorized === undefined ? undefined : this.props.isAuthorized
                };
            }

            this.FetchData(userQueryParams, true);
        }
    }

    // tslint:disable-next-line: no-any
    private handleChange = (name: string) => (event: any) => {
        const item: IDownShiftItem = { ...event };
        const selected = { value: item!.value, label: item!.label };

        this.setState({
            selectedCode: item!.value,
            selectedLabel: item!.label,
            // tslint:disable-next-line:object-literal-sort-keys
            selectedItem: selected // This is required if we are setting a default value
        });

        if (this.props.onSelection) {
            this.props.onSelection(item, name);
        }
    }

    // tslint:disable-next-line: no-any
    private getInputProps(): any {
        return ({
            /*   ...this.props, */
            label: this.props.label,
            name: this.props.name,
            // tslint:disable
            onChange: (event: any) => {
            },
            placeholder: this.props.placeholder,
            required: this.props.required,
            disabled: this.props.disable ? this.props.disable : false
        })
    }

    private loadMoreRows(startIndex: number, stopIndex: number) {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
        }

        const userQueryParams: UserQueryParams = {
            filter: this.filter,
            first: loadBlockLimit,
            offset: this.offset,
            isStaff: this.props.isStaffOnly ? this.props.isStaffOnly : undefined,
            isExternal: this.props.isExternal === undefined ? undefined : this.props.isExternal,
            isAuthorized: this.props.isAuthorized === undefined ? undefined : this.props.isAuthorized
        };

        return this.FetchData(userQueryParams, false);
    }

    private FetchData(userQueryParams: UserQueryParams, isSearch: boolean) {
        // tslint:disable-next-line:no-console
        // console.log("FetchData");

        return fetchUserData(userQueryParams,
            false,
            // tslint:disable-next-line:no-unused-expression
            (data) => this.onDataRetrieved(data, isSearch),
            (reason: any): (void) => {
                // tslint:disable-next-line:no-console
                console.log('Error while fetchUserData. Reason - ' + reason);

                if (this.state.loading) {
                    this.setState({
                        loading: false
                    })
                }
            });
    }

    private onDataRetrieved(data: UserListData, isSearch: boolean) {
        const userData = data.administrator.users.user;

        // Set the Total Record count for the search
        this.rowCount = data.administrator.users.recordCount;
        // Set the offset for the next searcg       
        this.offset = this.offset + loadBlockLimit;

        if (this.list === []) {
            // Id is not used here, because we are saving the Account Name into the Client DB in OP
            this.list = userData.map((user: User) => {
                return ({
                    // label: this.props.isStaffOnly ? user.friendlyName : user.friendlyName + ' | Role - ' + user.roleName,
                    label: user.isStaff ? user.staffName : user.friendlyName,
                    value: this.props.isGuidAsValue? user.userID : user.email,
                    isStaff: user.isStaff,
                    email: user.email,
                    roleName: user.roleName,
                    isExternal: user.isExternal
                });
            });
        }
        else {
            for (const user of userData) {
                const isExists = this.list.filter((item: any) => item.value.toLowerCase().includes(user.email)).length;

                if (isExists === 0) {
                    this.list.push({
                        // label: this.props.isStaffOnly ? user.friendlyName : user.friendlyName + ' | Role - ' + user.roleName,
                        label: user.isStaff ? user.staffName : user.friendlyName,
                        value: this.props.isGuidAsValue? user.userID : user.email,
                        isStaff: user.isStaff,
                        email: user.email,
                        roleName: user.roleName,
                        isExternal: user.isExternal
                    });
                }
            }
        }

        this.setState({
            loading: false
        });

        return this.list;
    }

    private clearData() {
        this.offset = 0;
        this.filter = "";
        this.list = [];
    }
}

export function fetchUserData(query: UserQueryParams,
    refreshData: boolean,
    onSuccess: (data: UserListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: UserListData,
        variables: {
            filter: query.filter,
            first: query.first,
            offset: query.offset,
            isStaff: query.isStaff,
            isExternal: query.isExternal,
            isAuthorized: query.isAuthorized
        },
        fetchPolicy: fetchPolicy
    })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const UserListData = gql`
query userQuery($offset: Int, $first: Int, $filter: String, $isStaff: Boolean, $isExternal: Boolean, $isAuthorized: Boolean){
    administrator{
        users(offset:$offset, first:$first, filter:$filter, isStaff: $isStaff, isExternal: $isExternal, isAuthorized: $isAuthorized){
            recordCount,
            user{
                userID,
                email,
                friendlyName,
                roleName,
                isStaff,
                staffName,
                isExternal
            }
        }
    }
}`;