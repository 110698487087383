import * as React from 'react';
import { List } from 'semantic-ui-react';
import glamorous from 'glamorous';
import { RvLabel, LabelStyle } from './Label/Label';
import { MatterTerminology } from '../MatterTerminology';
import { cdnUrl } from '../App';

const ListItem = glamorous(List.Item) ({
    marginBottom: 10,
    alignItems: 'center',
    [`& .${`browserIcon`}`] : {
      width: '2em !important',
      height: '2em',
      marginRight: '10px !important'
    }
  });

  // tslint:disable-next-line:no-any
const SupportedBrowserWrapper = glamorous.div<{theme?: any}> ((props) => ({
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`& .${`list`}`] : {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0%',
      marginTop: 45
    },
    [`& .${`content`}`] : {
      textAlign: 'left'
    },
    [`& .${`button`}`] : {
      backgroundColor: props.theme.BrandColors.TemplatePrimary,
      color: props.theme.NeutralColors.BackgroundWhite,
      padding: 10
    },
    [`& .${`redview-logo`}`] : {
        width: '100%',
        height: 'auto'
    }     
}));

interface SupportedBroswerProps {
    onClickCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface SupportedBrowserState {
  visibility: boolean;    
}

export class SupportedBrowsers extends React.Component<SupportedBroswerProps, SupportedBrowserState> {
    // tslint:disable-next-line:no-any
    constructor(props: SupportedBroswerProps, context?: any) {
      super(props, context);
      this.state = {
        visibility: true
      };
    }
    toggleVisibilitySupportedBrowser = () => this.setState({ visibility: !this.state.visibility });

    render() {
      // const {state: { visibility }} = this;
    
      return (
        
          <SupportedBrowserWrapper>
            <div><img src={cdnUrl + '/assets/images/RedView.gif'} alt="redrain" className="redview-logo"/></div>
            <RvLabel label={MatterTerminology.SupportedBrowsers} style={LabelStyle.Heading2}/>
            <List>
              <ListItem style={{display: 'flex', flexDirection: 'row'}}>
                <img src={cdnUrl + '/assets/images/chrome.png'} className="browserIcon" alt="google chrome" />
                <List.Content>
                  <List.Header>{MatterTerminology.GoogleChrome}</List.Header>
                  <List.Description>
                    v10.0.01 (and above)
                  </List.Description>
                </List.Content>
              </ListItem>

              <ListItem style={{display: 'flex', flexDirection: 'row'}}>
                <img src={cdnUrl + '/assets/images/safari.png'} className="browserIcon" alt="safari" />
                <List.Content>
                  <List.Header>{MatterTerminology.Safari}</List.Header>
                  <List.Description>
                    v4.1 (and above)
                  </List.Description>
                </List.Content>
              </ListItem>

              <ListItem style={{display: 'flex', flexDirection: 'row'}}>
                <img src={cdnUrl + '/assets/images/firefox.png'} className="browserIcon" alt="mozilla firefox" />
                <List.Content>
                  <List.Header>{MatterTerminology.MozillaFirefox}</List.Header>
                  <List.Description>
                    v54.0.1 (and above) (32-bit)
                  </List.Description>
                </List.Content>
              </ListItem>

              <ListItem style={{display: 'flex', flexDirection: 'row'}}>
                <img src={cdnUrl + '/assets/images/edge.png'} className="browserIcon" alt="microsoft edge" />
                <List.Content>
                  <List.Header>{MatterTerminology.MicrosoftEdge}</List.Header>
                  <List.Description>
                    v38.14393.1066.0 (and above)
                  </List.Description>
                </List.Content>
              </ListItem>

              {/* <ListItem style={{display: 'flex', flexDirection: 'row'}}>
                <img src={require('../images/ie.png')} className="browserIcon" alt="internet explorer" />
                <List.Content>
                  <List.Header>{MatterTerminology.InternetExplorer}</List.Header>
                  <List.Description>
                    v11.1480.14 (and above)
                  </List.Description>
                </List.Content>
              </ListItem> */}
            </List>
          </SupportedBrowserWrapper>

      );
    }
}
