import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { formatNumber } from '../helpers/ChartHelper';
import moment from 'moment';
import { RvLoader } from './Loader';
// import { formatNumber } from '../helpers/ChartHelper';
// import moment from 'moment';
// import { UserFeatureEnum, UserFeature } from '../types/UserFeature';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
        },
        headerWrapper: {
            display: 'flex',
            flex: 1,
            '& > div': {
                textTransform: 'capitalize',
                opacity: 0.52,
                color: 'rgb(35, 37, 59)'
            },
            '& > div:nth-child(1)': {
                opacity: 1,
                color: 'inherit',
            },
        },
        colHeader: {
            flex: 1,
            padding: '10px',
            // fontWeight: 600,
        },
        bodyWrapper: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            borderRadius: '4px',
            '& > div:nth-child(odd)': {
                backgroundColor: 'rgb(247, 248, 250)'
            }
        },
        rowBody: {
            flex: 1,
            display: 'flex',
            '& > div': {
                textTransform: 'capitalize',
                opacity: 0.52,
                color: 'rgb(35, 37, 59)'
            },
            '& > div:nth-child(1)': {
                opacity: 1,
                color: 'inherit',
            },
        },
        colBody: {
            flex: 1,
            padding: '10px',
        },
        imagesWrapper: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexFlow: 'row wrap',
            margin: '0 -10px',
            '& img': {
                width: '100%',
                height: 'auto',
                maxWidth: '35px',
                padding: '0 10px',
            }
        }
    })
);

export enum ValueTypeEnum {
    STRING = 0,
    DECIMAL,
    NUMBER,
    DATE,
    ARRAY,
    ARRAY_OF_OBJECT,
    OBJECT,
    IMAGE,
    ARRAY_OF_IMAGE
}   

export interface CustomTableValueProps {
    // tslint:disable-next-line: no-any
    value: any;
    type: ValueTypeEnum;
}

export interface CustomTableContentProps {
    // tslint:disable-next-line: no-any
    [key: string]: CustomTableValueProps | any;
}

interface CustomTableProps {
    header?: string[];
    content?: CustomTableContentProps[];
    options?: string[];
}

// tslint:disable-next-line: no-any
export const CustomTable: React.FC<CustomTableProps> = (props) => {
    const classes = useStyles();

    // tslint:disable-next-line: no-any
    const formatValue = (item: CustomTableValueProps) => {
        if (item.type === ValueTypeEnum.NUMBER) {
            return formatNumber(item.value);
        }
        if (item.type === ValueTypeEnum.DECIMAL) {
            return formatNumber(item.value, 2);
        }
        if (item.type === ValueTypeEnum.DATE) {
            return moment(item.value).format('DD-MMM-YYYY');
        }
        if (item.type === ValueTypeEnum.ARRAY) {
            return item.value.join(',');
        }
        if (item.type === ValueTypeEnum.IMAGE) {
            return (
                <img src={item.value} alt={item.value}/>
            );
        }
        if (item.type === ValueTypeEnum.ARRAY_OF_IMAGE) {
            return (
                <div className={classes.imagesWrapper}>
                    {item.value.map((val: string, index: number) => (
                        <img src={val} alt={index.toString()} />
                    ))}
                </div>
            );
        }
        return item.value;
    };

    const getContent = () => {
        if (props.content) {
            return (
                <>
                    {props.content && props.content
                    .sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0)
                    .map((item: CustomTableContentProps, index: number) => !item.hide && (
                        <div className={classes.rowBody} key={index}>
                            {props.options && props.options.map((option: string, i: number) => (
                                <div className={`${classes.colBody} label-${option}`} key={i}>
                                    {formatValue(item[option])}
                                </div>
                            ))}
                        </div>
                    ))}
                </>
            );
        }
        return (
            <div className={classes.rowBody}>
                <div className={`${classes.colBody}`}>
                    <RvLoader />
                </div>
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <div className={classes.headerWrapper}>
                {props.header && props.header.map((head, index) => (
                    <div className={classes.colHeader} key={index}>
                        {head}
                    </div>
                ))}
            </div>
            <div className={classes.bodyWrapper}>
                {getContent()}
            </div>
        </div>
    );
};
